import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import LeaForm from './LeaForm';
import { clearMessages, startUpdateLea } from './LeaActions';

const updateLea = props => {
  let navigate = useNavigate();

  function onSubmit(modifiedLeaValues) {
    props.startUpdateLea(modifiedLeaValues, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/lea/dashboard');
  }

  function leaDup(code) {
    let isDup = false;
    if (code !== props.lea.arrest_agency_code) {
      isDup = props.leaCodes.includes(code);
    }
    return isDup;
  }

  return <LeaForm title="Update LEA Record"
             submitLabel="Update Record"
             onSubmit={onSubmit}
             onCancel={onCancel}
             leaDup={leaDup}
             clearMessages={props.clearMessages}
             errorMessage={props.errorMessage}
             successMessage={props.successMessage}
             authenticated={!!props.token}
             unauthorizedFields={[]}
             lea={props.lea}
    />
}

const mapStateToProps = (state) => ({
  lea: state.lea.items.find(item => item.arrest_agency_code === state.lea.currentRow),
  leaCodes: state.lea.items.map(item => item.arrest_agency_code),
  errorMessage: state.lea.errorMessage,
  successMessage: state.lea.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  startUpdateLea,
  clearMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(updateLea);