import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from 'styled-components';
import SetPasswordAdmin from '../Login/SetPasswordAdmin';

const ProfileFields = styles.div`
  width: 450px;
`;
ProfileFields.displayName = 'ProfileFields';

const FieldSet = styles.div`
  display: grid;
  grid-template-columns: 7em auto;
  grid-column-gap: 1em;

  & .fieldText {
    margin: 10px;
  }

  label {
    font-family: georgia, times, serif;
    color: #2687af;
  }

  input {
    width: 100%;
  }
`
FieldSet.displayName = 'FieldSet';

const OptionSet = styles.div`
  display: flex;
  flex-direction: row;
`
OptionSet.displayName = 'OptionSet';

const ScopeFields = styles.fieldset`
  margin: 10px;
  padding: 0 5px;
  background-color:papayawhip;
  border-radius: 6px;
  flex-grow: 1;

`;
ScopeFields.displayName = 'ScopeFields';

const ScopeSet = styles.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
ScopeSet.displayName = 'ScopeSet';

const CheckedElement = styles.div`
  align-self: center;
  display: grid;
  grid-template-columns: 1em auto;
  column-gap: 10px;

  & label {
    justify-self: start;
  }
`;
CheckedElement.displayName = 'CheckBoxField'

const CheckBox = props => (
  <CheckedElement key={props.name}>
    <input type="checkbox"
           id={props.name}
           checked={props.getter}
           onChange={e => {
             console.log('checkbox', e.target.id, 'changed to ', e.target.checked);
             props.setter(e.target.checked);
           }}
    />
    <label htmlFor={props.name}>{props.name}</label>
  </CheckedElement>
)

export default function(props) {

  // Don't bother setting these according to the inbound props.
  // We'll set them in the useEffect after the initial render.
  //
  const [enumber,     setEnumber]     = useState('');
  const [description, setDescription] = useState('');
  const [email,       setEmail]       = useState('');
  const [enabled,     setEnabled]     = useState('');
  const [admin,       setAdmin]       = useState('');
  const [all,         setAll]         = useState('');
  const [da,          setDa]          = useState('');
  const [lasd,        setLasd]        = useState('');
  const [lapd,        setLapd]        = useState('');
  const [lea,         setLea]         = useState('');
  const [bcs,         setBcs]         = useState('');
  const [probation,   setProbation]   = useState('');
  const [court,       setCourt]   = useState('');
  const [origProfile, setOrigProfile] = useState({});
  const [diffProfile, setDiffProfile] = useState(new Map());

  const navigate = useNavigate();

  function noop() {}

  useEffect(() => {
    setEnumber(props.enumber         || '');
    setDescription(props.description || '');
    setEmail(props.email             || '');
    setEnabled(props.enabled         || false);
    setAdmin(props.scope ? props.scope.includes('admin')  : false);
    setAll  (props.scope ? props.scope.includes('all')    : false);
    setDa   (props.scope ? props.scope.includes('da')     : false);
    setLasd (props.scope ? props.scope.includes('lasd')   : false);
    setLapd (props.scope ? props.scope.includes('lapd')   : false);
    setLea  (props.scope ? props.scope.includes('lea')    : false);
    setBcs  (props.scope ? props.scope.includes('bcs')    : false);
    setCourt(props.scope ? props.scope.includes('court')  : false);
    setProbation(props.scope ? props.scope.includes('probation') : false);
  }, [props])

  useEffect(() => {
    if (origProfile.enumber === undefined) {
      const originalValues = { 
        enumber: props.enumber,
        description: props.description || '',
        email: props.email || '',
        enabled: props.enabled || false,
        admin: props.scope ? props.scope.includes('admin')  : false,
        all:   props.scope ? props.scope.includes('all')    : false,
        da:    props.scope ? props.scope.includes('da')     : false,
        lasd:  props.scope ? props.scope.includes('lasd')   : false,
        lapd:  props.scope ? props.scope.includes('lapd')   : false,
        lea:   props.scope ? props.scope.includes('lea')    : false,
        bcs:   props.scope ? props.scope.includes('bcs')    : false,
        court: props.scope ? props.scope.includes('court')  : false,
        probation: props.scope ? props.scope.includes('probation') : false
      };
      setOrigProfile(originalValues);
    } else {
      const changes = new Map();
      if (props.create) changes.set('enumber', enumber);
      if (origProfile.description !== description) changes.set('description', description);
      if (origProfile.email       !== email)       changes.set('email',       email);
      if (origProfile.enabled     !== enabled)     changes.set('enabled',     enabled);
      if (origProfile.admin       !== admin)       changes.set('admin',       admin);
      if (origProfile.all         !== all)         changes.set('all',         all);
      if (origProfile.da          !== da)          changes.set('da',          da);
      if (origProfile.lasd        !== lasd)        changes.set('lasd',        lasd);
      if (origProfile.lapd        !== lapd)        changes.set('lapd',        lapd);
      if (origProfile.lea         !== lea)         changes.set('lea',         lea);
      if (origProfile.bcs         !== bcs)         changes.set('bcs',         bcs);
      if (origProfile.court       !== court)       changes.set('court',       court);
      if (origProfile.probation   !== probation)   changes.set('probation',   probation);
      setDiffProfile(changes);
    }
  }, [enumber, description, email, enabled, admin, all, da, lasd, lapd, lea, bcs, court, probation]);

  function resetProfile() {
    console.log('resetting profile to ', JSON.stringify(origProfile));
    setDescription(origProfile.description);
    setEmail      (origProfile.email);
    setEnabled    (origProfile.enabled);
    setAdmin      (origProfile.admin);
    setAll        (origProfile.all);
    setDa         (origProfile.da);
    setLasd       (origProfile.lasd);
    setLapd       (origProfile.lapd);
    setLea        (origProfile.lea);
    setBcs        (origProfile.bcs);
    setCourt      (origProfile.court);
    setProbation  (origProfile.probation);
  }

  function updateProfile() {
    const changed = { enumber };
    if (diffProfile.size) {
      if (diffProfile.has('description')) changed.description = diffProfile.get('description');
      if (diffProfile.has('email'))       changed.email       = diffProfile.get('email');
      if (diffProfile.has('enabled'))     changed.enabled     = diffProfile.get('enabled');
      const allScopes = { admin, all, da, lasd, lapd, lea, bcs, court, probation };
      if (Object.keys(allScopes).some(s => diffProfile.has(s))) {
        changed.scope = Object.keys(allScopes).filter(s => allScopes[s]);
      }
    }
    console.log('Update:', JSON.stringify(changed));
    props.updateHandler(changed);
  }

  function createProfile() {
    const newProfile = { enumber, description, email, enabled }
    newProfile.scope = [];
    admin     && newProfile.scope.push('admin');
    all       && newProfile.scope.push('all');
    da        && newProfile.scope.push('da');
    lasd      && newProfile.scope.push('lasd');
    lapd      && newProfile.scope.push('lapd');
    lea       && newProfile.scope.push('lea');
    bcs       && newProfile.scope.push('bcs');
    court     && newProfile.scope.push('court');
    probation && newProfile.scope.push('probation');
    console.log('Create:', JSON.stringify(newProfile));
    props.createHandler(newProfile);
  }

  function deleteProfile() {
    console.log('Deleting profile for', enumber);
    props.deleteHandler(enumber);
  }

  function done() {
    navigate('/Profiles/dashboard');
  }

  return (
      <ProfileFields style={{width: '450px'}}>
        <div>
          {props.updateHandler && <button onClick={updateProfile}>Update</button>}
          {props.createHandler && <button onClick={createProfile}>Create</button>}
          {props.readonly ||  <button onClick={resetProfile}>Reset</button>}
          <button onClick={done}>Done</button>
          {props.deleteHandler && <button onClick={deleteProfile}>Delete</button>}
          <span className={'error'}>{props.error}</span>
          <span className={'error'}>{props.success}</span>
        </div>
        <FieldSet>
          <div className="fieldText">
            <label>eNumber</label>
            <input type="text" id="enumber" value={enumber} readOnly={props.readonly}
                  onChange={e => setEnumber(e.target.value)} maxLength="12"/>
          </div>
          <div className="fieldText">
            <label>Description</label>
            <input type="text" id="description" value={description} readOnly={props.readonly}
                  onChange={e => setDescription(e.target.value)} maxLength="40" />
          </div>
          <CheckBox name="Enabled" getter={enabled}
                    setter={props.readonly ? noop : setEnabled} />
          <div className="fieldText">
            <label>Email</label>
            <input type="text" id="email" value={email} readOnly={props.readonly}
                  onChange={e => setEmail(e.target.value)} maxLength="50" />
          </div>
        </FieldSet>
        <OptionSet>
          <ScopeFields>
            <legend>Scopes</legend>
            <ScopeSet>
              <CheckBox name="all"   getter={all}   setter={props.readonly ? noop : setAll} />
              <CheckBox name="admin" getter={admin} setter={props.readonly ? noop : setAdmin}/>
              <CheckBox name="lea"   getter={lea}   setter={props.readonly ? noop : setLea}/>
              <CheckBox name="bcs"   getter={bcs}   setter={props.readonly ? noop : setBcs}/>
              <CheckBox name="da"    getter={da}    setter={props.readonly ? noop : setDa}/>
              <CheckBox name="lasd"  getter={lasd}  setter={props.readonly ? noop : setLasd}/>
              <CheckBox name="lapd"  getter={lapd}  setter={props.readonly ? noop : setLapd}/>
              <CheckBox name="court" getter={court} setter={props.readonly ? noop : setCourt}/>
              <CheckBox name="probation" getter={probation} setter={props.readonly ? noop : setProbation}/>
            </ScopeSet>
          </ScopeFields>
          {props.updateHandler && <SetPasswordAdmin enumber={enumber}/>}
        </OptionSet>
      </ProfileFields>
    )
}