import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LeaFilter from './LeaFilter';
import LeaList   from './LeaList';
import { startFetchingLeas, clearMessages } from './LeaActions';
import MessageDisplay from '../ErrorHandling/MessageDisplay';
import { LoadingMessage, DownloadButton } from '../components/Loading';
import { json2csv } from 'json-2-csv';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';

const Dashboard = styled.div`
  margin-top: 15px;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
Dashboard.display = 'Dashboard';

export const LeaDashboard = props => {
  const navigate = useNavigate();
  const runEffectOnlyOnce = true;

  const [msTimeout, setMsTimeout] = useState(16000);

  // The runEffectOnlyOnce restricts the effect to running only
  // once per page load.  But even this is too much if the LEA
  // items have already been loaded from a previous page load
  // and there have been no changes.  Therefore, inside the
  // useEffect function, we verify that the number of items is
  // zero before issuing a request for a new set.
  //
  useEffect(() => {
    console.log('In LeaDashboard useEffect');
    if (props.lea.items.length === 0) {
      console.log(`No LEAs found on page load; starting LEA API fetch.`);
      props.startFetchingLeas(props.apihost, props.apikey);
    } else {
      console.log(`Skipping LeaDashboard fetch; Already have ${props.lea.items.length} items.`);
    }
    return () => {
      console.log('Running LEA Dashboard useEffect cleanup');
      props.clearMessages();
    }
  }, [runEffectOnlyOnce]);

  useEffect(() => {
    console.log('In LeaDashboard fetching effect');
    const dlLink = document.querySelector('#downloadAllCSV');
    if (dlLink) {
      if (props.lea.isFetching) {
        dlLink.disabled = true;
        dlLink.hidden = true;
      } else {
        json2csv(props.lea.items, (err, csvText) => {
          if (err) {
            console.error(`CSV conversion error: ${err.message}`);
            dlLink.disabled = true;
            dlLink.hidden = true;
          } else {
            const csvDataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvText)}`;
            dlLink.disabled = false;
            dlLink.hidden = false;
            dlLink.href = csvDataUrl;
          }
        });
      }
    } else {
      console.error('LEA Dashboard: CSV download link not available in DOM.');
    }
  }, [props.lea.isFetching]);

  function reloadLeas() {
    let newTimeout = msTimeout + 2000;
    console.log(`Refetching LEA codes with timeout ${newTimeout} ms`);
    setMsTimeout(newTimeout);
    props.startFetchingLeas(props.apihost, props.apikey, newTimeout);
  }

  function addLea() {
    navigate('/lea/add');
  }

  return <Dashboard>
      <ButtonRow>
        <button style={{background: 'darkseagreen'}} onClick={reloadLeas}>Refresh LEAs from API</button>
        <button style={{background: 'sandybrown'}} onClick={addLea}>Add LEA Record</button>
        <DownloadButton id="downloadAllCSV" download="LEA.csv">Download CSV</DownloadButton>
        <span className={'message-box'}>
          <MessageDisplay error={props.lea.errorMessage} success={props.lea.successMessage} />
        </span>
      </ButtonRow>
      <LeaFilter />
      {props.lea.isFetching ? <LoadingMessage message={`Fetching LEA codes with ${msTimeout/1000} second timeout.`}/>
                            : <LeaList />
      }
  </Dashboard>
}

const mapStateToProps = state => ({
  lea: state.lea,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey
});

const mapDispatchToProps = {
  startFetchingLeas,
  clearMessages,
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaDashboard);