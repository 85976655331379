import React from 'react';
import styled from 'styled-components';

export const Label = styled.label`
    color: dodgerblue;
    text-align: right;
    margin-right: 5px;
    font-family: "Helvetica", serif;
    font-size: 0.8em;
    align-self: center;
`;
export const Input = styled.input`
  & {
    background-color: #f7fed5;
    align-self: flex-end;
  }
  &&:invalid {
    background-color: #f42300;
  }
`;

export const Select = styled.select`
  &.invalid {
    background-color: #f42300;
  }

  & {
    background-color: #f7fed5;
    align-self: flex-end;
  }
  
`;

// Required props:
//   - id of the input field
//   - label
//   - value
//   - onChange
export const TextField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
             type="text"
             minLength={props.minLength ? props.minLength : 0}
             maxLength={props.maxLength ? props.maxLength : 60}
             pattern={props.pattern ? props.pattern : '.*'}
             title={props.title ? props.title : ''}
             required={props.required ? true : false}
             readOnly={props.readOnly ?? false}
             value={props.value}
             onChange={props.onChange}/>
    </>
  );
}

export const ReadonlyField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
             type="text"
             value={props.value}
             readOnly />
    </>
  );
};

export const DateField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
            type="text"
            pattern="^\d\d\d\d-\d\d-\d\d$"
            required={props.required ? true : false}
            title="Date format yyyy-mm-dd"
            placeholder="yyyy-mm-dd"
            readOnly={props.readOnly}
            value={props.value}
            onChange={props.onChange}/>
    </>
  );
}

export const SelectField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Select id={props.id}
              required={props.required ? true : false}
              pattern={props.pattern ? props.pattern : '.*'}
              value={props.value}
              onChange={props.onChange}>
        {props.children}
      </Select>
    </>
  )
}

const Messages = styled.div`
  margin: 10px 0;
  width: 25em;
  height: 5em;
`;

const Success = styled.p`
  color: green;
  margin-block-start: 2px;
  margin-block-end: 2px;
`;

const Warning = styled.p`
  color: red;
  margin-block-start: 2px;
  margin-block-end: 2px;
`;

export const MessageBox = props => {
  return <fieldset>
    <legend>Messages</legend>
    <Messages>
      <Success>{props.successMessage}</Success>
      <Warning>{props.errorMessage}</Warning> 
      {props.authenticated ? <p>Authenticated</p> : <Warning>Not Authenticated</Warning>}
      <Warning>{props.validateMessage}</Warning>
      {props.fieldsValid ? '' : <Warning>Invalid fields have red background</Warning>}
    </Messages>
  </fieldset>
}

const Header = styled.div`
  display: grid;
  grid-template-columns: 25em 30em;
`;

export const PageHeader = props => {
  return <Header>
    <h2>{props.title}</h2>
    <MessageBox {...props}/>
  </Header>
}
