import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CourtDistForm from './CourtDistForm';
import { clearMessages, startAddCourtDist } from './CourtDistAction';

const addCourtDist = props => {
  const navigate = useNavigate();

  function onSubmit(newCourtDist) {
    props.startAddCourtDist(newCourtDist, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/CourtDistrict/dashboard');
  }

  return <CourtDistForm title="New Court District"
                        submitLabel="Add Record"
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        clearMessages={props.clearMessages}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        authenticated={!!props.token}
         />
}

const mapStateToProps = state => ({
  errorMessage: state.courtdist.errorMessage,
  successMessage: state.courtdist.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  clearMessages,
  startAddCourtDist,
}

export default connect(mapStateToProps, mapDispatchToProps)(addCourtDist);