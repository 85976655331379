import React from 'react';
import styled from 'styled-components';

const ComponentName = styled.th`
  text-align: center;
`

const PropTable = styled.div`
  border-radius: 30px;
  margin: 10px 10px;
  background-color: ${props => props.backgroundColor};
`

const PropName = styled.td`
  text-align: right;
  padding: 2px 6px;
  font-size: 1.0em;
  color: darkblue;
`

const Value = styled.td`
  text-align: left;
  padding: 2px 8px;
  font-size: 0.8em;
  font-family: Consolas, Verdana, sans-serif;
`

const ApiVersion = props => {
  const buildTS = new Date(parseInt(props.build_time));
  return <PropTable backgroundColor={props.backgroundColor}>
    <table style={{borderCollapse: 'separate'}}>
      <colgroup>
        <col style={{width: '80px'}}/>
        <col style={{width: '130px'}}/>
        <col style={{width: '380px'}}/>
      </colgroup>
      <tbody>
        <tr>
          <ComponentName rowSpan="7">{props.heading}</ComponentName>
          <PropName>Build ID:</PropName>
          <Value>{props.build_id}</Value>
        </tr>
        <tr>
          <PropName>Build Number:</PropName>
          <Value>{props.build_no}</Value>
        </tr>
        <tr>
          <PropName>Initiator:</PropName>
          <Value>{props.initiator}</Value>
        </tr>
        <tr>
          <PropName>Git Hash:</PropName>
          <Value>{props.git_hash}</Value>
        </tr>
        <tr>
          <PropName>Build Date:</PropName>
          <Value>{buildTS.toLocaleString()}</Value>
        </tr>
        <tr>
          <PropName>Docker Image:</PropName>
          <Value>{props.image_name}</Value>
        </tr>
        <tr>
          <PropName>Image Tag:</PropName>
          <Value>{props.image_tag}</Value>
        </tr>
      </tbody>
    </table>
  </PropTable>
}

export default ApiVersion;