const defaultInitialState = {
  invalidated: true,
  isFetching: false,
  isUpdating: false,
  errorMessage: '',
  successMessage: '',
  profiles: []
}

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      if (action.profiles) {
        state = {...state, ...action.profiles} ;
      } else {
        console.log('Profile reducer INIT called with no action.profiles');
      }
      console.log(`Profile reducer INIT returning with ${state.profiles.length} profiles.`);
      return state;

    case 'UPDATE_PROFILE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               invalidated: false,
               errorMessage: null,
               successMessage: action.successMessage,
               profiles: state.profiles.map(p => p.enumber === action.enumber
                                              ? { ...p, ...action.update } 
                                              : p)
              };

    case 'START_FETCHING':
      return { ...state,
              isFetching: true,
              invalidated: state.invalidated,
              errorMessage: null,
              successMessage: null,
              profiles: state.profiles };
    
    case 'FINISH_FETCHING':
      return { ...state,
               isFetching: false,
               invalidated: state.invalidated,
               profiles: state.profiles };

    case 'FETCH_ONE':
      return { ...state,
               isFetching: false,
               invalidated: false,
               profiles: state.profiles.map(p => p.enumber === action.enumber
                                              ? action.profile 
                                              : p)
             };

    case 'START_ADDING_PROFILE':
      return { ...state,
               isUpdating: true,
               errorMessages: null,
               successMessages: null
             }

    case 'ADD_PROFILE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               invalidated: false,
               errorMessage: null,
               successMessage: action.successMessage,
               profiles: [...state.profiles, action.profile]
             };

    case 'ADD_FAILED':
      return { ...state,
               isUpdating: false,
               isFetching: false,
               errorMessage: action.message,
               successMessage: null
             }

    case 'START_DELETING_PROFILE':
      return { ...state,
               isUpdating: true,
               errorMessage: null,
               successMessage: null
             };

    case 'DELETE_PROFILE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               invalidated: false,
               errorMessage: null,
               successMessage: action.message,
               profiles: state.profiles.filter(p => p.enumber !== action.enumber)
             };

    case 'CLEAR_FAILED':
      return { ...state,
               errorMessage: null,
               successMessage: null
             }

    default:
      console.log(`Profile default reducer just ran with action.type = ${action.type}!`);
      return state;
  }
}