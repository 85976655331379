import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearFailed } from './ProfileAction';

const ProfileList = styled.div`
  background-color: #f1f1f1f1;
  margin: 10px;
  padding: 10px;

  & .selected {
    font-weight:bold;
  }
  & .not_selected {
    font-weight: normal;
  }
  & ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li {
      display: block;
      margin-left: 0px;
      font-size: 24px;
      line-height: 30px;
      font-family: "Courier New", consolas, monospace;
    }
  }
`;

const profileList = (props) => {

  function onSelectionChange(evt) {
    props.onEnumberChange(evt.target.id);
    props.clearFailed();
  }

  return <ProfileList className="profile_list">
    {
      props.profiles.length > 0 ? (
        <ul>
          {
            props.profiles.map(p => (
              <li key={p.enumber}
                  id={p.enumber}
                  onClick={onSelectionChange}
                  className={props.selection === p.enumber ? 'selected' : 'not_selected'}
              >
                {p.enumber}
              </li>
            ))
          }
        </ul>
      ) : (
        <div>
          Empty List
        </div>
      )
    }
  </ProfileList>
}

const mapState = state => ({ profiles: state.profiles.profiles })
const mapDispatch = { clearFailed };

export default connect(mapState, mapDispatch)(profileList);