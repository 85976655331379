const defaultInitialState = {
  apikey: null,
  urlbase: "http://localhost:6010"
};

export default (prefix = '') => {
  return (state = defaultInitialState, action) => {
    switch(action.type) {
      case prefix + 'SET_CURRENT_HOST':
        return { ...state, urlbase: action.apiHost.urlbase,
                            apikey: action.apiHost.apikey };

      case prefix + 'SET_API_KEY':
        return { ...state, apikey: action.apiKey }

      default:
        console.log(`HostSelector default reducer just ran with action.type = ${action.type}!`);
        return state;
    }
  }
}