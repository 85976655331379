import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LandingPageLayout = styled.main`
  display: grid;
  min-width: 600px;
  max-width: 800px;
  margin: 0 10px;
`;

const TableLinks = styled.section`
  margin: 15px;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 20px;

  a {
    text-decoration: none;
  }
`;

const Card = styled.article`
  display: grid;
  grid-template-rows: max-content 210px 2fr;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  border-radius: 5px;
  background: cornsilk;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  & header {
    text-align: center;
    font-family: Palatino;
    letter-spacing: 2px;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & p {
    color: black;
    padding: 2px 16px;
    font-family: Bookman, Georgia, "Times New Roman";
  }
`;

const LandingDashboard = () => {
  return <LandingPageLayout>
    <TableLinks>
      <Cards>
        <Link to="/ChargeCode/dashboard">
          <Card>
            <header><h4>Charge Codes</h4></header>
            <img alt="Gavel image" src="/images/gavel-unsplash.jpg"/>
            <p>Los Angeles County DA UCC codes</p>
          </Card>
        </Link>
        <Link to="/lea/dashboard">
          <Card>
            <header><h4>LEAs</h4></header>
            <img alt="Building image" src="/images/building-unsplash.jpg"/>
            <p><strong>L</strong>aw <strong>E</strong>nforcement <strong>A</strong>gency information</p>
          </Card>
        </Link>
        <Link to="/CourtDepartment/dashboard">
          <Card>
            <header><h4>Court Departments</h4></header>
            <img alt="LA Court Building" src="/images/lasc1.jpg"/>
            <p>Los Angeles Superior Court Departments</p>
          </Card>
        </Link>
        <Link to="/CourtDistrict/dashboard">
          <Card>
            <header><h4>Court Districts</h4></header>
            <img alt="LA Court Building" src="/images/lasc2.jpg"/>
            <p>Los Angeles Superior Court Districts</p>
          </Card>
        </Link>
      </Cards>
    </TableLinks>
    <section>
      <h4>Related Links</h4>
      <p><a href="https://docs.codes.lacounty-isab.org">API Access</a> – 
        Information for programmers to embed table access within their own applications.
      </p>
    </section>
  </LandingPageLayout>
}

export default LandingDashboard;