import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProfileForm from './ProfileForm';
import { startAddProfile } from './ProfileAction';

const CreateProfile = styled.div`
  margin-left: 10px;
`;
CreateProfile.displayName = 'CreateProfile';

const createProfile = (props) => {
  const createProfile = (newProfile) => {
    props.startAddProfile(newProfile, props.token, props.authhost, props.apikey);
  }
  return <CreateProfile>
    <h3>New Profile</h3>
    <ProfileForm createHandler={createProfile}
                 error={props.error}
                 success={props.success}/>
  </CreateProfile>
}

const mapStateToProps = state => ({
  error:   state.profiles.errorMessage,
  success: state.profiles.successMessage,
  authhost: state.authserver.urlbase,
  apikey:   state.authserver.apikey,
  token:    state.credentials.token,
});

const mapDispatchToProps = { startAddProfile };

export default connect(mapStateToProps, mapDispatchToProps)(createProfile);