import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChargeListRow from './ChargeListRow';

const ChargeCodeTable = styled.table`
  border-collapse: collapse;
  position: relative;
`;
ChargeCodeTable.displayName = 'ChargeCodeTable';

const ChargeCodeHeader = styled.thead`
  th {
    background-color: #b8b8b8;
    padding: 0 10px 0 5px;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 10;
  }
`;
ChargeCodeHeader.displayName = 'ChargeCodeHeader';

export const ChargeList = props => <div>
  {
    props.charges.items.length === 0 ? (
      <p>Empty charge list</p>
    ) : (
      <ChargeCodeTable>
        <ChargeCodeHeader>
          <tr>
            <th>Code</th>
            <th>Statute</th>
            <th>Literal ID</th>
            <th>Degree</th>
            <th>Level</th>
            <th>Expiration</th>
            <th>Full Description</th>
            <th>Template ID</th>
            <th>Last Update</th>
          </tr>
        </ChargeCodeHeader>
        <tbody>
          { props.charges.filteredItems.map((chg, index) => (
              <ChargeListRow {...chg}
                        index={index+1}
                        key={chg.id} />
            ))
          }
        </tbody>
      </ChargeCodeTable>
    )
  }
</div>

const mapStateToProps = state => ({ charges: state.charges });

export default connect(mapStateToProps)(ChargeList);