import React from 'react';
import styled from 'styled-components';
import { Name, VarValue, MonoValue } from '../components/DisplayFields';

const ChargeCodeDetailHover = styled.div`
  grid-template-columns: 10em 20em;
`;
ChargeCodeDetailHover.displayName = 'ChargeCodeDetailHover';

export default (props) => {
  return <ChargeCodeDetailHover>
    <Name>Short Description</Name>
    <VarValue>{props.short_description}</VarValue>

    <Name>ID</Name>
    <MonoValue>{props.id}</MonoValue>

    <Name>BCS Code</Name>
    <MonoValue>{props.bcs_class_code}</MonoValue>

    <Name>Hierarchy</Name>
    <MonoValue>{props.bcs_hierarchy}</MonoValue>

    <Name>Effective date</Name>
    <VarValue>{props.eff_date}</VarValue>

    <Name>Classification Code</Name>
    <VarValue>{props.classification_code}</VarValue>

    <Name>Custody Code</Name>
    <MonoValue>{props.custody_code}</MonoValue>

    <Name>State CJIS Code</Name>
    <VarValue>{props.state_cjis_code}</VarValue>

    <Name>Sentencing Code</Name>
    <VarValue>{props.sentence_range}</VarValue>

    <Name>Bail Amount</Name>
    <MonoValue>{props.bail_amount}</MonoValue>

    <Name>Reporting District</Name>
    <VarValue>{props.reporting_district}</VarValue>

    <Name>Composite Key</Name>
    <MonoValue>{props.composite_key}</MonoValue>

    <Name>Reduce Flag</Name>
    <VarValue>{props.reduce_flag}</VarValue>

    <Name>Violent Flag</Name>
    <VarValue>{props.violent_flag}</VarValue>

    <Name>Register Flag</Name>
    <VarValue>{props.register}</VarValue>

    <Name>Destruct Flag</Name>
    <VarValue>{props.destruct}</VarValue>

  </ChargeCodeDetailHover>
}
