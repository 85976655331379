import React from 'react';
import { connect } from 'react-redux';
import CourtDistListRow from './CourtDistListRow';
import styled from 'styled-components';

const Table = styled.table`
  border-collapse: collapse;
  thead {
    background: #563600;
    color: #ffeed5;
  }
  th {
    padding: 0 3px;
  }
  th:nth-child(1) {
    width: 2em;
  }
  th:nth-child(2) {
    width: 3em;
  }
  th:nth-child(3) {
    width: 20em;
  }
  th:nth-child(4) {
    width: 15em;
  }
  th:nth-child(5) {
    width: 2em;
  }
`;
Table.displayName = 'Table';

const List = props => (
  <div>
    {
      props.courtdist.items.length === 0 ? (
        <p>Empty Court District list</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Court Type</th>
              <th>Judicial District</th>
              <th>Description</th>
              <th>City</th>
              <th>Case Prefix</th>
              <th>Expiration</th>
            </tr>
          </thead>
          <tbody>
            {
              props.courtdist.filteredItems.map(item => (
                <CourtDistListRow {...item} key={`${item.court_type}${item.judicial_district}`} />
              ))
            }
          </tbody>
        </Table>
      )
    }
  </div>
)

const mapStateToProps = state => ({
  courtdist: state.courtdist
});

export default connect(mapStateToProps)(List);