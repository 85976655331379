import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, SelectField, PageHeader,
         Label, Input } from '../components/FormFields';

export default function CourtDepartmentForm(props) {
  let dept = props.courtdept || {};
  const [department,   setDepartment]  = useState(dept.department || '');
  const [court_type,   setCourt_type]   = useState(dept.court_type || '');
  const [judicial_district, setJudicial_district] = useState(dept.judicial_district || '');
  const [description,  setDescription] = useState(dept.description || '');
  const [address,      setAddress]     = useState(dept.address || '');
  const [city,         setCity]        = useState(dept.city || '');
  const [state,        setState]       = useState(dept.state || '');
  const [zip,          setZip]         = useState(dept.zip || '');
  const [phone,        setPhone]       = useState(dept.phone || '');
  const [exp_date,      setExp_date]   = useState(dept.exp_date || '');
  const [prob_svc_office, setProb_svc_office] = useState(dept.prob_svc_office || '');

  const [origDept,    setOrigDept]    = useState(new Map());
  const [diffDept,    setDiffDept]    = useState(new Map());
  const [fieldsValid, setFieldsValid] = useState(true);

  useEffect(() => {
    if (origDept.has('court_type')) {  // origDept already populated
      const changes = new Map();
      if (origDept.get('department') !== department) changes.set('department', department);
      if (origDept.get('court_type') !== court_type) changes.set('court_type', court_type);
      if (origDept.get('judicial_district') !== judicial_district) changes.set('judicial_district', judicial_district);
      if (origDept.get('description') !== description) changes.set('description', description);
      if (origDept.get('address')     !== address)  changes.set('address', address);
      if (origDept.get('city')        !== city)     changes.set('city', city);
      if (origDept.get('state')       !== state)    changes.set('state', state);
      if (origDept.get('zip')         !== zip)      changes.set('zip', zip);
      if (origDept.get('phone')       !== phone)    changes.set('phone', phone);
      if (origDept.get('exp_date')    !== exp_date) changes.set('exp_date', exp_date);
      if (origDept.get('prob_svc_office') !== prob_svc_office) changes.set('prob_svc_office', prob_svc_office);
      setDiffDept(changes);
    } else {
      const original = new Map();
      original.set('department',  dept.department    || '');
      original.set('court_type',  dept.court_type    || '');
      original.set('judicial_district', dept.judicial_district || '');
      original.set('description', dept.description   || '');
      original.set('address',     dept.address       || '');
      original.set('city',        dept.city          || '');
      original.set('state',       dept.state         || '');
      original.set('zip',         dept.zip           || '');
      original.set('phone',       dept.phone         || '');
      original.set('exp_date',    dept.exp_date      || '');
      original.set('prob_svc_office', dept.prob_svc_office || '');
      setOrigDept(original);
    }
    const invalidElements = document.querySelectorAll('input:invalid');
    setFieldsValid(invalidElements.length === 0);
  }, 
  [ department, court_type, judicial_district, description,
    address, city, state, zip, phone, exp_date, prob_svc_office
  ])

  useEffect(() => {
    const submitButtons = document.getElementsByClassName('submit');
    if (submitButtons.length) {
      for (const button of submitButtons) {
        button.disabled = !!props.successMessage
                        || !fieldsValid
                        || (props.authenticated === undefined ? false : !props.authenticated);
      }
    }
    return () => {
        if (props.errorMessage || props.successMessage) {
          props.clearMessages();
        }
      }
    },
    [props.errorMessage, props.successMessage, props.authenticated, fieldsValid]);
  
  useEffect(() => {
    const courtTypeSelect = document.querySelector('select#court_type');
    if (courtTypeSelect === undefined) {
      console.error('Failed to locate select#court_type element; cannot validates it\'s value');
    } else {
      if (court_type === '') {
        console.log('Court Type has NOT been set');
        courtTypeSelect.classList.add('invalid');
      } else {
        console.log('Court Type has been set');
        courtTypeSelect.classList.remove('invalid');
      }
    }
  }, [court_type]);

  function onClear(evt) {
    evt.preventDefault();
    setCourt_type('');
    setJudicial_district('');
    setDepartment('');
    setDescription('');
    setAddress('');
    setCity('');
    setState('');
    setZip('');
    setPhone('');
    setExp_date('');
    setProb_svc_office('');
    props.clearMessages();
  }

  function onSubmit(evt) {
    evt.preventDefault();
    const modifiedFields = { court_type, judicial_district, department }; // Not changed; but used as key
    diffDept.forEach((value, field) => modifiedFields[field] = value);
    console.log(`Changed dept state: ${JSON.stringify(modifiedFields)}`);
    props.onSubmit(modifiedFields);
  }

  return <CourtDeptPage>
    <PageHeader title={props.title}
                authenticated={props.authenticated}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                fieldsValid={fieldsValid}
    />
    <ButtonRow>
      <button style={{background: 'orange'}} onClick={onSubmit} className="submit">
        {props.submitLabel}
      </button>
      <button style={{background: 'lightskyblue'}} onClick={props.onCancel}>
        Back to List
      </button>
      <button style={{background: 'burlywood'}} onClick={onClear}>
        Clear Fields
      </button>
      <span style={{'fontStyle': 'italic'}}>Last Updated:</span>
      <span>{dept.last_updated || 'New'}</span>
    </ButtonRow>
    <FieldSectionLayout>
      <TextField id="department"
                 label="Department"
                 maxLength="3"
                 required
                 value={department}
                 onChange={e => setDepartment(e.target.value)} />
      <SelectField id="court_type"
                   label="Court Type"
                   value={court_type}
                   onChange={e => setCourt_type(e.target.value)} >
        <option value="">-- Choose --</option>
        <option value="M">M – Municipal</option>
        <option value="S">S – Superior</option>
        <option value="X">X – Juvenile</option>
        <option value="J">J – Justice</option>
        <option value="P">P – Parole</option>
      </SelectField>
      <TextField id="judicial_district"
                 label="Judicial District"
                 maxLength="3"
                 required
                 value={judicial_district}
                 onChange={e => setJudicial_district(e.target.value)} />
      <TextField id="city"
                 label="City"
                 maxLength="20"
                 value={city}
                 onChange={e => setCity(e.target.value)} />
      <Label htmlFor="description">Description</Label>
      <Input id="description"
             type="text"
             style={{gridColumn: '2 / span 3'}}
             required
             maxLength="30"
             value={description}
             onChange={e => setDescription(e.target.value)} />
      <Label htmlFor="address">Address</Label>
      <Input id="address"
             type="text"
             style={{gridColumn: '2 / span 3'}}
             maxLength="37"
             value={address}
             onChange={e => setAddress(e.target.value)} />
      <TextField id="state"
                 label="State"
                 maxLength="2"
                 title="Two-character state code"
                 value={state}
                 onChange={e => setState(e.target.value)} />
      <Label htmlFor="zip">Zip</Label>
      <Input id="zip"
             type="text"
             minLength="5"
             maxLength="10"
             pattern="\d\d\d\d\d(-\d\d\d\d)?"
             value={zip}
             onChange={e => setZip(e.target.value)} />
      <Label htmlFor="phone" style={{gridColumn: '3'}}>Phone</Label>
      <Input id="phone"
             type="text"
             maxLength="18"
             placeholder="nnn-nnn-nnnn"
             pattern="\d\d\d-\d\d\d-\d\d\d\d"
             value={phone}
             onChange={e => setPhone(e.target.value)} />
      <Label htmlFor="exp_date" style={{gridColumn: '2 / span 2'}}>Expire Date</Label>
      <Input id="exp_date"
             type="text"
             pattern="^\d\d\d\d-\d\d-\d\d$"
             title="Date format yyyy-mm-dd"
             placeholder="yyyy-mm-dd"
             value={exp_date}
             onChange={e => setExp_date(e.target.value)} />
      <Label htmlFor="prob_svc_office" style={{gridColumn: '2 / span 2'}}>Probation Service Office</Label>
      <Input id="prob_svc_office"
             type="text"
             maxLength="6"
             value={prob_svc_office}
             onChange={e => setProb_svc_office(e.target.value)} />           
    </FieldSectionLayout>
  </CourtDeptPage>;
}

// Styles used for layout.
//
const FieldSectionLayout = styled.section`
  display: grid;
  grid-template-columns: 8em 3em 6em 12em;
  row-gap: 10px;
`;
FieldSectionLayout.displayName = 'FieldSectionLayout';

const CourtDeptPage = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 900px;
`;
CourtDeptPage.display = 'CourtDeptPage';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';