import React from 'react';
import { connect } from 'react-redux';
import LeaListRow from './LeaListRow';
import styled from 'styled-components';

const LeaTable = styled.table`
  border-collapse: collapse;
  position: relative;

  th {
    background: #563600;
    color: #ffeed5;
    position: sticky;
    top: 0;
  }
`;
LeaTable.displayName = 'LeaTable';

const LeaList = props => (
  <div>
    {
      props.lea.items.length === 0 ? (
        <p>Empty LEA list</p>
      ) : (
        <LeaTable>
          <thead id="LeaHeader">
            <tr>
              <th>Code</th>
              <th>NCIC</th>
              <th>Prim LEA</th>
              <th>Subp</th>
              <th>Name</th>
              <th>City</th>
              <th>Expired</th>
              <th>Expiration</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody id="LeaList">
            {
              props.lea.filteredItems.map((lea, index) => (
                <LeaListRow {...lea}
                            index={index+1}
                            key={lea.arrest_agency_code} />))
            }
          </tbody>
        </LeaTable>
      )
    }
  </div>
)

const mapStateToProps = state => ({
  lea: state.lea
})


export default connect(mapStateToProps)(LeaList);