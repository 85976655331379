import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CourtDeptDashboard from './CourtDeptDashboard';
import AddCourtDept       from './AddCourtDept';
import UpdateCourtDept    from './UpdateCourtDept';

const CourtDeptRouter = () => <div>
  <Routes>
    <Route path={'/dashboard'}  element={<CourtDeptDashboard />} />
    <Route path={'/add'} element={<AddCourtDept />} />
    <Route path={'/detail/:judicial_district/:department/:court_type'} element={<UpdateCourtDept />} />
  </Routes>
</div>;

export default CourtDeptRouter;