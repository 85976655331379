import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CourtDeptForm from './CourtDeptForm';
import { clearMessages, startAddCourtDept } from './CourtDeptAction';

const addCourtDept = props => {
  const navigate = useNavigate();

  function onSubmit(newCourtDept) {
    props.startAddCourtDept(newCourtDept, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/CourtDepartment/dashboard');
  }

  return <CourtDeptForm title="New Court Department"
                        submitLabel="Add Record"
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        clearMessages={props.clearMessages}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        authenticated={!!props.token}
         />
}

const mapStateToProps = state => ({
  errorMessage: state.courtdept.errorMessage,
  successMessage: state.courtdept.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  clearMessages,
  startAddCourtDept,
}

export default connect(mapStateToProps, mapDispatchToProps)(addCourtDept);