import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import CourtDeptForm from './CourtDeptForm';
import { clearMessages, startUpdateCourtDept } from './CourtDeptAction';

const updateCourtDept = props => {
  const navigate = useNavigate();
  const { court_type, judicial_district, department } = useParams();
  console.log(`Updating court department with id = (${court_type}, ${judicial_district}, ${department})`);

  const dept = props.courtdept.items.find(item => item.court_type === court_type
                                               && item.judicial_district === judicial_district
                                               && item.department === department);

  function onSubmit(modifiedValues) {
    props.startUpdateCourtDept(modifiedValues, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/CourtDepartment/dashboard');
  }

  return <CourtDeptForm title="Update Court Department"
                        submitLabel="Update Record"
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        clearMessages={props.clearMessages}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        authenticated={!!props.token}
                        courtdept={dept}
          />
}

const mapStateToProps = (state) => ({
  courtdept: state.courtdept,
  errorMessage: state.courtdept.errorMessage,
  successMessage: state.courtdept.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  startUpdateCourtDept,
  clearMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(updateCourtDept);