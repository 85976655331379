import React from 'react';
import styled from 'styled-components';
import { Name, VarValue, MonoValue } from '../components/DisplayFields';

const CourtDistDetailHover = styled.div`
  grid-template-columns: 12em 16em;
`;

export default (props) => {
  return <CourtDistDetailHover>
    <Name>State Code</Name> 
    <MonoValue>{props.state_code}</MonoValue>

    <Name>State District Code</Name>
    <MonoValue>{props.state_district_code}</MonoValue>

    <Name>Judicial District Name</Name>
    <VarValue>{props.judicial_district_name}</VarValue>

    <Name>Probation Service Office</Name>
    <VarValue>{props.prob_svc_office}</VarValue>

    <Name>Last Updated</Name>
    <MonoValue>{props.last_updated}</MonoValue>
  </CourtDistDetailHover>
}