import React  from 'react';
import { connect } from 'react-redux';
import { setCurrentRow } from './ChargeConversionActions';

export const chargeConversionListRow = props => {

  function handleClick() {
    console.log(`handleRowClick: source=${props.source}, cjis=${props.cjis}, external=${props.external}`);
    props.handleRowSelect(props.source, props.cjis, props.external);
  }

  return (
    <tr onClick={handleClick}>
      <td>{props.source}</td>
      <td>{props.cjis}</td>
      <td>{props.external}</td>
      <td>{props.last_updated}</td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleRowSelect: (source, cjis, external) => dispatch(setCurrentRow({ source, cjis, external }))
});

export default connect(null, mapDispatchToProps)(chargeConversionListRow);