const initialLeaDisplayFilter = {
  codeFilter: '',
  ncicFilter: '',
  expiredFromFilter: '',
  expiredToFilter: '',
  expiredFlagFilter: '',
  updatedFromFilter: '',
  updatedToFilter: '',
  subpoenaFilter: '',
  nameFilter: '',
  cityFilter: '',
  primaryLeaFilter: '',
  useridFilter: '',
}

const defaultInitialState = {
  isFetching: false,
  errorMessage: '',
  successMessage: '',
  items: [],
  filterCriteria: initialLeaDisplayFilter,
  filteredItems: [],
  currentRow: '',
  unauthorizedFields: [],
};

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      if (action.lea) {
        console.log('LEA INITIALIZE called with action.lea.items.length = ', action.lea.items.length);
        state = action.lea;
      } else {
        console.log('LEA INITIALIZE called with no action.lea.');
      }
      console.log(`LEA INITIALIZE returning with ${state.items.length} items.`);
      return state;

    case 'START_FETCHING':
      return { ...state,
               isFetching: true }
    
    case 'FINISH_FETCHING':
      return { ...state,
               isFetching: false}
             
    case 'UPDATE_LEA_FETCH_RESULTS':
      return { ...state,
               errorMessage: action.errorMessage,
               successMessage: action.successMessage,
               items: action.items }
    
    case 'UPDATE_LEA':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: state.items.map(lea => lea.arrest_agency_code === action.arrest_agency_code
                                    ? { ...lea, ...action.update }
                                    : lea)
             };
    
    case 'ADD_LEA':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: [...state.items, action.newLea],
             };

    case 'SET_LEA_ROW':
      return { ...state,
               currentRow: action.id };

    case 'SET_LEA_DISPLAY_FILTER_CRITERIA':
      return { ...state,
               filterCriteria: action.filterCriteria };
      
    case 'SET_FILTERED_LEAS':
      return { ...state,
               filteredItems: action.filteredItems };
    
    case 'SET_ERROR_MESSAGE':
      return { ...state,
               errorMessage: action.errorMessage };

    case 'CLEAR_MESSAGES':
      return {...state,
              errorMessage: '',
              successMessage: '',
              unauthorizedFields: []
            };

    case 'UNAUTORIZED_FIELDS':
      return { ...state,
               unauthorizedFields: action.unauthorizedFields
             };

    default:
      console.log('LEA default reducer just ran with action.type=', action.type);
      return state;
  }
}