import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setWebVersion,
         startFetchApiVersion,
         startFetchAuthVersion } from './VersionAction';
import WebVersion from './WebVersion';
import ApiVersion from './ApiVersion';

import * as webBuildInfo from './build.json';

const VersionDashboard = props => {
  const [ web, setWeb]   = useState(null);
  const [ api, setApi]   = useState(null);
  const [ auth, setAuth] = useState(null);

  useEffect(() => {
    if (props.webVersion) {
      setWeb(props.webVersion);
    } else {
      props.setWebVersion(webBuildInfo);
    }
    if (props.apiVersion) {
      setApi(props.apiVersion);
    } else {
      props.startFetchApiVersion(props.apihost, props.apikey);
      console.log('Start fetching API version info from ', props.apihost)
    }
    if (props.authVersion) {
      setAuth(props.authVersion);
    } else {
      props.startFetchAuthVersion(props.authhost, props.authkey);
      console.log('Start fetching Auth version info from ', props.authhost)
    }
  }, [props.webVersion, 
      props.apiVersion,
      props.authVersion,
      props.apihost,
      props.authhost,
     ]);

  return <div style={{ maxWidth: '600px' }}>
    {web  && <WebVersion heading="UI"   {...web} backgroundColor="#e0cdc6" />}
    {api  && <ApiVersion heading="API"  {...api} backgroundColor="#c4c6af" />}
    {auth && <ApiVersion heading="IDP" {...auth} backgroundColor="#a7be97" />}
  </div>;
}

const mapStateToProps = (state) => ({
  token:    state.credentials.token,
  authhost: state.authserver.urlbase,
  authkey:  state.authserver.apikey,
  apihost:  state.apiserver.urlbase,
  apikey:   state.apiserver.apikey,
  webVersion:  state.versions.webVersion,
  apiVersion:  state.versions.apiVersion,
  authVersion: state.versions.authVersion,
});

const mapDispatchToProps = {
  setWebVersion,
  startFetchApiVersion,
  startFetchAuthVersion
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionDashboard);