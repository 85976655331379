import fetch from 'cross-fetch';
import messages from '../ErrorHandling/messages';

const profileUri = '/idp/profile';
const standardHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const initialize = (initialProfiles) => ({
  type: 'INITIALIZE',
  profiles: initialProfiles
});

const startFetching = () => ({ type: 'START_FETCHING'});
const finishFetching = () => ({ type: 'FINISH_FETCHING'});

export const startFetchingProfiles = (apihost, token, apikey) => {
  const fetchUrl = apihost + profileUri;
  const fetchOptions = { method: 'GET' };
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  fetchOptions.headers['Authorization'] = `Bearer ${token}`;
  return (dispatch) => {
    dispatch(startFetching());
    return fetch(fetchUrl, fetchOptions).then(response => {
      const reqId = response.headers.get('x-cjisauth-requestid');
      console.log(`Profile GET status code = ${response.status}; IDP-Request ID: ${reqId}`);
      if (response.ok) {
        return response.json();
      } else {
        let errorMessage;
        switch(response.status) {
          case 400:
          case 401:
          case 403:
          case 500: errorMessage = `${messages[`http${response.status}`]}; IDP-Request ID: ${reqId}`; break;
          default: errorMessage = `Encountered HTTP status ${response.status}; IDP-Request ID: ${reqId}`;
        }
        return { error: {
            code: response.status,
            message: errorMessage
          }
        }
      }
    }).then(response => {
      if (response.error) {
        console.log(`Error code: ${response.error.code}, Message: ${response.error.message}.`);
        dispatch(addFailed(response.error.message));
      } else {
        const profiles = response;
        console.log(`Profile results size = ${profiles.length}.`);
        dispatch(finishFetching());
        dispatch(initialize({
          invalidated: false,
          isFetching: false,
          isUpdating: false,
          errorMessage: '',
          profiles
        }));
      }
    });
  };
};

const updateProfile = (modifiedProfile, successMessage = 'Success') => ({
  type: 'UPDATE_PROFILE',
  enumber: modifiedProfile.enumber,
  update: modifiedProfile,
  successMessage
});

export const startUpdateProfile = (modifiedProfile, token, apihost, apikey) => {
  return startAddUpdateProfile(modifiedProfile, token, 'PATCH', apihost, apikey, (response, dispatch) => {
    dispatch(updateProfile(modifiedProfile, 'Update successful.'));
  });
}

export const startAddProfile = (profile, token, apihost, apikey) => {
  return startAddUpdateProfile(profile, token, 'POST', apihost, apikey, (response, dispatch) => {
    dispatch(addProfile(profile));
  });
}

const startAddUpdateProfile = (profile, token, method, apihost, apikey,  callback) => {
  const fetchUrl = apihost + profileUri;
  const fetchOptions = { method };
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  fetchOptions.headers['Authorization'] = `Bearer ${token}`;
  fetchOptions['body'] = JSON.stringify(profile);
  return (dispatch) => {
    dispatch(startAdding());
    return fetch(fetchUrl, fetchOptions).then(response => {
      const reqId = response.headers.get('x-cjisauth-requestid');
      console.log(`Profile ${method} status code = ${response.status}; Request ID = ${reqId}`)
      if (response.ok) {
        return {};
      } else {
        let errMessage;
        switch(response.status) {
          case 400:
          case 401:
          case 403:
          case 500: errMessage = `${messages[`http${response.status}`]}; IDP-Request ID: ${reqId}`; break;
          default:  errMessage = `Encountered HTTP status ${response.status}; Request ID: ${reqId}`;
        }
        return { error: {
          code: response.status,
          message: errMessage
          }
        }
      }
    }).then(response => {
      if (response.error) {
        console.log(`Error code: ${response.error.code}, Message: ${response.error.message}.`);
        dispatch(addFailed(response.error.message));
      } else {
        callback(response, dispatch);
      }
    }).catch(err => {
      console.log(`Fetch API returned error: ${err}.`);
      dispatch(addFailed(err.toString()));
    })
  }
};

// Add the "failed" state.
//
export const addFailed = (error) => ({
  type: 'ADD_FAILED',
  message: error
})
// Clear the "failed" state.
//
export const clearFailed = () => ({type: 'CLEAR_FAILED'});

const startAdding = () => ({type: 'START_ADDING_PROFILE'});
const addProfile = (profile, successMessage = 'Success') => ({
  type: 'ADD_PROFILE',
  profile,
  successMessage
});

const startDeleting = () => ({type: 'START_DELETING_PROFILE'});

const deleteProfile = (enumber, message='Success') => ({
  type: 'DELETE_PROFILE',
  enumber,
  message
});

export const startDeleteProfile = (enumber, token, apihost, apikey) => {
  const fetchUrl = apihost + profileUri + `?enumber=${enumber}`;
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return (dispatch) => {
    dispatch(startDeleting);
    return fetch(fetchUrl, fetchOptions).then(response => {
      const reqId = response.headers.get('x-cjisauth-requestid');
      console.log(`Profile DELETE status code = ${response.status}; Request ID = ${reqId}`);
      if (response.ok) {
        return {};
      } else {
        let errMessage;
        switch (response.status) {
          case 400:
          case 401:
          case 403:
          case 500: errMessage = `${messages[`http${response.status}`]}; IDP-Request ID: ${reqId}`; break;
          default:  errMessage = `Encountered HTTP status ${response.status}; IDP-Request ID: ${reqId}`;
        }
        return { error: {
          code: response.status,
          message: errMessage
        }}
      }
    }).then(response => {
      if (response.error) {
        console.error(`Delete Profile API returned ${response.error.code} with message ${response.error.message}`);
        dispatch(addFailed('Deletion failed'));
      } else {
        console.log(`Profile deletion succeeded.`);
        dispatch(deleteProfile(enumber));
      }
    }).catch(err => {
      console.error('Fetch failure:', err.message);
      dispatch(addFailed('Failed to reach API.'));
    });
  }
}