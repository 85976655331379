import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChargeConversionListRow from './ChargeConversionListRow';
import ChargeConversionFilter  from './ChargeConversionFilter';

const ConversionList = styled.div`
  height: 600px;
`;

const ConversionTable = styled.div`
  height: 25em;
  overflow-y: auto;
  border: 2px solid #ddd;

  table {
    width: 100%;
    border-collapse: collapse;
    position: relative;

    thead tr th {
      background-color: cornsilk;
      position: sticky;
      top: 0;
    }

    tbody tr {
      font-family: consolas,monospace;

      &:hover {
        background-color: #ddd;
      }
    }
  }
`;

export const ChargeConversionList = props => {

  let items = props.conversions.items;
  if (props.conversions.filterCriteria) {
    const sourceFilter = props.conversions.filterCriteria.source;
    const cjisFilter   = props.conversions.filterCriteria.cjis;
    const externalFilter = props.conversions.filterCriteria.external;
    items = props.conversions.items.filter(item => (
      (sourceFilter === 'none' || item.source   && item.source === sourceFilter)     &&
      (!cjisFilter             || item.cjis     && item.cjis.startsWith(cjisFilter)) &&
      (!externalFilter         || item.external && item.external.startsWith(externalFilter))
    ))
  }
  
  return (
    <ConversionList>
      <ChargeConversionFilter />
      {
        props.conversions.items.length === 0 ? (
          <p>Empty Charge Conversion List</p>
        ) : (
        <ConversionTable>
          <table>
            <thead>
              <tr>
                <th>Source</th>
                <th>CJIS</th>
                <th>External</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              { items.map((conv, index) => (
                <ChargeConversionListRow {...conv}
                                        index={index+1}
                                        key={conv.source + conv.external} /> ))
              }
            </tbody>
          </table>
        </ConversionTable>
        )
      }
    </ConversionList>
  )
};

const mapStateToProps = state => ({ conversions: state.conversions });

export default connect(mapStateToProps)(ChargeConversionList);