import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ProfileList from './ProfileList';
import ShowProfile from './ShowProfile';

const ProfileDetail = styled.div`
  display: flex;
  flex-direction: row;
`;

const profileDetail = () => {

  const [enumber, setEnumber] = useState(useParams().enumber);

  return <ProfileDetail className="profile_detail">
    <ProfileList selection={enumber} 
                 onEnumberChange={setEnumber}/>
    <ShowProfile enumber={enumber}/>
  </ProfileDetail>
}

export default profileDetail;