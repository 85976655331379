import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ListFilter from './CourtDistFilter';
import List from './CourtDistList';
import { startFetchingCourtDists, clearMessages } from './CourtDistAction';
import MessageDisplay from '../ErrorHandling/MessageDisplay';
import { LoadingMessage, DownloadButton } from '../components/Loading';
import { json2csv } from 'json-2-csv';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';

const Dashboard = styled.div`
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
Dashboard.display = 'Dashboard';

const CourtDistDashboard = props => {
  const navigate = useNavigate();
  const runEffectOnlyOnce = true;

  const [msTimeout, setMsTimeout] = useState(16000);

  // The runEffectOnlyOnce restricts the effect to running only
  // per page load.  But even this is too much of the once Court
  // Department items have already been loaded from a previous
  // page load and there are no changes, because a page load
  // happens every time the tab is selected.  Therefore, inside
  // the useEffect function, we verify that the number of items
  // is zero before issuing a request for a new set.
  //
  useEffect(() => {
    console.log('In Court District Dashboard useEffect');
    if (props.courtdist.items.length === 0) {
      console.log('No court districts found on page load; start API fetch');
      props.startFetchingCourtDists(props.apihost, props.apikey);
    } else {
      console.log(`Skipping Court District dashboard fetch; already have ${props.courtdist.items.length} items`);
    }
    return () => {
      console.log('Running Court District useEffect cleanup');
      props.clearMessages();
    }
  }, [runEffectOnlyOnce]);

  useEffect(() => {
    console.log('In Court District dashboard fetching effect');
    const dlLink = document.querySelector('#downloadAllCSV');
    if (dlLink) {
      if (props.courtdist.isFetching) {
        dlLink.disabled = true;
        dlLink.hidden = true;
      } else {
        json2csv(props.courtdist.items, (err, csvText) => {
          if (err) {
            console.error(`CSV conversion error: ${err.message}`);
            dlLink.disable = true;
            dlLink.hidden = true;
          } else {
            const csvDataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvText)}`
            dlLink.disabled = false;
            dlLink.hidden = false;
            dlLink.href = csvDataUrl;
          }
        });
      }
    } else {
      console.error('Court District Dashboard: CSV download link not available in DOM');
    }
  }, [props.courtdist.isFetching]);

  function reloadCourtDept() {
    let newTimeout = msTimeout + 2000;
    console.log(`Refetching Court Districts with timeout ${newTimeout} ms`);
    setMsTimeout(newTimeout);
    props.startFetchingCourtDists(props.apihost, props.apikey, newTimeout);
  }

  return <Dashboard>
    <ButtonRow>
      <button style={{background: 'darkseagreen'}} onClick={reloadCourtDept}>Refresh from API</button>
      <button style={{background: 'sandybrown'}} onClick={() => navigate('/CourtDistrict/add')}>Add Court District</button>
      <DownloadButton id="downloadAllCSV" download="CourtDistrict.csv">Download CSV</DownloadButton>
      <span className={'message-box'}>
        <MessageDisplay error={props.courtdist.errorMessage} success={props.courtdist.successMessage} />
      </span>
    </ButtonRow>
    <ListFilter />
    {props.courtdist.isFetching ? <LoadingMessage message={`Fetching Court Districts with ${msTimeout/1000} second timeout`} />
                                : <List />
    }
  </Dashboard>
}

const mapStateToProps = state => ({
  courtdist: state.courtdist,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey,
});

const mapDispatchToProps = {
  startFetchingCourtDists,
  clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourtDistDashboard);