import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, DateField, PageHeader } from './LeaFields';

// Props should be
//  operation - 'add' or 'update'
//  onDelete  - function that takes arrest_agency_code and returns
//              whether the delete was successful.  If this value is
//              undefined, it means delete is not allowed and a
//              'delete' button should not be displayed.
//  onSubmit  - function that runs submit action.
//  clearFailed - function that clears error messages.
//
export default function LeaForm(props) {
  let lea = props.lea || {};
  const [arrest_agency_code,  setArrest_agency_code]  = useState(lea.arrest_agency_code || '');
  const [ncic_identification, setNcic_identification] = useState(lea.ncic_identification || '');
  const [agency_name,         setAgency_name]         = useState(lea.agency_name     || '');
  const [source_system,       setSource_system]       = useState(lea.source_system   || '');
  const [address,             setAddress]             = useState(lea.address         || '');
  const [city,                setCity]                = useState(lea.city            || '');
  const [state,               setState]               = useState(lea.state           || '');
  const [zipcode,             setZipcode]             = useState(lea.zipcode         || '');
  const [telephone,           setTelephone]           = useState(lea.telephone       || '');
  const [pts_service_branch,  setPts_service_branch]  = useState(lea.pts_service_branch || '');
  const [jail_facility,       setJail_facility]       = useState(lea.jail_facility   || '');
  const [agency_type,         setAgency_type]         = useState(lea.agency_type     || '');
  const [printer_id,          setPrinter_id]          = useState(lea.printer_id      || '');
  const [expired_flag,        setExpired_flag]        = useState(lea.expired_flag    || '');
  const [primary_lea,         setPrimary_lea]         = useState(lea.primary_lea     || '');
  const [subpoena_service_type, setSubpoena_service_type] = useState(lea.subpoena_service_type || '');
  const [user_id,             setUser_id]             = useState(lea.user_id         || '');
  const [ori_type,            setOri_type]            = useState(lea.ori_type        || '');
  const [caldoj_compare_date, setCaldoj_compare_date] = useState(lea.caldoj_compare_date || '');
  const [create_date,         setCreate_date]         = useState(lea.create_date     || '');
  const [update_date,         setUpdate_date]         = useState(lea.update_date     || '');
  const [expiration_date,     setExpiration_date]     = useState(lea.expiration_date || '');
  const [effective_start_date, setEffective_start_date] = useState(lea.effective_start_date || '');
  const [ajis_ori_sub_field,  setAjis_ori_sub_field]  = useState(lea.ajis_ori_sub_field || '');
  const [caldoj_ori_onfile,   setCaldoj_ori_onfile]   = useState(lea.caldoj_ori_onfile || '');
  const [aka_agency_name,     setAka_agency_name]     = useState(lea.aka_agency_name || '');
  const [caldoj_agency_name,  setCaldoj_agency_name]  = useState(lea.caldoj_agency_name || '');
  const [fax_number,          setFax_number]          = useState(lea.fax_number      || '');

  const [origLea,     setOrigLea]     = useState(new Map());
  const [diffLea,     setDiffLea]     = useState(new Map());
  const [fieldsValid, setFieldsValid] = useState(true);
  const [validateMsg, setValidateMsg] = useState('');

  useEffect(() => {
    if (origLea.has('arrest_agency_code')) { // origLea already populated
      const changes = new Map();
      if (origLea.get('arrest_agency_code') !== arrest_agency_code) changes.set('arrest_agency_code', arrest_agency_code);
      if (origLea.get('ncic_identification') !== ncic_identification) changes.set('ncic_identification', ncic_identification);
      if (origLea.get('agency_name')   !== agency_name)    changes.set('agency_name',   agency_name);
      if (origLea.get('source_system') !== source_system)  changes.set('source_system', source_system);
      if (origLea.get('address')       !== address)        changes.set('address',       address);
      if (origLea.get('city')          !== city)           changes.set('city',          city);
      if (origLea.get('state')         !== state)          changes.set('state',         state);
      if (origLea.get('zipcode')       !== zipcode)        changes.set('zipcode',       zipcode)
      if (origLea.get('telephone')     !== telephone)      changes.set('telephone',     telephone)
      if (origLea.get('pts_service_branch') !== pts_service_branch) changes.set('pts_service_branch', pts_service_branch);
      if (origLea.get('jail_facility') !== jail_facility)  changes.set('jail_facility', jail_facility);
      if (origLea.get('agency_type')   !== agency_type)    changes.set('agency_type',   agency_type);
      if (origLea.get('printer_id')    !== printer_id)     changes.set('printer_id',    printer_id);
      if (origLea.get('expired_flag')  !== expired_flag)   changes.set('expired_flag',  expired_flag);
      if (origLea.get('primary_lea')   !== primary_lea)    changes.set('primary_lea',   primary_lea);
      if (origLea.get('subpoena_service_type') !== subpoena_service_type) changes.set('subpoena_service_type', subpoena_service_type);
      if (origLea.get('user_id')       !== user_id)        changes.set('user_id',       user_id);
      if (origLea.get('ori_type')      !== ori_type)       changes.set('ori_type',      ori_type);
      if (origLea.get('caldoj_compare_date') !== caldoj_compare_date) changes.set('caldoj_compare_date', caldoj_compare_date);
      if (origLea.get('create_date')   !== create_date)    changes.set('create_date',   create_date);
      if (origLea.get('update_date')   !== update_date)    changes.set('update_date',   update_date);
      if (origLea.get('expiration_date') !== expiration_date) changes.set('expiration_date', expiration_date);
      if (origLea.get('effective_start_date') !== effective_start_date) changes.set('effective_start_date', effective_start_date);
      if (origLea.get('ajis_ori_sub_field') !== ajis_ori_sub_field) changes.set('ajis_ori_sub_field', ajis_ori_sub_field);
      if (origLea.get('caldoj_ori_onfile') !== caldoj_ori_onfile) changes.set('caldoj_ori_onfile', caldoj_ori_onfile);
      if (origLea.get('aka_agency_name') !== aka_agency_name) changes.set('aka_agency_name', aka_agency_name);
      if (origLea.get('caldoj_agency_name') !== caldoj_agency_name) changes.set('caldoj_agency_name', caldoj_agency_name);
      if (origLea.get('fax_number')   !== fax_number)     changes.set('fax_number', fax_number);
      setDiffLea(changes);
    } else { // Need to populate original version of LEA
      const original = new Map();
      original.set('arrest_agency_code',  lea.arrest_agency_code  || '');
      original.set('ncic_identification', lea.ncic_identification || '');
      original.set('agency_name', lea.agency_name || '');
      original.set('source_system', lea.source_system || '');
      original.set('address', lea.address || '');
      original.set('city', lea.city || '');
      original.set('state', lea.state || '');
      original.set('zipcode', lea.zipcode || '');
      original.set('telephone', lea.telephone || '');
      original.set('pts_service_branch', lea.pts_service_branch || '');
      original.set('jail_facility', lea.jail_facility || '');
      original.set('agency_type', lea.agency_type || '');
      original.set('printer_id', lea.printer_id || '');
      original.set('expired_flag', lea.expired_flag || '');
      original.set('primary_lea', lea.primary_lea || '');
      original.set('subpoena_service_type', lea.subpoena_service_type || '');
      original.set('user_id', lea.user_id || '');
      original.set('ori_type', lea.ori_type || '');
      original.set('caldoj_compare_date', lea.caldoj_compare_date || '');
      original.set('create_date', lea.create_date || '');
      original.set('update_date', lea.update_date || '');
      original.set('expiration_date', lea.expiration_date || '');
      original.set('effective_start_date', lea.effective_start_date || '');
      original.set('ajis_ori_sub_field', lea.ajis_ori_sub_field || '');
      original.set('caldoj_ori_onfile', lea.caldoj_ori_onfile || '');
      original.set('aka_agency_name', lea.aka_agency_name || '');
      original.set('caldoj_agency_name', lea.caldoj_agency_name || '');
      original.set('fax_number', lea.fax_number || '');
      setOrigLea(original);
    }
    const invalidElements = document.querySelectorAll('input:invalid');
    setFieldsValid(invalidElements.length === 0);
  }, [arrest_agency_code, ncic_identification, agency_name, source_system,
      address, city, state, zipcode, telephone, pts_service_branch, jail_facility,
      agency_type, printer_id, expired_flag, primary_lea, subpoena_service_type,
      user_id, ori_type, caldoj_compare_date, create_date, update_date,
      expiration_date, effective_start_date, ajis_ori_sub_field, caldoj_ori_onfile,
      aka_agency_name, caldoj_agency_name, fax_number
     ]);
  
  useEffect(() => {
    const submitButtons = document.getElementsByClassName('submit');
    if (submitButtons.length) {
      for (const button of submitButtons) {
        button.disabled = !!props.successMessage
                       || !fieldsValid
                       || (props.authenticated === undefined ? false : !props.authenticated);
      }
    }
    return () => {
      if (props.errorMessage || props.successMessage) {
        props.clearMessages();
      }
    }
  }, [props.errorMessage, props.successMessage, props.authenticated,  fieldsValid]);

  useEffect(() => {
    if (props.leaDup) {
      if (props.leaDup(arrest_agency_code)) {
        console.log('Duplicate found');
        setValidateMsg(`Arrest agency code ${arrest_agency_code} already exists`)
      } else {
        setValidateMsg('');
      }
    } else {
      console.log('No leaDup defined for form.');
    }
  }, [arrest_agency_code])

  function onClear(evt) {
    evt.preventDefault();
    setArrest_agency_code('');
    setNcic_identification('');
    setAgency_name('');
    setSource_system('');
    setAddress('');
    setCity('');
    setState('');
    setZipcode('');
    setTelephone('');
    setPts_service_branch('');
    setJail_facility('');
    setAgency_type('');
    setPrinter_id('');
    setExpired_flag('');
    setPrimary_lea('');
    setSubpoena_service_type('');
    setUser_id('');
    setOri_type('');
    setCaldoj_compare_date('');
    setCreate_date('');
    setUpdate_date('');
    setExpiration_date('');
    setEffective_start_date('');
    setAjis_ori_sub_field('');
    setCaldoj_ori_onfile('');
    setAka_agency_name('');
    setCaldoj_agency_name('');
    setFax_number('');
    props.clearMessages();
  }

  function onSubmit(evt) {
    evt.preventDefault();
    let modifiedLeaValues = { arrest_agency_code };  // Not changed; but used as key.
    if (props.add === true) {
      // In the case of an ADD, these properties must be considered modified.
      modifiedLeaValues = { arrest_agency_code, state, expired_flag, expiration_date,
                            subpoena_service_type, effective_start_date };
    }
    diffLea.forEach((value, field) => modifiedLeaValues[field] = value);
    console.log(`Changed LEA state: ${JSON.stringify(modifiedLeaValues)}`);
    props.onSubmit(modifiedLeaValues);
  }

  return <LeaCodePage>
    <PageHeader title={props.title} 
                authenticated={props.authenticated}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                fieldsValid={fieldsValid}
                validateMessage={validateMsg}
    />
    <ButtonRow>
      <button style={{background: 'orange'}} onClick={onSubmit} className="submit">{props.submitLabel}</button>
      <button style={{background: 'lightskyblue'}} onClick={props.onCancel}>Back to List</button>
      <button style={{background: 'burlywood'}} onClick={onClear}>Clear Fields</button>
      {(props.onDelete !== undefined) ??
        <button style={{background: 'red'}} onClick={props.onDelete}>Delete Record</button>
      }
    </ButtonRow>
    <FieldSectionLayout>
      <FieldSection background="#ded7ff" grid_columns="3em 3em 4em 6em 6em 3em">
        <TextField id="arrest_agency_code"
                       label="Agency Code"
                       maxLength="4"
                       required
                       pattern='(\d|[A-Z]){4}'
                       title="Four digits (required)"
                       value={arrest_agency_code}
                       onChange={e => setArrest_agency_code(e.target.value)} />
        <TextField     id="ncic_identification"
                       label="NCIC ID"
                       maxLength="10"
                       value={ncic_identification}
                       onChange={e => setNcic_identification(e.target.value)} />
        <TextField     id="primary_lea"
                       label="Primary LEA"
                       value={primary_lea}
                       maxLength="4"
                       pattern='\d\d\d\d'
                       title="Four digits"
                       onChange={e => setPrimary_lea(e.target.value)} />
        <TextField     id="address"
                       label="Address"
                       maxLength="37"
                       title="Max length = 37"
                       long_value
                       value={address}
                       onChange={e => setAddress(e.target.value)} />
        <TextField     id="city"
                       label="City"
                       maxLength="20"
                       title="Max length = 20"
                       long_value
                       value={city}
                       onChange={e => setCity(e.target.value)} />
        <TextField     id="state"
                       label="State"
                       mid_left
                       maxLength="2"
                       pattern="[A-Z][A-Z]"
                       value={state}
                       onChange={e => setState(e.target.value)} />
        <TextField     id="zipcode"
                       label="Zip"
                       mid_right
                       maxLength="10"
                       value={zipcode}
                       onChange={e => setZipcode(e.target.value)} />
        <TextField     id="telephone"
                       label="Phone"
                       mid_left
                       maxLength="18"
                       value={telephone}
                       onChange={e => setTelephone(e.target.value)} />
        <TextField     id="fax_number"
                       label="FAX"
                       mid_right
                       maxLength="18"
                       value={fax_number}
                       onChange={e => setFax_number(e.target.value)} />
      </FieldSection>
      <FieldSection background="#ffeed5" grid_columns="4em 1fr 3em 1fr 4em 1fr">
        <TextField id="user_id"
                       label="Last edit by"
                       title="System generated"
                       mid_left
                       readOnly
                       value={user_id} />
        <TextField id="source_system"
                       label="Source System" 
                       title="System generated"
                       mid_right
                       readOnly
                       value={source_system} />
        <DateField id="update_date"
                       label="Updated"
                       mid_left
                       readOnly
                       value={update_date}
                       onChange={e => setUpdate_date(e.target.value)} />
        <DateField id="create_date"
                       label="Created"
                       mid_right
                       readOnly
                       value={create_date} />
        <DateField     id="expiration_date"
                       label="Expired"
                       mid_left
                       value={expiration_date}
                       onChange={e => setExpiration_date(e.target.value)} />
        <DateField     id="effective_start_date"
                       label="Effective Start"
                       mid_right
                       value={effective_start_date}
                       onChange={e => setEffective_start_date(e.target.value)} />
        <DateField     id="caldoj_compare_date"
                       label="Cal-DOJ Compare"
                       mid_left
                       value={caldoj_compare_date}
                       onChange={e => setCaldoj_compare_date(e.target.value)} />
      </FieldSection>
      <FieldSection background="#d4feff" grid_columns="4em auto">
        <TextField id="agency_name"
                  label="Agency Name"
                  maxLength="40"
                  title="Max length = 40"
                  required
                  value={agency_name}
                  onChange={e => setAgency_name(e.target.value)} />
        <TextField id="caldoj_agency_name"
                  label="Cal DOJ Name"
                  maxLength="90"
                  title="Max length = 90"
                  value={caldoj_agency_name}
                  onChange={e => setCaldoj_agency_name(e.target.value)} />
        <TextField id="aka_agency_name"
                  label="AKA Name"
                  maxLength="40"
                  title="Max length = 40"
                  value={aka_agency_name}
                  onChange={e => setAka_agency_name(e.target.value)} />
      </FieldSection>
      <FieldSection background="#e8e8e8" grid_columns="3fr 3em 2fr 3em 2fr 3em">
        <TextField id="caldoj_ori_onfile"
                   label="Cal-DOJ ORI on file"
                   maxLength="1"
                   value={caldoj_ori_onfile}
                   onChange={e => setCaldoj_ori_onfile(e.target.value)} />
        <TextField id="ori_type"
                   label="ORI Type"
                   maxLength="1"
                   value={ori_type}
                   onChange={e => setOri_type(e.target.value)} />
        <TextField id="subpoena_service_type"
                   label="Subpoena Type"
                   title="(P)aper or (E)lectronic or blank"
                   pattern="P|E| "
                   maxLength="1"
                   value={subpoena_service_type}
                   onChange={e => setSubpoena_service_type(e.target.value)} />
        <TextField id="ajis_ori_sub_field"
                   label="AJIS ORI Subfield"
                   maxLength="1"
                   title="Single character"
                   value={ajis_ori_sub_field}
                   onChange={e => setAjis_ori_sub_field(e.target.value)} />
        <TextField id="printer_id"
                   label="Printer ID"
                   maxLength="4"
                   title="4 characters"
                   value={printer_id}
                   onChange={e => setPrinter_id(e.target.value)} />
        <TextField id="agency_type"
                   label="Agency Type"
                   maxLength="3"
                   title="Max length = 3"
                   value={agency_type}
                   onChange={e => setAgency_type(e.target.value)} />
        <TextField id="jail_facility"
                   label="Jail Facility"
                   maxLength="1"
                   title="Single character"
                   value={jail_facility}
                   onChange={e => setJail_facility(e.target.value)} />
        <TextField id="expired_flag"
                   label="Expired Flag"
                   maxLength="1"
                   value={expired_flag}
                   onChange={e => setExpired_flag(e.target.value)} />
        <TextField id="pts_service_branch"
                   label="PTS Branch"
                   maxLength="3"
                   title="Max length = 3"
                   value={pts_service_branch}
                   onChange={e => setPts_service_branch(e.target.value)} />
      </FieldSection>
    </FieldSectionLayout>
  </LeaCodePage>
}

const FieldSectionLayout = styled.section`
  display: grid;
  grid-template-columns: 30em 25em;
  gap: 10px;
`;
FieldSectionLayout.displayName = 'FieldSectionLayout';

const FieldSection = styled.fieldset`
  background: ${props => props.background};
  display: grid;
  grid-template-columns: ${props => props.grid_columns};
  gap: 5px;
  border: solid 2px #b3afaf;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
FieldSection.displayName = 'FieldSection';

const LeaCodePage = styled.div`
  margin-left: 10px;
`;
LeaCodePage.displayName = 'LeaCodePage';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';
