import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import LeaForm from './LeaForm';
import { clearMessages, startAddLea } from './LeaActions';

const today = DateTime.now().toISODate();

const addLea = props => {
  const navigate = useNavigate();

  function onSubmit(newLea) {
    props.startAddLea(newLea, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/lea/dashboard');
  }

  function leaDup(code) {
    return props.leaCodes.includes(code);
  }

  return <LeaForm title="New LEA Record"
                  submitLabel="Add Record"
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  leaDup={leaDup}
                  clearMessages={props.clearMessages}
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  authenticated={!!props.token}
                  user_id={props.user_id}
                  unauthorizedFields={[]}
                  lea={{
                    user_id: props.user_id,
                    source_system: 'AWS',
                    state: 'CA',
                    expired_flag: 'N',
                    subpoena_service_type: 'P',
                    update_date: today,
                    create_date: today,
                    effective_start_date: today,
                    expiration_date: '2099-12-31',
                  }}
                  add={true}
  />
}

const mapStateToProps = state => ({
  leaCodes: state.lea.items.map(item => item.arrest_agency_code),
  errorMessage: state.lea.errorMessage,
  successMessage: state.lea.successMessage,
  token: state.credentials.token,
  user_id: state.credentials.claims.sub,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  clearMessages,
  startAddLea
}

export default connect(mapStateToProps, mapDispatchToProps)(addLea);