import React from 'react';
import { Routes, Route} from 'react-router-dom';
import ChargeConversionDashboard from './ChargeConversionDashboard';

const ChargeConversionRouter = () => (
  <div>
    <Routes>
      <Route path="dashboard" element={<ChargeConversionDashboard />} />
    </Routes>
  </div>
);

export default ChargeConversionRouter;