const defaultInitialState = {
  isFetching: false,
  isUpdating: false,
  errorMessage: '',
  successMessage: '',
  items: [],
  currentRow: null,
  filterCriteria: null
};

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      if (action.conversions) {
        console.log('Charge conversion initialize called with action.conversions.items.length=', action.conversions.items.length);
        state = action.conversions;
      } else {
        console.log('Charge conversion initialize called with no action.conversions.');
      }
      console.log(`Charge conversion initialize called with ${state.items.length} items.`);
      return state;
    
    case 'START_FETCHING':
      return { ...state,
               isFetching: true };
    
    case 'FINISH_FETCHING':
      return { ...state,
               isFetching: false, };
    
    case 'START_MODIFICATION':
      return { ...state,
               isUpdating: true };
    
    case 'FINISH_MODIFICATION':
      return { ...state,
               isUpdating: false,
               successMessage: action.success ? action.message : '',
               errorMessage:   action.success ? '' : action.message };

    case 'CLEAR_FAILED':
      return { ...state,
               errorMessage: '',
               successMessage: '' };

    case 'SET_CONVERSION_ROW':
      return { ...state,
               currentRow: action.row };
    
    case 'SET_CONVERSION_DISPLAY_FILTER_CRITERIA':
      return { ...state,
               filterCriteria: action.filterCriteria };

    default:
      console.log('Charge conversion default reducer run with action.type=', action.type);
      return state;
  }
};