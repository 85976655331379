import fetch from 'cross-fetch';

const chargeUri = '/api/ChargeCode';

const startFetching = () => ({ type: 'START_FETCHING' });
const finishFetching = () => ({ type: 'FINISH_FETCHING' });

const populateFetchResults = (updatedCharges) => ({
  type: 'POPULATE_FETCH_RESULTS',
  charges: updatedCharges
})

export const startFetchingChargeCodes = (apihost, apikey, msTimeout = 20000) => {
  const controller = new AbortController();
  const fetchUrl = apihost + chargeUri;
  const fetchOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-api-key': apikey || '',
    },
    signal: controller.signal
  }
  return async (dispatch) => {
    dispatch(clearFailed());
    dispatch(startFetching());
    const timeout = setTimeout(() => controller.abort(), msTimeout);
    try {
      console.time('FetchChargeCodes');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('FetchChargeCodes');
      console.log(`Charge Code GET status code = ${response.status}.`);
      if (response.ok) {
        const results = await response.json();
        console.log(`Charge Code result size = ${results.length}.`);
        dispatch(populateFetchResults({
          invalidated: false,
          isFetching: false,
          isUpdating: false,
          errorMessage: '',
          items: results,
          unauthorizedFields: []
        }));
      } else {
        let errorMessage;
        if (response.headers.has('content-type')) {
          const ct = response.headers.get('content-type');
          switch (true) {
            case /application\/json/.test(ct):
              console.error('Received JSON response.');
              const errObj = await response.json();
              if (errObj.message) {
                errorMessage = errObj.message;
                console.error('Error message = ', errorMessage);
              } else if (errObj.sqlMessage) {
                errorMessage = `${errObj.code}: ${errObj.sqlMessage}`;
                console.error('SQL message = ', errorMessage);
              } else {
                errorMessage = JSON.stringify(errObj);
                console.error('Error response', errorMessage);
              }
              break;
            case /text\/plain/.test(ct):
              errorMessage = await response.text();
              console.error('Text error response', errorMessage);
              break;
            default:
              console.error(`Status ${response.status} returned with content-type: ${ct}`);
              errorMessage = `API server returned ${response.status} status`;
          }
        } else {
          errorMessage = `Status ${response.status} returned with no content.`;
          console.error(errorMessage);
        }
        dispatch(addFailed(errorMessage));
      }
    } catch(err) {
      let msg = typeof(err.message) === 'string' ? err.message : JSON.stringify(err);
      console.log(`Error retrieving Charge Code records from API: ${msg}`);
      if (msg.includes('Aborted')) {
        msg = `Data fetch aborted for exceeding timeout of ${msTimeout/1000} seconds.  Click Refresh to try again.`;
      }
      console.error(msg);
      dispatch(addFailed(msg));
    } finally {
      clearTimeout(timeout);
      dispatch(finishFetching());
    }
  };
};

const updateCharge = (modifiedCharge, successMessage = 'Success') => ({
  type: 'UPDATE_CHARGE',
  id: modifiedCharge.id,
  update: modifiedCharge,
  successMessage
});

export const startUpdateChargeCode = (modifiedChargeValues, token, apihost, apikey) => {
  console.log(`Sending ChargeCode update request to ${apihost}.`);
  return startAddUpdateCharge(modifiedChargeValues, token, 'PATCH', apihost, apikey, (response, dispatch) => {
    console.log(`Charge code ${modifiedChargeValues.id} updated.`);
    dispatch(updateCharge(modifiedChargeValues, 'Update successful.'));
  });
}

export const startAddChargeCode = (chargeWithoutId, token, apihost, apikey) => {
  console.log(`Sending ChargeCode add request to ${apihost}.`);
  return startAddUpdateCharge(chargeWithoutId, token, 'POST', apihost, apikey, (response, dispatch) => {
    const id = response.insertId;
    console.log(`Charge code added with key ${id}.`);
    const charge = { ...chargeWithoutId, id };
    dispatch(addCharge(charge, `Added with ID = ${id}`));
  });
};

const startAddUpdateCharge = (charge, token, method, apihost, apikey, callback) => {
  const fetchUrl = apihost + chargeUri;
  const fetchOptions = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': apikey || '',
    },
    body: JSON.stringify(charge)
  }
  return async (dispatch) => {
    let result;
    dispatch(startAdding());
    try {
      console.time('AddUpdateChargeCode');
      let response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('AddUpdateChargeCode');
      const reqId = response.headers.get('x-cjisapi-requestid');
      const reqIdMsg = reqId ? `Request ID: ${reqId}` : `No request ID returned`;
      console.log(reqIdMsg);
      if (response.ok) {
        result = await response.json();
        callback(result, dispatch);
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 401:
            errorMessage = "Authentication required";
            break;
          case 403:
            errorResponse = await response.json();
            if (/Unauthorized fields/.test(errorResponse.reason)) {
              dispatch(setUnauthorizedFields(errorResponse.unauthorizedFields));
            }
            errorMessage = errorResponse.reason;
            break;
          case 500:
            errorResponse = await response.json();
            if (errorResponse.sqlMessage) {
              console.log(`API encountered SQL error ${errorResponse.code}: ${errorResponse.sqlMessage}`);
              errorMessage = errorResponse.sqlMessage;
            } else {
              errorMessage = `API encountered an error, ${reqIdMsg}`;
            }
            break;
          default: 
            errorMessage = `Encountered HTTP status ${response.status}`
        }
        console.error(`Error code: ${response.status}, Message: ${errorMessage}`);
        dispatch(addFailed(errorMessage));
      }
    } catch (err) {
      console.error('Fetch API returned error:', err);
      dispatch(addFailed(err.toString()));
    }
  }
}

export const startDeleteChargeCode = (id, token, apihost, apikey) => {
  const deleteUrl = `${apihost}${chargeUri}/${id}`;
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': apikey || '',
    }
  }
  return async (dispatch) => {
    let result;
    dispatch(startAdding());
    const logtag = 'DeleteChargeCode';
    try {
      console.time(logtag);
      let response = await fetch(deleteUrl, fetchOptions);
      console.timeLog(logtag, `Status ${response.status}`);
      const reqId = response.headers['x-cjisapi-requestid'];
      const reqIdMsg = reqId ? `Request ID: ${reqId}` : `No requestId returned`;
      console.timeLog(logtag, reqIdMsg);
      if (response.ok) {
        result = await response.json();
        if (result.affectedRows === 1) {
          console.timeLog(logtag, `Successfully deleted id ${id}`);
          dispatch(deleteCharge(id));
        } else if (result.affectedRows === 0) {
          const message = `Failed to delete; id ${id} not found`;
          console.timeLog(logtag, message);
          dispatch(deleteChargeFailed(message));
        } else {
          const message = `Failed to delete ID ${id}; reason unknown`;
          console.timeLog(logtag, message);
          dispatch(deleteChargeFailed(message));
        }
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 401:
            errorMessage = 'Authenitcation required';
            break;
          case 403:
            errorMessage = `User not authorized to delete.`;
            break;
          case 500:
            errorResponse = await response.json();
            if (errorResponse.sqlMessage) {
              console.timeLog(logtag, `API encountered SQL error ${errorResponse.code}: ${errorResponse.sqlMessage}`);
              errorMessage = errorResponse.sqlMessage;
            } else {
              errorMessage = `API encountered an error for request ID ${reqIdMsg}`;
            }
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        console.timeLog(logtag, `Error code: ${response.satus}, Message: ${errorMessage}`);
        dispatch(deleteChargeFailed(id, errorMessage));
      }
    } catch (err) {
      console.logTime(logtag, `API transmission error: ${err.message}`);
      dispatch(deleteChargeFailed(id, err.message));
    } finally {
      console.timeEnd(logtag);
    }
  }
};

const startAdding = () => ({
  type: 'START_ADDING'
})

const addCharge = (charge, successMessage = 'Success') => ({
  type: 'ADD_CHARGE',
  charge,
  successMessage
});

const addFailed = error => ({
  type: 'ADD_FAILED',
  message: error
});

const deleteCharge = (id, message = 'Success') => ({
  type: 'DELETE_CHARGE',
  id,
  message
})

const deleteChargeFailed = error => ({
  type: 'DELETE_CHARGE_FAILED',
  message: error
});

export const clearFailed = () => ({
  type: 'CLEAR_FAILED'
});

const setUnauthorizedFields = unauthorizedFields => ({
  type: 'UNAUTHORIZED_FIELDS',
  unauthorizedFields
})

export const setFilterCriteria = criteria => ({
  type: 'SET_CHARGECODE_DISPLAY_FILTER_CRITERIA',
  criteria
});

export const setFilteredItems = items => ({
  type: 'SET_FILTERED_CHARGECODES',
  filteredItems: items
});