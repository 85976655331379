import React from 'react';
import styled from 'styled-components';
import { Name, VarValue, MonoValue } from '../components/DisplayFields';

const CourtDeptDetailHover = styled.div`
  grid-template-columns: 8em 16em;
`;

export default (props) => {
  return <CourtDeptDetailHover>
    <Name>City</Name>
    <VarValue>{props.city}</VarValue>

    <Name>Zip Code</Name>
    <VarValue>{props.zip}</VarValue>

    <Name>Probation Service Office</Name>
    <VarValue>{props.prob_svc_office}</VarValue>

    <Name>Last Updated</Name>
    <MonoValue>{props.last_updated}</MonoValue>
  </CourtDeptDetailHover>
}