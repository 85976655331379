import fetch from 'cross-fetch';

const courtDeptUri = '/api/CourtDepartment';
const standardHeaders = {
  'Accept': 'application/json'
}

const  startFetching = () => ({ type:  'START_FETCHING'});
const finishFetching = () => ({ type: 'FINISH_FETCHING'});
const updateFetchResults = (items, errorMessage, successMessage) => ({
  type: 'UPDATE_COURT_DEPT_FETCH_RESULTS',
  items,
  errorMessage,
  successMessage
});

export const startFetchingCourtDepts = (apihost, apikey, msTimeout = 10000) => {
  const controller = new AbortController();
  const fetchUrl = apihost + courtDeptUri;
  const fetchOptions = { method: 'GET', signal: controller.signal }
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    const timeout = setTimeout(() => controller.abort(), msTimeout);
    try {
      console.time('fetchCourtDept');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('fetchCourtDept');
      console.log('Court Dept GET status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        const msg = `Court Dept result size = ${results.length}`;
        dispatch(updateFetchResults(results, '', msg));
        console.log(msg);
      } else {
        let errorMessage = `Failed to fetch Court Dept with status ${response.status}`;
        dispatch(setErrorMessage(errorMessage));
        console.error(errorMessage);
      }
    } catch(err) {
      const msg = `Error retrieving Court Dept records from API: ${err.message}`;
      console.error(msg);
      dispatch(setErrorMessage(msg));
    } finally {
      clearTimeout(timeout);
      dispatch(finishFetching());
    }
  }
}

const addCourtDept = (newDept, successMessage = 'Success') => ({
  type: 'ADD_COURT_DEPT',
  newDept,
  successMessage
});

export const startAddCourtDept = (dept, token, apihost, apikey) => {
  return startAddUpdateCourtDept(dept, token, 'POST', apihost, apikey, (jsonBody, dispatch) => {
    console.log(`Court department ${dept.department} added`);
    dispatch(addCourtDept(dept, `Successfully added Court Dept ${dept.department}`));
  });
}

const updateCourtDept = (modifiedCourtDept, successMessage = 'Success') => ({
  type: 'UPDATE_COURT_DEPT',
  update: modifiedCourtDept,
  successMessage
});

export const startUpdateCourtDept = (modifiedValues, token, apihost, apikey) => {
  return startAddUpdateCourtDept(modifiedValues, token, 'PATCH', apihost, apikey, (jsonBody, dispatch) => {
    const msg = `Court Dept ${modifiedValues.department} updated.`;
    console.log(`Court Dept update returned ${JSON.stringify(jsonBody)}`);
    console.log(msg);
    dispatch(updateCourtDept(modifiedValues, msg));
  });
}

export const clearMessages = () => ({ type: 'CLEAR_MESSAGES' });

export const setErrorMessage = (errorMessage) => ({
  type: 'SET_ERROR_MESSAGE',
  errorMessage
});

const startAddUpdateCourtDept = (courtDeptValues, token, method, apihost, apikey, callback) => {
  // Some fields are not allowed to be zero-length strings.
  // Replace such occurrences with null values.
  //
  if (courtDeptValues?.phone === '') {
    courtDeptValues.phone = null;
  }
  if (courtDeptValues?.exp_date === '') {
    courtDeptValues.exp_date = null;
  }
  const fetchUrl = apihost + courtDeptUri;
  const fetchOptions = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': apikey || '',
    },
    body: JSON.stringify(courtDeptValues)
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    try {
      console.time('AddUpdateCourtDept');
      let response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('AddUpdateCourtDept');
      const reqId = response.headers.get('x-cjisapi-requestid');
      const reqIdMsg = reqId ? `Request ID: ${reqId}` : `No request ID returned`;
      console.log(reqIdMsg);
      if (response.ok) {
        const result = await response.json();
        callback(result, dispatch);
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 400:
            errorResponse = await response.json();
            errorMessage = errorResponse.message ?? 'Bad Request';
            break;
          case 401:
            errorMessage = 'Authentication required';
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.reason;
            if (errorResponse.unauthorizedFields !== undefined) {
              errorMessage = `${errorResponse.reason}, ${errorResponse.unauthorizedFields.join(',')}`;
            }
            break;
          case 500:
            errorResponse = await response.json();
            if (errorResponse.sqlMessage) {
              console.log(`API encountered SQL error ${errorResponse.code}: ${errorResponse.sqlMessage}`);
              errorMessage = errorResponse.sqlMessage;
            } else {
              errorMessage = `API encountered an error, ${reqIdMsg}`;
            }
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        if (reqId) {
          errorMessage = `${errorMessage}, Request ID = ${reqId}`;
        }
        console.error(`Error code: ${response.status}, Message: ${errorMessage}`);
        dispatch(setErrorMessage(errorMessage));
      }
    } catch (err) {
      console.error('Fetch API returned error:', err);
      dispatch(setErrorMessage(err.toString()));
    } finally {
      dispatch(finishFetching());
    }
  }
}

export const setFilterCriteria = filterCriteria => ({
  type: 'SET_COURT_DEPT_DISPLAY_FILTER_CRITERIA',
  filterCriteria
});

export const setFilteredItems = items => ({
  type: 'SET_FILTERED_COURT_DEPT',
  filteredItems: items
})