import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, SelectField, DateField,
         PageHeader } from '../components/FormFields';

export default function CourtDistrictForm(props) {
  let dist = props.courtdist || {};
  const [court_type,      setCourt_type]   = useState(dist.court_type || '');
  const [judicial_district, setJudicial_district] = useState(dist.judicial_district || '');
  const [description,     setDescription] = useState(dist.description || '');
  const [city,            setCity]        = useState(dist.city || '');
  const [state_code,      setState_code]  = useState(dist.state_code || '');
  const [state_district_code, setState_district_code] = useState(dist.state_district_code || '');
  const [judicial_district_name, setJudicial_district_name] = useState(dist.judicial_district_name || '');
  const [exp_date,        setExp_date]   = useState(dist.exp_date || '');
  const [prob_svc_office, setProb_svc_office] = useState(dist.prob_svc_office || '');
  const [case_number_prefix, setCase_number_prefix] = useState(dist.case_number_prefix || '');

  const [origDist,    setOrigDist]    = useState(new Map());
  const [diffDist,    setDiffDist]    = useState(new Map());
  const [fieldsValid, setFieldsValid] = useState(true);

  useEffect(() => {
    if (origDist.has('court_type')) {  // origDist already populated
      const changes = new Map();
      if (origDist.get('court_type') !== court_type) changes.set('court_type', court_type);
      if (origDist.get('judicial_district') !== judicial_district) changes.set('judicial_district', judicial_district);
      if (origDist.get('description') !== description) changes.set('description', description);
      if (origDist.get('city')        !== city)     changes.set('city', city);
      if (origDist.get('state_code')  !== state_code) changes.set('state_code', state_code);
      if (origDist.get('state_district_code') !== state_district_code) changes.set('state_district_code', state_district_code);
      if (origDist.get('judicial_district_name') !== judicial_district_name) changes.set('judicial_district_name', judicial_district_name);
      if (origDist.get('exp_date')    !== exp_date) changes.set('exp_date', exp_date);
      if (origDist.get('prob_svc_office') !== prob_svc_office) changes.set('prob_svc_office', prob_svc_office);
      if (origDist.get('case_number_prefix') !== case_number_prefix) changes.set('case_number_prefix', case_number_prefix);
      setDiffDist(changes);
    } else {
      const original = new Map();
      original.set('court_type',  dist.court_type    || '');
      original.set('judicial_district', dist.judicial_district || '');
      original.set('description', dist.description   || '');
      original.set('city',        dist.city          || '');
      original.set('state_code',  dist.state_code    || '');
      original.set('state_district_code', dist.state_district_code || '');
      original.set('judicial_district_name', dist.judicial_district_name || '');
      original.set('exp_date',    dist.exp_date      || '');
      original.set('prob_svc_office', dist.prob_svc_office || '');
      original.set('case_number_prefix', dist.case_number_prefix || '');
      setOrigDist(original);
    }
    const invalidElements = document.querySelectorAll('input:invalid');
    setFieldsValid(invalidElements.length === 0);
  }, 
  [ court_type, judicial_district, description, city, state_code, state_district_code,
    judicial_district_name, exp_date, prob_svc_office, case_number_prefix
  ]);

  useEffect(() => {
    const submitButtons = document.getElementsByClassName('submit');
    if (submitButtons.length) {
      for (const button of submitButtons) {
        button.disabled = !!props.successMessage
                        || !fieldsValid
                        || (props.authenticated === undefined ? false : !props.authenticated);
      }
    }
    return () => {
        if (props.errorMessage || props.successMessage) {
          props.clearMessages();
        }
      }
    },
    [props.errorMessage, props.successMessage, props.authenticated, fieldsValid]);
    
  useEffect(() => {
    const courtTypeSelect = document.querySelector('select#court_type');
    if (courtTypeSelect === undefined) {
      console.error('Failed to locate select#court_type element; cannot validates it\'s value');
    } else {
      if (court_type === '') {
        console.log('Court Type has NOT been set');
        courtTypeSelect.classList.add('invalid');
      } else {
        console.log('Court Type has been set');
        courtTypeSelect.classList.remove('invalid');
      }
    }
  }, [court_type]);

  function onClear(evt) {
    evt.preventDefault();
    setCourt_type('');
    setJudicial_district('');
    setDescription('');
    setCity('');
    setState_code('');
    setState_district_code('');
    setJudicial_district_name('');
    setExp_date('');
    setProb_svc_office('');
    setCase_number_prefix('');
    props.clearMessages();
  }

  function onSubmit(evt) {
    evt.preventDefault();
    const modifiedFields = { court_type, judicial_district }; // Not changed; but used as key
    diffDist.forEach((value, field) => modifiedFields[field] = value);
    console.log(`Changed dist state: ${JSON.stringify(modifiedFields)}`);
    props.onSubmit(modifiedFields);
  }

  return <CourtDistPage>
    <PageHeader title={props.title}
                authenticated={props.authenticated}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                fieldsValid={fieldsValid}
    />
    <ButtonRow>
      <button style={{background: 'orange'}} onClick={onSubmit} className="submit">
        {props.submitLabel}
      </button>
      <button style={{background: 'lightskyblue'}} onClick={props.onCancel}>
        Back to List
      </button>
      <button style={{background: 'burlywood'}} onClick={onClear}>
        Clear Fields
      </button>
      <span style={{'fontStyle': 'italic'}}>Last Updated:</span>
      <span>{dist.last_updated || 'New'}</span>
    </ButtonRow>
    <FieldSectionLayout>
      <TextField id="judicial_district"
                 label="Judicial District"
                 maxLength="3"
                 required
                 value={judicial_district}
                 onChange={e => setJudicial_district(e.target.value)} />
      <SelectField id="court_type"
                   label="Court Type"
                   value={court_type}
                   onChange={e => setCourt_type(e.target.value)} >
        <option value="">-- Choose --</option>
        <option value="M">M – Municipal</option>
        <option value="S">S – Superior</option>
        <option value="X">X – Juvenile</option>
        <option value="J">J – Justice</option>
        <option value="P">P – Parole</option>
      </SelectField>
      <TextField id="state_code"
                 label="State Code"
                 maxLength="5"
                 value={state_code}
                 onChange={e => setState_code(e.target.value)} />
      <TextField id="description"
                 label="Description"
                 required
                 maxLength="30"
                 value={description}
                 onChange={e => setDescription(e.target.value)} />
      <TextField id="state_district_code"
                 label="State District Code"
                 maxLength="6"
                 value={state_district_code}
                 onChange={e => setState_district_code(e.target.value)} />
      <TextField id="city"
                 label="City"
                 maxLength="20"
                 value={city}
                 onChange={e => setCity(e.target.value)} />
      <TextField id="prob_svc_office"
                 label="Probation Service Office"
                 maxLength="6"
                 value={prob_svc_office}
                 onChange={e => setProb_svc_office(e.target.value)} />           
      <TextField id="judicial_district_name"
                 label="Judicial District Name"
                 maxLength="30"
                 value={judicial_district_name}
                 onChange={e => setJudicial_district_name(e.target.value)} />
      <TextField id="case_number_prefix"
                 label="Case Number Prefix"
                 maxLength="1"
                 value={case_number_prefix}
                 onChange={e => setCase_number_prefix(e.target.value)} />
      <DateField id="exp_date"
                 label="Expire Date"
                 value={exp_date}
                 onChange={e => setExp_date(e.target.value)} />
    </FieldSectionLayout>
  </CourtDistPage>;
}

// Styles used for layout.
//
const FieldSectionLayout = styled.section`
  display: grid;
  grid-template-columns: 12em 4em 10em 15em;
  row-gap: 10px;
`;
FieldSectionLayout.displayName = 'FieldSectionLayout';

const CourtDistPage = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 900px;
`;
CourtDistPage.display = 'CourtDistPage';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';