import React from 'react';

// Accepted props are
//   error - displayed as an error message.
//   success - displayed as a success message.
//   info - displayed as an information message.
//   none - do not display a message.
//
export default (props) => {
  let msgClass = 'text--none';
  let msg = '';
  if (props.error  &&  props.error.length > 0) {
    msg = props.error;
    msgClass = 'text--error';
  } else if (props.success  &&  props.success.length > 0) {
    msg = props.success;
    msgClass = 'text--success';
  } else if (props.info  &&  props.info.length > 0) {
    msg = props.info;
    msgClass = 'text--info';
  }

  return (
    <span className={msgClass}>{msg}</span>
  )
}