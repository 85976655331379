import React from 'react';
import { Routes, Route} from 'react-router-dom';
import LeaDashboard from './LeaDashboard';
import AddLea    from './AddLea';
import UpdateLea from './UpdateLea';

const LeaRouter = () => <div>
  <Routes>
    <Route path={'/dashboard'}  element={<LeaDashboard />} />
    <Route path={'/detail/:id'} element={<UpdateLea />} />
    <Route path={'/add'}        element={<AddLea />} />
  </Routes>
</div>;

export default LeaRouter;