import React from 'react';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import styled from 'styled-components';

const ts = (t) => DateTime.fromSeconds(t).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

const CredentialList = styled.fieldset`
`
CredentialList.displayName = 'CredentialList';

const PropertyList = styled.div`
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: auto auto;

  font-family: garamond, serif;

  & .propName {
    text-align: right;
    font-weight: bold;
  }

  & .propValue {
    text-align: left;
    letter-spacing: 1px;
  }
`
PropertyList.displayName = 'PropertyList';

const NoCredsMsg = styled.p`
  font-family: garamond, serif;
  font-size: larger;
  letter-spacing: 1px;
  color: red;
`;
NoCredsMsg.displayName = 'NoCredsMsg';

export const credentialList = props => {
  return (
    <div>
      {
        props.token ? (
          <CredentialList>
            <legend>Credentials</legend>
            <PropertyList>
              <div className="propName">ID:</div>
              <div className="propValue">{props.claims.sub}</div>

              <div className="propName">Scopes:</div>
              <div className="propValue">{props.claims.scope ? props.claims.scope.join() : ''}</div>

              <div className="propName">Issued At:</div>
              <div className="propValue">{props.claims.iat ? ts(props.claims.iat) : ''}</div>

              <div className="propName">Expires At:</div>
              <div className="propValue">{props.claims.exp ? ts(props.claims.exp) : ''}</div>

              <div className="propName">Token Issuer:</div>
              <div className="propValue">{props.claims.iss ? (props.claims.iss) : ''}</div>
            </PropertyList>
          </CredentialList>
        ) : (
          <NoCredsMsg>No Credentials; read-only access</NoCredsMsg>
        )
      }
    </div>
  );
}

const mapStateToProps = state => ({ 
  token: state.credentials.token,
  claims: state.credentials.claims
})

export default connect(mapStateToProps)(credentialList);