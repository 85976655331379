import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import CredentialDashboard    from '../Login/CredentialDashboard';
import ProfileRouter          from '../UserAdmin/ProfileRouter';
import ChargeCodeRouter       from '../ChargeCode/ChargeCodeRouter';
import ChargeConversionRouter from '../ChargeConversion/ChargeConversionRouter';
import CourtDeptRouter        from '../CourtDepartment/CourtDeptRouter';
import CourtDistRouter        from '../CourtDistrict/CourtDistRouter';
import LeaRouter              from '../LEA/LeaRouter';
import LandingDashboard       from '../LandingPage/LandingDashboard';
import VersionDashboard       from '../Version/VersionDashboard';
import { populateApiEndpoints   } from '../HostSelector/ApiServerActions';
import { startFetchingChargeCodes }    from '../ChargeCode/ChargeCodeActions';

const CJISRouter = (props) => {

  const executeThisOnce = true;

  useEffect(() => {
    console.log(`CJISRouter fetching API endpoints. Current host = ${window.location.hostname}`)
    props.populateApiEndpoints();
  }, [executeThisOnce])

  useEffect(() => {
    console.log('CJISRouter useEffect with apiHost', props.apiHost);
    if (props.apiHost === 'http://localhost:6010') {
      console.log('Skipping pre-fetch for localhost');
    } else {
      if (!props.isFetchingCharges) {
        console.log('Pre-emptively fetching charge codes while on landing page.');
        props.startFetchingChargeCodes(props.apiHost, props.apiKey, 8000);
      } else {
        console.log('Charge code fetch is already in progress.');
      }
    }
  }, [props.apiHost]);

  return <BrowserRouter>
    <div>
      <Header/>
      <Routes>
        <Route path="/"                   element={<LandingDashboard />}       /> 
        <Route path="/Credentials"        element={<CredentialDashboard />}    />
        <Route path="/Profiles/*"         element={<ProfileRouter />}          />
        <Route path="/ChargeCode/*"       element={<ChargeCodeRouter />}       />
        <Route path="/ChargeConversion/*" element={<ChargeConversionRouter />} />
        <Route path="/lea/*"              element={<LeaRouter />}              />
        <Route path="/CourtDepartment/*"  element={<CourtDeptRouter />}        />
        <Route path="/CourtDistrict/*"    element={<CourtDistRouter />}        />
        <Route path="/version"            element={<VersionDashboard />}       />
      </Routes>
    </div>
  </BrowserRouter>
}

const mapStateToProps = state => ({
  isFetchingCharges: state.charges.isFetching,
  apiHost: state.apiserver.urlbase,
  apiKey:  state.apiserver.apikey,
});
const mapDispatchToProps = { 
                             populateApiEndpoints,
                             startFetchingChargeCodes
                           };

export default connect(mapStateToProps, mapDispatchToProps)(CJISRouter);