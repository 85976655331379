import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ChargeCodeDetail from './ChargeCodeDetailHover';
import CellHover from '../components/CellHover';

const ChargeCodeRow = styled.tr`
  td {
    padding: 0px 5px;
    border: 1px solid #ddd;
  }
  &.felony {
    background-color: rgba(255,   0, 0, 0.2);
  }
  &.misdemeanor {
    background-color: rgba(255, 165, 0, 0.2);
  }
  &.infraction {
    background-color: rgba(255, 255, 0, 0.2);
  }
  &:hover {
    background-color: #ddd;
  }
`;
ChargeCodeRow.displayName = 'ChargeCodeRow';

export default function (props) {
  const navigate = useNavigate();

  function  handleDblClick() {
    navigate(`/ChargeCode/update/${props.id}`);
  }

  function rowClassNames() {
    let className = '';
    switch (props.offense_level && props.offense_level.toUpperCase()) {
        case 'F': className += " felony";       break;
        case 'M': className += " misdemeanor";  break;
        case 'I': className += " infraction";   break;
        default: 
    }
    return className
  }

  return <ChargeCodeRow className={rowClassNames()}
    onDoubleClick={handleDblClick}>
    <td>{props.code}</td>
    <CellHover style={{fontFamily: 'consolas'}}>{props.statute}
      <ChargeCodeDetail {...props} />
    </CellHover>
    <td>{props.literal_id}</td>
    <td>{props.degree}</td>
    <td>{props.offense_level}</td>
    <td>{props.exp_date}</td>
    <td style={{width: '25em'}}>{props.full_description}</td>
    <td style={{ textAlign: 'right'}}>{props.template_id}</td>
    <td>{props.last_updated}</td>
  </ChargeCodeRow>
}