// This is meant to supplement a table cell by providing
// a container for "hover content".  The content will only
// be displayed when the user hovers over the cell.

import styled from 'styled-components';

const CellHover = styled.td`
  position: relative;

  & div {
    display: none;
  }

  &:hover div {
    display: grid;
    position: absolute;
    background-color: #ddd;
    box-shadow: inset 0 0 20px 0 #aaa;
    border-radius: 10px;
    padding: 10px;
    left: 10em;
    top: 2em;
    z-index: 20;
  }
`;
CellHover.displayName = 'CellHover';

export default CellHover;