import fetch from 'cross-fetch';

const chargeUri = '/api/ChargeConversion';
const standardHeaders = {
  'Accept': 'application/json'
}

const initialize = (initialConversions) => ({
  type: 'INITIALIZE',
  conversions: initialConversions
});

const  startFetching = () => ({ type:  'START_FETCHING'});
const finishFetching = () => ({ type: 'FINISH_FETCHING'});

export const startFetchingConversions = (apihost, apikey) => {
  const fetchUrl = apihost + chargeUri;
  const fetchOptions = { method: 'GET' };
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return async (dispatch) => {
    dispatch(startFetching());
    try {
      console.time('fetchChargeConversions');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('fetchChargeConversions');
      console.log('Charge Conversion GET status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        console.log('Charge Conversion result size = ', results.length);
        dispatch(finishFetching());
        dispatch(initialize({
          invalidated: false,
          isFetching: false,
          isUpdating: false,
          errorMessage: '',
          items: results,
          currentRow: null,
          filterCriteria: null
        }));
      } else {
        dispatch(finishFetching());
        let errorMessage, errorResponse;
        switch (response.status) {
          case 401:
            errorMessage = "Authentication required";
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.reason;
            break;
          default:
            errorMessage `Encountered HTTTP status ${response.status}`;
        }
        dispatch(initialize({
          invalidated: true,
          isFetching: false,
          isUpdating: false,
          errorMessage,
          items: [],
          currentRow: null,
          filterCriteria: null
        }));
        return [];
      }
    } catch(err) {
      console.log('Fetch connection failure ', err);
      dispatch(finishFetching());
      dispatch(initialize({
        invalidated: true,
        isFetching: false,
        isUpdating: false,
        errorMessage: JSON.stringify(err),
        items: [],
        currentRow: null,
        filterCriteria: null
      }));
    }
  };
};

const startModification  = () => ({ type: 'START_MODIFICATION' });
const finishModification = (success = true, message = '') => ({ 
  type: 'FINISH_MODIFICATION',
  success,
  message
});

export const startAddConversion = (apihost, apikey, token, source, cjis, external) => {
  const fetchUrl = apihost + chargeUri;
  const fetchOptions = { method: 'POST' };
  fetchOptions['headers'] = { ...standardHeaders };
  fetchOptions.headers['Authorization'] = `Bearer ${token}`;
  fetchOptions.headers['x-api-key'] = apikey || '';
  fetchOptions.headers['Content-Type'] = 'application/json';
  fetchOptions['body'] = JSON.stringify({source, cjis, external});
  return async (dispatch) => {
    dispatch(startModification());
    try {
      console.time('AddConversion');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('AddConversion');
      console.log('Charge Conversion POST status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        console.log('Charge Converserion POST response:', JSON.stringify(results));
        if (response.code) {
          dispatch(finishModification(false, results.sqlMessage));
        } else {
          dispatch(finishModification(true, 'Charge conversion added; click Reload to view.'));
        }
      } else {
        let errorMessage, errorResponse;
        switch (response.status) {
          case 401:
            errorMessage = 'Authentication required';
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.reason;
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        dispatch(finishModification(false, errorMessage));
      }
    } catch(err) {
      const errMsg = err.message === 'undefined' ? JSON.stringify(err) : err.message;
      console.log('Charge Conversion POST exception:', errMsg);
      dispatch(finishModification(false, errMsg));
    }
  }
}

export const startDeleteConversion = (apihost, apikey, token, currentRow) => {
  const fetchUrl = apihost + chargeUri;
  const fetchOptions = { method: 'DELETE' };
  fetchOptions['headers'] = { ...standardHeaders };
  fetchOptions['headers']['Authorization'] = `Bearer ${token}`;
  fetchOptions['headers']['x-api-key'] = apikey || '';
  fetchOptions['headers']['Content-Type'] = 'application/json';
  fetchOptions['body'] = JSON.stringify(currentRow);
  return async (dispatch) => {
    dispatch(startModification());
    try {
      const response = await fetch(fetchUrl, fetchOptions);
      console.log('Charge Conversion DELETE status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        console.log('Charge Conversion DELETE response:', JSON.stringify(results));
        if (results.code) {
          dispatch(finishModification(false, results.sqlMessage));
        } else {
          if (results.affectedRows !== undefined) {
            const msg = `Items deleted: ${results.affectedRows}, click Refresh to update conversions.`;
            dispatch(finishModification(true, msg));
          } else {
            dispatch(finishModification(true));
          }
        }
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 401:
            errorMessage = "Authentication required";
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.message;
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        dispatch(finishModification(false, errorMessage));
      }
    } catch (err) {
      const errMsg = err.message === 'undefined' ? JSON.stringify(err) : err.message;
      console.log('Charge Conversion DELETE exception:', errMsg);
      dispatch(finishModification(false, errMsg));
    }
  }
}

export const clearFailed = () => ({
  type: 'CLEAR_FAILED'
});

export const setCurrentRow = row => ({
  type: 'SET_CONVERSION_ROW',
  row
});

export const setFilterCriteria = filterCriteria => ({
  type: 'SET_CONVERSION_DISPLAY_FILTER_CRITERIA',
  filterCriteria
});