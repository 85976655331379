import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import CellHover from '../components/CellHover';
import CourtDistDetailHover from './CourtDistDetailHover';

const DistRow = styled.tr`
  &:nth-child(odd) {
    background: #d6a979;
  }
  &:nth-child(even) {
    background: #ffdda9;
  }
  &:hover {
    background: #ddd;
  }
`;
DistRow.displayName = 'DistRow';

const MonoField = styled.td`
  font-family: consolas, courier, mono;
  padding: 0 10px 0 5px;
`;
MonoField.displayName = 'MonoField';

const VarField = styled.td`
  font-family: georgia, "Times New Roman", serif;
  padding: 0 5px;
`;
VarField.displayName = 'VarField';

const courtDistListRow = props => {

  const navigate = useNavigate();

  function handleDoubleClick() {
    navigate(`/CourtDistrict/detail/${props.judicial_district}/${props.court_type}`);
  }

  function datetimeToDate(isoDT) {
    const dt = DateTime.fromISO(isoDT);
    return dt.isValid ? dt.toISODate() : '';
  }

  return (
    <DistRow onDoubleClick={handleDoubleClick}>
      <MonoField style={{textAlign: 'center'}}>{props.court_type}</MonoField>
      <MonoField>{props.judicial_district}</MonoField>
      <CellHover style={{fontFamily: 'georgia, serif', padding: '0 5px'}}>
        {props.description}
        <CourtDistDetailHover {...props} />
      </CellHover>
      <VarField>{props.city}</VarField>
      <MonoField>{props.case_number_prefix}</MonoField>
      <MonoField>{datetimeToDate(props.exp_date)}</MonoField>
    </DistRow>
  )
}

export default courtDistListRow;