import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from 'styled-components';
import ProfileTable from './ProfileTable';
import { startFetchingProfiles, addFailed } from './ProfileAction';

const Dashboard = styles.div`
  margin-left: 10px;
`

const linkStyle = {
  background: 'linear-gradient(#a8ceff, #d5e7ff, #a8ceff)',
  padding: '5px',
  color: 'black',
  display: 'inline-block',
  textDecoration: 'none',
  border: 'solid grey 1px',
  marginBottom: '15px',
}


const profileDashboard = (props) => { 

  useEffect(() => {
    if (props.token) {
      console.log(`Fetching profiles from ${props.authhost}.`);
      props.startFetchingProfiles(props.authhost, props.token, props.apikey);
    } else {
      props.addFailed('Missing credentials; go to Login.');
    }
  }, [props.token, props.authhost]);

  return <Dashboard>
    <h2>Profile Dashboard</h2>
    {props.profiles.isFetching ? 'Fetching profiles ...'
                               : <div>
        {props.profiles.errorMessage ? `Error: ${props.profiles.errorMessage}`
                                     : <div>
            <Link style={linkStyle} to="/Profiles/create">Create Profile</Link>
            <ProfileTable />
          </div>
        }
      </div>
    }
  </Dashboard>
}

const mapStateToProps = state => ({
  profiles: state.profiles,
  authhost: state.authserver.urlbase,
  apikey:   state.authserver.apikey,
  token:    state.credentials.token
});

const mapDispatchToProps = { startFetchingProfiles, addFailed };

export default connect(mapStateToProps, mapDispatchToProps)(profileDashboard);
