import fetch from 'cross-fetch';

const courtDistUri = '/api/CourtDistrict';
const standardHeaders = {
  'Accept': 'application/json',
}

const  startFetching = () => ({ type:  'START_FETCHING' });
const finishFetching = () => ({ type: 'FINISH_FETCHING' });
const updateFetchResults = (items, errorMessage, successMessage) => ({
  type: 'UPDATE_COURT_DIST_FETCH_RESULTS',
  items,
  errorMessage,
  successMessage
});

export const startFetchingCourtDists = (apihost, apikey, msTimeout = 10000) => {
  const controller = new AbortController();
  const fetchUrl = apihost + courtDistUri;
  const fetchOptions = { method: 'GET', signal: controller.signal };
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    const timeout = setTimeout(() => controller.abort(), msTimeout);
    try {
      console.time('fetchCourtDist');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('fetchCourtDist');
      console.log('Court Dist GET status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        const msg = `Court Dist result size = ${results.length}`;
        dispatch(updateFetchResults(results, '', msg));
        console.log(msg);
      } else {
        let errorMessage = `Failed to fetch Court Dist with status ${response.status}`;
        dispatch(setErrorMessage(errorMessage));
        console.error(errorMessage);
      }
    } catch(err) {
      const msg = `Error retrieving Court Dist records from API: ${err.message}`;
      console.error(msg);
      dispatch(setErrorMessage(msg));
    } finally {
      clearTimeout(timeout);
      dispatch(finishFetching());
    }
  }
}

const addCourtDist = (newDist, successMessage = 'Success') => ({
  type: 'ADD_COURT_DIST',
  newDist,
  successMessage
});

export const startAddCourtDist = (district, token, apihost, apikey) => {
  return startAddUpdateCourtDist(district, token, 'POST', apihost, apikey, (jsonBody, dispatch) => {
    console.log(`Court district ${district.judicial_district} added`);
    dispatch(addCourtDist(district, `Successfully added Court Dist ${district.judicial_district}`));
  });
}

const updateCourtDist = (modifiedCourtDist, successMessage = 'Success') => ({
  type: 'UPDATE_COURT_DIST',
  update: modifiedCourtDist,
  successMessage
});

export const startUpdateCourtDist = (modifiedValues, token, apihost, apikey) => {
  return startAddUpdateCourtDist(modifiedValues, token, 'PATCH', apihost, apikey, (jsonBody, dispatch) => {
    console.log(`Court district ${modifiedValues.judicial_district} updated.`);
    console.log(`Court district update returned ${JSON.stringify(jsonBody)}`);
    dispatch(updateCourtDist(modifiedValues, 'Update successful'));
  });
}

export const clearMessages = () => ({ type: 'CLEAR_MESSAGES' });

export const setErrorMessage = (errorMessage) => ({
  type: 'SET_ERROR_MESSAGE',
  errorMessage
});

const startAddUpdateCourtDist = (courtDistValues, token, method, apihost, apikey, callback) => {
  // Some fields are not allowed to be zero-length strings.
  // Replace such occurrences with null values.
  //
  if (courtDistValues?.exp_date === '') {
    courtDistValues.exp_date = null;
  }
  const fetchUrl = apihost + courtDistUri;
  const fetchOptions = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': apikey || '',
    },
    body: JSON.stringify(courtDistValues)
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    try {
      console.time('AddUpdateCourtDist');
      let response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('AddUpdateCourtDist');
      const reqId = response.headers.get('x-cjisapi-requestid');
      const reqIdMsg = reqId ? `Request ID: ${reqId}` : `No request ID returned`;
      console.log(reqIdMsg);
      if (response.ok) {
        const result = await response.json();
        callback(result, dispatch);
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 400:
            errorResponse = await response.json();
            errorMessage = errorResponse.message ?? 'Bad Request';
            break;
          case 401:
            errorMessage = 'Authentication required';
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.reason;
            if (errorResponse.unauthorizedFields !== undefined) {
              errorMessage = `${errorResponse.reason}, ${errorResponse.unauthorizedFields.join(',')}`;
            }
            break;
          case 500:
            errorResponse = await response.json();
            if (errorResponse.sqlMessage) {
              console.log(`API encountered SQL error ${errorResponse.code}: ${errorResponse.sqlMessage}`);
              errorMessage = errorResponse.sqlMessage;
            } else {
              errorMessage = `API encountered an error, ${reqIdMsg}`;
            }
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        if (reqId) {
          errorMessage = `${errorMessage}, Request ID = ${reqId}`;
        }
        console.error(`Error code: ${response.status}, Message: ${errorMessage}`);
        dispatch(setErrorMessage(errorMessage));
      }
    } catch (err) {
      console.error('Fetch API returned error:', err);
      dispatch(setErrorMessage(err.toString()));
    } finally {
      dispatch(finishFetching());
    }
  }
}

export const setFilterCriteria = filterCriteria => ({
  type: 'SET_COURT_DIST_DISPLAY_FILTER_CRITERIA',
  filterCriteria
});

export const setFilteredItems = items => ({
  type: 'SET_FILTERED_COURT_DIST',
  filteredItems: items
});