import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setFilterCriteria } from './ChargeConversionActions';

const TableFilter = styled.fieldset`
  width: 100%;

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  & label {
    margin-right: 5px;
  }

  & input {
    max-width: 8em;
  }
`;

const chargeConversionFilter = props => {

  const [source,   setSource]   = useState('none');
  const [cjis,     setCjis]     = useState('');
  const [external, setExternal] = useState('');

  return (
    <div id="filterarea-div">
      <form id="filterarea">
        <TableFilter>
          <legend>List Filters</legend>
          <div>
            <div>
              <label htmlFor="source-filter">Source</label>
              <select id="source-filter"
                      value={source}
                      onChange={e => { setSource(e.target.value);
                                      props.setFilterCriteria({source: e.target.value, cjis, external})
                                    }
                              }>
                <option value="none">none</option>
                <option value="JAI">JAI</option>
                <option value="AJIS">AJIS</option>
                <option value="TCIS">TCIS</option>
                <option value="DA">DA</option>
                <option value="PIMS">PIMS</option>
              </select>
            </div>
            <div>
              <label htmlFor="cjis-filter">CJIS</label>
              <input type="text" id="cjis-filter"
                                value={cjis}
                                maxLength={10}
                                onChange={e => { setCjis(e.target.value);
                                                 props.setFilterCriteria({source, cjis: e.target.value, external});
                                               }
                                          }/>
            </div>
            <div>
              <label htmlFor="external-filter">External</label>
              <input type="text" id="external-filter"
                                value={external}
                                maxLength={10}
                                onChange={e => { setExternal(e.target.value);
                                                  props.setFilterCriteria({source, cjis, exernal: e.target.value})
                                                }
                                          }/>
            </div>
          </div>
        </TableFilter>
      </form>
    </div>
  )
}

const mapDispatchToProps = { setFilterCriteria };

export default connect(null, mapDispatchToProps)(chargeConversionFilter);