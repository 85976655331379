import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProfileForm from './ProfileForm';
import { startUpdateProfile, startDeleteProfile } from './ProfileAction';

const EnumberValue = styled.span`
  font-family: consolas, times, mono;
  font-size: large;
`;
EnumberValue.displayName = 'EnumberValue';

const ShowProfile = (props) => {
  const updateProfile = (itemsToUpdate) => {
    props.startUpdateProfile(itemsToUpdate, props.token, props.authhost, props.apikey);
  }
  const deleteProfile = (enumber) => {
    props.startDeleteProfile(enumber, props.token, props.authhost, props.apikey);
  }
  const profile = props.profiles.find(p => p.enumber === props.enumber);
  return (
    <div className="profile_show">
      <p>Enumber: <EnumberValue>{props.enumber}</EnumberValue></p>
      <ProfileForm {...profile} 
                    readonly={false} 
                    updateHandler={updateProfile}
                    deleteHandler={deleteProfile}
                    error={props.error}
                    success={props.success}/>
    </div>
  )
};

const mapStateToProps = state => ({
  profiles: state.profiles.profiles,
  error:    state.profiles.errorMessage,
  success:  state.profiles.successMessage,
  authhost: state.authserver.urlbase,
  apikey:   state.authserver.apikey,
  token:    state.credentials.token,
});

const mapDispatchToProps = { startUpdateProfile, startDeleteProfile };


export default connect(mapStateToProps, mapDispatchToProps)(ShowProfile);