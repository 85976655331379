const initialDisplayFilter = {
  districtFilter: '',
  judDistrictFilter: '',
  courtTypeFilter: '',
  descFilter: '',
  cityFilter: '',
  notExpFilter: true,
  expiredFilter: false,
  nameFilter: '',
  prefixFilter: ''
}

const defaultInitialState = {
  isFetching: false,
  errorMessage: '',
  successMessage: '',
  items: [],
  filterCriteria: initialDisplayFilter,
  filteredItems: [],
  unauthorizedFields: [],
}

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      if (action.dist) {
        console.log(`Court District INITIALIZE called with action.dist having ${action.dist.items.length} items`);
        state = action.dist;
      } else {
        console.log('Court District INITIALIZE called with no action.dist');
      }
      console.log(`Court District returning with ${state.items.length} items`);
      return state;
    
    case 'START_FETCHING':
      return { ...state, isFetching: true };
    
    case 'FINISH_FETCHING':
      return { ...state, isFetching: false };
    
    case 'UPDATE_COURT_DIST_FETCH_RESULTS':
      return { ...state,
               errorMessage: action.errorMessage,
               successMessage: action.successMessage,
               items: action.items,
             };
        
    case 'UPDATE_COURT_DIST':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: state.items.map(dist => ( dist.court_type === action.update.court_type &&
                                                dist.judicial_district === action.update.judicial_district )
                                            ? { ...dist, ...action.update }
                                            : dist)
             };

    case 'ADD_COURT_DIST':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: [...state.items, action.newDist]
             };

    case 'SET_COURT_DIST_DISPLAY_FILTER_CRITERIA':
      return { ...state,
               filterCriteria: action.filterCriteria };
              
    case 'SET_FILTERED_COURT_DIST':
      return { ...state,
               filteredItems: action.filteredItems };

    case 'SET_ERROR_MESSAGE':
      return { ...state,
               errorMessage: action.errorMessage };

    case 'CLEAR_MESSAGES':
      return { ...state,
               errorMessage: '',
               successMessage: '',
             } ;

    default:
      console.log(`Court District default reducer with action.type = ${action.type}`);
      return state;
  }
}