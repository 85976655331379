import React, { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { setFilterCriteria, setFilteredItems } from './LeaActions';
import styled from 'styled-components';

const commafy = aNumber => numeral(aNumber).format('0,0');

  const FilterSection = styled.section`
    display: flex;
    gap: 20px;

    label {
      font-family: georgia;
      font-size: 10pt;
      color: #00aad6;
    }

    input {
      font-family: consolas;
      font-size: 10pt;
      background: #e8e8e8;
    }

    select {
      font-family: helvetica;
    }
  `;
  FilterSection.displayName = 'FilterSection';

  const SmallField = styled.div`
    display: grid;
    grid-template-rows: 1em 1.5em;
    width: 80px;
  `;
  SmallField.displayName = 'SmallField';

  const MediumField = styled.div`
    display: grid;
    grid-template-rows: 1em 1.5em;
    width: 120px;
  `;
  MediumField.displayName = 'MediumField';

  const LongField = styled.div`
    display: grid;
    grid-template-rows: 1em 1.5em;
    width: 200px;
  `;
  LongField.displayName = 'LongField';

const FilterStats = styled.div`
  width: 14em;
  .propName {
    font-family: georgia;
    text-align: right;
  }
  .propValue {
    font-family: consolas;
    text-align: right;
  }
`;
FilterStats.displayName = 'FilterStats';

const leaFilter = props => {
  console.log(DateTime.local().toISOTime(), 'leaFilter render lea.length = ', props.lea.length);

  const f = props.filterCriteria;
  const [codeFilter, setCodeFilter] = useState(f.codeFilter || '');
  const [ncicFilter, setNcicFilter] = useState(f.ncicFilter || '');
  const [updatedFromFilter, setUpdatedFromFilter] = useState(f.updatedFromFilter || '');
  const [updatedToFilter,   setUpdatedToFilter  ] = useState(f.updatedToFilter   || '');
  const [expiredFromFilter, setExpiredFromFilter] = useState(f.expiredFromFilter || '');
  const [expiredToFilter,   setExpiredToFilter  ] = useState(f.expiredToFilter   || '');
  const [expiredFlagFilter, setExpiredFlagFilter] = useState(f.expiredFlagFilter || '');
  const [subpoenaFilter, setSubpoenaFilter] = useState(f.subpoenaFilter || '');
  const [nameFilter, setNameFilter] = useState(f.nameFilter || '');
  const [cityFilter, setCityFilter] = useState(f.cityFilter || '');
  const [primaryLeaFilter, setPrimaryLeaFilter] = useState(f.primaryLeaFilter || '');
  const [useridFilter, setUseridFilter] = useState(f.useridFilter || '');

  const filterCriteria = {
    codeFilter,
    ncicFilter,
    updatedFromFilter,
    updatedToFilter,
    expiredFromFilter,
    expiredToFilter,
    expiredFlagFilter,
    subpoenaFilter,
    nameFilter,
    cityFilter,
    primaryLeaFilter,
    useridFilter
  };

  const codeFilterChanged = (newValue) => {
    setCodeFilter(newValue);
    props.setFilterCriteria({...filterCriteria, codeFilter: newValue});
  };
  const ncicFilterChanged = (newValue) => {
    setNcicFilter(newValue);
    props.setFilterCriteria({...filterCriteria, ncicFilter: newValue});
  };
  const updatedFromFilterChanged = (newValue) => {
    setUpdatedFromFilter(newValue);
    props.setFilterCriteria({...filterCriteria, updatedFromFilter: newValue});
  }
  const updatedToFilterChanged = (newValue) => {
    setUpdatedToFilter(newValue);
    props.setFilterCriteria({...filterCriteria, updatedToFilter: newValue});
  }
  const expiredFromFilterChanged = (newValue) => {
    setExpiredFromFilter(newValue);
    props.setFilterCriteria({...filterCriteria, expiredFromFilter: newValue});
  }
  const expiredToFilterChanged = (newValue) => {
    setExpiredToFilter(newValue);
    props.setFilterCriteria({...filterCriteria, expiredToFilter: newValue});
  }
  const expiredFlagFilterChanged = (newValue) => {
    setExpiredFlagFilter(newValue);
    props.setFilterCriteria({...filterCriteria, expiredFlagFilter: newValue})
  }
  const subpoenaFilterChanged = (newValue) => {
    setSubpoenaFilter(newValue);
    props.setFilterCriteria({...filterCriteria, subpoenaFilter: newValue});
  }
  const nameFilterChanged = (newValue) => {
    setNameFilter(newValue);
    props.setFilterCriteria({...filterCriteria, nameFilter: newValue});
  }
  const cityFilterChanged = (newValue) => {
    setCityFilter(newValue);
    props.setFilterCriteria({...filterCriteria, cityFilter: newValue});
  }
  const primaryLeaFilterChanged = (newValue) => {
    setPrimaryLeaFilter(newValue);
    props.setFilterCriteria({...filterCriteria, primaryLeaFilter: newValue});
  }
  const useridFilterChanged = (newValue) => {
    setUseridFilter(newValue);
    props.setFilterCriteria({...filterCriteria, useridFilter: newValue});
  }

  useEffect(() => {
    console.log(DateTime.local().toISOTime(), 'leaFilter useEffect lea.length = ', props.lea.length);
    const filterCode = lea => lea.arrest_agency_code.startsWith(codeFilter);
    const filterNcic = lea => {
      let keepEntry = true;
      if (ncicFilter) {
        if (lea.ncic_identification) {
          const lcFieldValue = lea.ncic_identification.toLowerCase();
          const lcFilterValue = ncicFilter.toLowerCase();
          keepEntry = lcFieldValue.startsWith(lcFilterValue);
        } else {
          keepEntry = false;
        }
      }
      return keepEntry;
    }

    const filterExpiredFromTo = lea => {
      let keepEntry = true;
      const fromDate = DateTime.fromISO(expiredFromFilter);
      const toDate   = DateTime.fromISO(expiredToFilter);
      const expired  = DateTime.fromISO(lea.expiration_date);
      if (fromDate.isValid || toDate.isValid) {
        if (expired.isValid) {
          if (fromDate.isValid && Interval.fromDateTimes(fromDate, expired).isValid === false) {
            keepEntry = false;
          }
          if (toDate.isValid  &&  Interval.fromDateTimes(expired, toDate).isValid === false) {
            keepEntry = false;
          }
        } else {
          keepEntry = false;
        }
      }
      return keepEntry;
    }
    const filterUpdatedFromTo = lea => {
      let keepEntry = true;
      const fromDate = DateTime.fromISO(updatedFromFilter);
      const toDate   = DateTime.fromISO(updatedToFilter);
      const updated  = DateTime.fromISO(lea.update_date);
      if (fromDate.isValid || toDate.isValid) {
        if (updated.isValid) {
          if (fromDate.isValid && Interval.fromDateTimes(fromDate, updated).isValid === false) {
            keepEntry = false;
          }
          if (toDate.isValid  &&  Interval.fromDateTimes(updated, toDate).isValid === false) {
            keepEntry = false;
          }
        } else {
          keepEntry = false;
        }
      }
      return keepEntry;
    }
    const filterExpiredFlag = lea => {
      let keepEntry = true;
      switch (expiredFlagFilter) {
        case '': break;
        case 'blank':
             keepEntry = !lea.expired_flag;
             break;
        default:
             keepEntry = lea.expired_flag 
                       ? lea.expired_flag.toUpperCase() == expiredFlagFilter
                       : false;
      }
      return keepEntry;
    };
    const filterSubpoena = lea => {
      let keepEntry = true;
      switch (subpoenaFilter) {
        case '': break;
        case 'blank':
             keepEntry = !lea.subpoena_service_type;
             break;
        default:
             keepEntry = lea.subpoena_service_type 
                       ? lea.subpoena_service_type.toUpperCase() == subpoenaFilter
                       : false;
      }
      return keepEntry;
    };
    const filterName = lea => {
      let keepEntry = true;
      if (nameFilter) {
        keepEntry = lea.agency_name 
                  ? lea.agency_name.toLowerCase().includes(nameFilter.toLowerCase())
                  : false;
      }
      return keepEntry;
    }
    const filterCity = lea => {
      let keepEntry = true;
      if (cityFilter) {
        keepEntry = lea.city ? lea.city.toLowerCase().includes(cityFilter.toLowerCase())
                            : false;
      }
      return keepEntry;
    }
    const filterPrimaryLea = lea => {
      let keepEntry = true;
      if (primaryLeaFilter) {
        keepEntry = lea.primary_lea
                      ? lea.primary_lea.startsWith(primaryLeaFilter)
                      : false;
      }
      return keepEntry;
    }
    const filterUserId = lea => {
      let keepEntry = true;
      if (useridFilter) {
        const lcUseridFilter = useridFilter.toLowerCase();
        const lcUseridValue = lea.user_id ? lea.user_id.toLowerCase() : ' ';
        keepEntry = lcUseridValue.includes(lcUseridFilter);
      }
      return keepEntry;
    }

    const selectedLeas = props.lea.filter(lea => 
          filterCode(lea) 
        && filterNcic(lea) 
        && filterExpiredFromTo(lea)
        && filterUpdatedFromTo(lea)
        && filterSubpoena(lea)
        && filterExpiredFlag(lea)
        && filterName(lea)
        && filterCity(lea)
        && filterPrimaryLea(lea)
        && filterUserId(lea)
      ).sort((a,b) => a.arrest_agency_code < b.arrest_agency_code ? -1 : 0);
  
    props.setFilteredItems(selectedLeas);
  }, [codeFilter,
      ncicFilter,
      expiredFromFilter,
      expiredToFilter,
      expiredFlagFilter,
      updatedFromFilter,
      updatedToFilter,
      subpoenaFilter,
      nameFilter,
      cityFilter,
      primaryLeaFilter,
      useridFilter,
      props.lea.length
    ]);
  
  return (
    <fieldset style={{width: 'min-content'}} >
      <legend>LEA List Filters</legend>
      <FilterSection id="LeaFilters">
        <div>
          <SmallField>
            <label>Agency Code</label>
            <input type="text"
                  tabIndex="1"
                  id="agencyCode"
                  value={codeFilter}
                  size="4"
                  onChange={e => codeFilterChanged(e.target.value)} />
          </SmallField >
          <SmallField>
            <label>Primary LEA</label>
            <input type="text"
                  tabIndex="2"
                  id="primaryLea"
                  value={primaryLeaFilter}
                  size="4"
                  onChange={e => primaryLeaFilterChanged(e.target.value)} />
          </SmallField>
        </div>
        <div>
          <LongField>
            <label>Name</label>
            <input type="text"
                  tabIndex="3"
                  id="agencyName"
                  value={nameFilter}
                  onChange={e => nameFilterChanged(e.target.value)} />
          </LongField>
          <LongField>
            <label>City</label>
            <input type="text"
                  tabIndex="4"
                  id="city"
                  value={cityFilter}
                  onChange={e => cityFilterChanged(e.target.value)} />
          </LongField>
        </div>
        <div>
          <MediumField>
            <label>NCIC ID</label>
            <input type="text"
                  tabIndex="5"
                  id="ncicId"
                  value={ncicFilter}
                  size="10"
                  onChange={e => ncicFilterChanged(e.target.value)} />
          </MediumField>
          <MediumField>
            <label>User ID</label>
            <input type="text"
                  tabIndex="6"
                  placeholder="email"
                  id="userid"
                  value={useridFilter}
                  size="10"
                  onChange={e => useridFilterChanged(e.target.value)} />
          </MediumField>
        </div>
        <div>
          <MediumField>
            <label>Subpoena Delivery</label>
            <select type="select"
                    tabIndex="11"
                    id="subpoena"
                    value={subpoenaFilter}
                    onChange={e => subpoenaFilterChanged(e.target.value)} >
                      <option value="E">Electronic</option>
                      <option value="P">Paper</option>
                      <option value="blank">Blank Entry</option>
                      <option value="">All</option>
            </select>
          </MediumField>
          <MediumField>
            <label>Expiration Flag</label>
            <select type="select"
                    tabIndex="12"
                    id="expirationFlag"
                    value={expiredFlagFilter}
                    onChange={e => expiredFlagFilterChanged(e.target.value)} >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                      <option value="blank">Blank Entry</option>
                      <option value="">All</option>
            </select>
          </MediumField>
        </div>
        <div>
          <MediumField>
            <label>Expired From</label>
            <input type="text"
                  tabIndex="7"
                  placeholder="yyyy-mm-dd"
                  id="expiredFrom"
                  value={expiredFromFilter}
                  size="10"
                  onChange={e => expiredFromFilterChanged(e.target.value)} />
          </MediumField>
          <MediumField>
            <label>Expired To</label>
            <input type="text"
                  tabIndex="8"
                  placeholder="yyyy-mm-dd"
                  id="expiredTo"
                  value={expiredToFilter}
                  size="10"
                  onChange={e => expiredToFilterChanged(e.target.value)} />
          </MediumField>
        </div>
        <div>
          <MediumField>
            <label>Updated From</label>
            <input type="text"
                  tabIndex="9"
                  placeholder="yyyy-mm-dd"
                  id="updatedFrom"
                  value={updatedFromFilter}
                  size="10"
                  onChange={e => updatedFromFilterChanged(e.target.value)} />
          </MediumField>
          <MediumField>
            <label>Updated To</label>
            <input type="text"
                  tabIndex="10"
                  placeholder="yyyy-mm-dd"
                  id="updatedTo"
                  value={updatedToFilter}
                  size="10"
                  onChange={e => updatedToFilterChanged(e.target.value)} />
          </MediumField>
        </div>
        <div>
          <FilterStats>
            <table>
              <tbody>
                <tr>
                  <td className="propName">Records loaded:</td>
                  <td className="propValue">{commafy(props.lea.length)}</td>
                </tr>
                <tr>
                  <td className="propName">Records displayed:</td>
                  <td className="propValue">{commafy(props.filteredItems.length)}</td>
                </tr>
              </tbody>
            </table>
          </FilterStats>
        </div>
      </FilterSection>
    </fieldset>
  );
}

const mapStateToProps = (state) => {
  console.log('LeaFilter mapStateToProps state.lea.items.length', state.lea.items.length);
  return ({
    lea:            state.lea.items,
    filteredItems:  state.lea.filteredItems,
    filterCriteria: state.lea.filterCriteria
  });
}
const mapDispatchToProps = { setFilterCriteria, setFilteredItems };

export default connect(mapStateToProps, mapDispatchToProps)(leaFilter);