import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import CourtDistForm from './CourtDistForm';
import { clearMessages, startUpdateCourtDist } from './CourtDistAction';

const updateCourtDist = props => {
  const navigate = useNavigate();
  const { court_type, judicial_district } = useParams();
  console.log(`Updating court district (${court_type}, ${judicial_district})`);

  const dist = props.courtdist.items.find(item => (item.court_type === court_type)
                                               && (item.judicial_district === judicial_district));

  function onSubmit(modifiedValues) {
    props.startUpdateCourtDist(modifiedValues, props.token, props.apihost, props.apikey);
  }

  function onCancel() {
    props.clearMessages();
    navigate('/CourtDistrict/dashboard');
  }

  return <CourtDistForm title="Update Court District"
                        submitLabel="Update Record"
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        clearMessages={props.clearMessages}
                        errorMessage={props.errorMessage}
                        successMessage={props.successMessage}
                        authenticated={!!props.token}
                        courtdist={dist}
          />
}

const mapStateToProps = (state) => ({
  courtdist: state.courtdist,
  errorMessage: state.courtdist.errorMessage,
  successMessage: state.courtdist.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey
});

const mapDispatchToProps = {
  startUpdateCourtDist,
  clearMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(updateCourtDist);