import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ChargeConversionList from './ChargeConversionList';
import ChargeConversionForm from './ChargeConversionForm';
import { startFetchingConversions } from './ChargeConversionActions.js';
import { LoadingMessage } from '../components/Loading';

const DashboardContent = styled.div`
  display: grid;
  grid-template-columns: auto 600px;
  grid-auto-flow: column;

  & section {
    margin: 15px 5px;
  }
`;

export const chargeConversionDashboard = props => {
  const runEffectOnlyOnce = true;

  // The runEffectOnlyOnce restricts the effect to running only
  // once per page load.  But even this is too much if the LEA
  // items have already been loaded from a previous page load
  // and there have been no changes.  Therefore, inside the
  // useEffect function, we verify that the number of items is
  // zero before issuing a request for a new set.
  //
  useEffect(() => {
    console.log('In ChargeConversionDashboard useEffect');
    if (props.conversions.items.length === 0) {
      console.log(`No Conversions found on dashboard, starting Conversion API fetch.`);
      props.startFetchingConversions(props.apihost, props.apikey);
    } else {
      console.log(`Skipping ConversionDashboard fetch; already havee ${props.conversions.items.length} items.`)
    }
  }, [runEffectOnlyOnce]);

  return <div id="ChargeConversionDashboard">
    <h2>Charge Conversion Dashboard</h2>
    <DashboardContent>
      <section>
        {props.conversions.isFetching ? <LoadingMessage message='Fetching charge conversions ...' />
                                      : <ChargeConversionList />
        }
      </section>
      <section>
        <ChargeConversionForm />
      </section>
    </DashboardContent>
  </div>
}

const mapStateToProps = state => ({
  conversions: state.conversions,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey
});

const mapDispatchToProps = {
  startFetchingConversions
}

export default connect(mapStateToProps, mapDispatchToProps)(chargeConversionDashboard);