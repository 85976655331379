import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
    color: dodgerblue;
    text-align: right;
    margin-right: 3px;
    font-family: "Helvetica", serif;
    font-size: 0.8em;
    align-self: center;
`;
const Input = styled.input`
  & {
    background-color: #f7fed5;
    align-self: flex-end;
    ${props => props.long_value ? 'grid-column: 2 / span 5' : '' };
    ${props => props.mid_left ? 'grid-column: 2 / span 2' : '' };
    ${props => props.mid_right ? 'grid-column: 5 / span 2' : '' };
  }
  &&:invalid {
    background-color: #f42300;
  }`;

// props:
//   - id of the input field
//   - label
//   - long_value - whether the value needs to span multiple cells.
//   - value
//   - onChange
export const TextField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
             type="text"
             minLength={props.minLength ? props.minLength : 0}
             maxLength={props.maxLength ? props.maxLength : 60}
             pattern={props.pattern ? props.pattern : '.*'}
             title={props.title ? props.title : ''}
             long_value={props.long_value ? true : false}
             mid_left={props.mid_left ? true : false}
             mid_right={props.mid_right ? true : false}
             required={props.required ? true : false}
             readOnly={props.readOnly ?? false}
             value={props.value}
             onChange={props.onChange}/>
    </>
  );
}

export const ReadonlyField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
             long_value={props.long_value ? true : false}
             type="text"
             value={props.value}
             readOnly />
    </>
  );
};

export const DateField = props => {
  return (
    <>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
            type="text"
            pattern="^\d\d\d\d-\d\d-\d\d$"
             long_value={props.long_value ? true : false}
             mid_left={props.mid_left ? true : false}
             mid_right={props.mid_right ? true : false}
            required={props.required ? true : false}
            title="Date format yyyy-mm-dd"
            placeholder="yyyy-mm-dd"
            readOnly={props.readOnly}
            value={props.value}
            onChange={props.onChange}/>
    </>
  );
}

const Messages = styled.div`
  margin: 10px 0;
`;

const Success = styled.p`
  color: green;
  margin-block-start: 2px;
  margin-block-end: 2px;
`;

const Warning = styled.p`
  color: red;
  margin-block-start: 2px;
  margin-block-end: 2px;
`;

export const MessageBox = props => {
  return <fieldset>
    <legend>Messages</legend>
    <Messages>
      <Success>{props.successMessage}</Success>
      <Warning>{props.errorMessage}</Warning> 
      {props.authenticated ? <p>Authenticated</p> : <Warning>Not Authenticated</Warning>}
      <Warning>{props.validateMessage}</Warning>
      {props.fieldsValid ? '' : <Warning>Invalid fields have red background</Warning>}
    </Messages>
  </fieldset>
}

const Header = styled.div`
  display: grid;
  grid-template-columns: 25em 30em;
`;

export const PageHeader = props => {
  return <Header>
    <h2>{props.title}</h2>
    <MessageBox {...props}/>
  </Header>
}
