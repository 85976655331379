import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './CourtDistDashboard';
import Add       from './AddCourtDist';
import Update    from './UpdateCourtDist';

const CourtDistRouter = () => <div>
  <Routes>
    <Route path={'/dashboard'}  element={<Dashboard />} />
    <Route path={'/add'}        element={<Add />} />
    <Route path={'/detail/:judicial_district/:court_type'} element={<Update />} />
  </Routes>
</div>;

export default CourtDistRouter;