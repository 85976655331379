import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProfileRow from './ProfileRow';

const StyledTable = styled.table`
  border-collapse: collapse;
  thead tr {
    background: lightgrey;
  }
`;
StyledTable.displayName = 'StyledTable';


export const ProfileTable = props => (
  <div>
    {
      props.profiles.length === 0 ? (
        <p>Empty profile</p>
      ) : (
        <StyledTable border="1" padding="2">
          <thead>
            <tr>
              <th>eNumber</th>
              <th>Email</th>
              <th>Scopes</th>
              <th>Description</th>
              <th>Profile State</th>
            </tr>
          </thead>
          <tbody>
            { props.profiles.map((p, index) => (
              <ProfileRow {...p}
                          index={index+1}
                          key={p.enumber} />
              ))
            }
          </tbody>
        </StyledTable>
      )
    }
  </div>
);

const mapStateToProps = state => {
  return { profiles: state.profiles.profiles }
}

export default connect(mapStateToProps)(ProfileTable);