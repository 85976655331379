import fetch from 'cross-fetch';

const leaUri = '/api/lea';
const standardHeaders = {
  'Accept': 'application/json'
}

const  startFetching = () => ({ type:  'START_FETCHING'});
const finishFetching = () => ({ type: 'FINISH_FETCHING'});
const updateFetchResults = (items, errorMessage, successMessage) => ({
  type: 'UPDATE_LEA_FETCH_RESULTS',
  items,
  errorMessage,
  successMessage
});

export const startFetchingLeas = (apihost, apikey, msTimeout = 10000) => {
  const controller = new AbortController();
  const fetchUrl = apihost + leaUri;
  const fetchOptions = { method: 'GET', signal: controller.signal };
  fetchOptions['headers'] = { ...standardHeaders };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    const timeout = setTimeout(() => controller.abort(), msTimeout);
    try {
      console.time('fetchLEAs');
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('fetchLEAs');
      console.log('LEA GET status code = ', response.status);
      if (response.ok) {
        const results = await response.json();
        const msg = `LEA result size = ${results.length}`;
        dispatch(updateFetchResults(results, '', msg));
        console.log(msg);
      } else {
        let errorMessage = `Failed to fetch LEA codes with status ${response.status}`;
        dispatch(setErrorMessage(errorMessage));
      }
    } catch(err) {
      const msg = `Error retrieving LEA records from API: ${err.message}`;
      console.error(msg);
      dispatch(setErrorMessage(msg));
    } finally {
      clearTimeout(timeout);
      dispatch(finishFetching());
    }
  };
}

const addLea = (newLea, successMessage = 'Success') => ({
  type: 'ADD_LEA',
  newLea,
  successMessage
});

export const startAddLea = (newLea, token, apihost, apikey) => {
  return startAddUpdateLea(newLea, token, 'POST', apihost, apikey, (jsonBody, dispatch) => {
    console.log(`LEA ${newLea.arrest_agency_code} added.`);
    console.log(`LEA add returned ${JSON.stringify(jsonBody)}`);
    dispatch(addLea(newLea, `Successfully added LEA ${newLea.arrest_agency_code}`));
  });
}

const updateLea = (modifiedLea, successMessage = 'Success') => ({
  type: 'UPDATE_LEA',
  arrest_agency_code: modifiedLea.arrest_agency_code,
  update: modifiedLea,
  successMessage
});

export const startUpdateLea = (modifiedLeaValues, token, apihost, apikey) => {
  return startAddUpdateLea(modifiedLeaValues, token, 'PATCH', apihost, apikey, (jsonBody, dispatch) => {
    console.log(`LEA ${modifiedLeaValues.arrest_agency_code} updated.`);
    console.log(`LEA update returned ${JSON.stringify(jsonBody)}`);
    dispatch(updateLea(modifiedLeaValues, 'Update successful'));
  });
}

const startAddUpdateLea = (lea_values, token, method, apihost, apikey, callback) => {
  const fetchUrl = apihost + leaUri;
  const fetchOptions = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': apikey || '',
    },
    body: JSON.stringify(lea_values)
  }
  return async (dispatch) => {
    dispatch(clearMessages());
    dispatch(startFetching());
    try {
      console.time('AddUpdateLea');
      let response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd('AddUpdateLea');
      const reqId = response.headers.get('x-cjisapi-requestid');
      const reqIdMsg = reqId ? `Request ID: ${reqId}` : `No request ID returned`;
      console.log(reqIdMsg);
      if (response.ok) {
        const result = await response.json();
        callback(result, dispatch);
      } else {
        let errorMessage, errorResponse;
        switch(response.status) {
          case 400:
            errorResponse = await response.json();
            errorMessage = errorResponse.message ?? 'Bad Request';
            break;
          case 401:
            errorMessage = "Authentication required";
            break;
          case 403:
            errorResponse = await response.json();
            errorMessage = errorResponse.reason;
            if (errorResponse.unauthorizedFields !== undefined) {
              errorMessage = `${errorResponse.reason}, ${errorResponse.unauthorizedFields.join(',')}`;
            }
            break;
          case 500:
            errorResponse = await response.json();
            if (errorResponse.sqlMessage) {
              console.log(`API encountered SQL error ${errorResponse.code}: ${errorResponse.sqlMessage}`);
              errorMessage = errorResponse.sqlMessage;
            } else {
              errorMessage = `API encountered an error, ${reqIdMsg}`;
            }
            break;
          default:
            errorMessage = `Encountered HTTP status ${response.status}`;
        }
        if (reqId) {
          errorMessage = `${errorMessage}, RequestId = ${reqId}`;
        }
        console.error(`Error code: ${response.status}, Message: ${errorMessage}`);
        dispatch(setErrorMessage(errorMessage));
      }
    } catch (err) {
      console.error('Fetch API returned error:', err);
      dispatch(setErrorMessage(err.toString()));
    } finally {
      dispatch(finishFetching());
    }
  }
}

const setErrorMessage = (errorMessage) => ({
  type: 'SET_ERROR_MESSAGE',
  errorMessage
});

export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES'
});

export const setCurrentRow = id => ({
  type: 'SET_LEA_ROW',
  id
});

export const setFilterCriteria = filterCriteria => ({
  type: 'SET_LEA_DISPLAY_FILTER_CRITERIA',
  filterCriteria
});

export const setFilteredItems = items => ({
  type: 'SET_FILTERED_LEAS',
  filteredItems: items
})