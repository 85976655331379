import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { setFilterCriteria, setFilteredItems } from './CourtDeptAction';
import styled from 'styled-components';

const commafy = aNumber => numeral(aNumber).format('0,0');

const courtDeptFilter = props => {
  const f = props.filterCriteria;
  const [deptFilter,   setDeptFilter] = useState(f.deptFilter || '');
  const [judDistrictFilter, setJudDistrictFilter] = useState(f.judDistrictFilter || '');
  const [courtTypeFilter, setCourtTypeFilter] = useState(f.courtTypeFilter || '');
  const [descFilter, setDescFilter] = useState(f.descFilter || '');
  const [addressFilter, setAddressFilter] = useState(f.addressFilter || '');
  const [cityFilter, setCityFilter] = useState(f.cityFilter || '');
  const [notExpFilter, setNotExpFilter] = useState(f.setNotExpFilter || true);
  const [expiredFilter, setExpiredFilter] = useState(f.expiredFilter || false);
  
  const filterCriteria = {
    deptFilter,
    judDistrictFilter,
    courtTypeFilter,
    descFilter,
    addressFilter,
    cityFilter,
    notExpFilter,
    expiredFilter,
  };

  const deptFilterChanged = newValue => {
    setDeptFilter(newValue);
    props.setFilterCriteria({...filterCriteria, deptFilter: newValue});
  };
  const judDistrictFilterChanged = newValue => {
    setJudDistrictFilter(newValue);
    props.setFilterCriteria({...filterCriteria, judDistrictFilter: newValue});
  };
  const courtTypeFilterChanged = newValue => {
    setCourtTypeFilter(newValue);
    props.setFilterCriteria({...filterCriteria, courtTypeFilter: newValue});
  };
  const descFilterChanged = newValue => {
    setDescFilter(newValue);
    props.setFilterCriteria({...filterCriteria, descFilter: newValue});
  };
  const addressFilterChanged = newValue => {
    setAddressFilter(newValue);
    props.setFilterCriteria({...filterCriteria, addressFilter: newValue});
  };
  const cityFilterChanged = newValue => {
    setCityFilter(newValue);
    props.setFilterCriteria({...filterCriteria, cityFilter: newValue});
  };
  const notExpFilterChanged = newValue => {
    setNotExpFilter(newValue);
    props.setFilterCriteria({...filterCriteria, notExpFilter: newValue});
  };
  const expiredFilterChanged = newValue => {
    setExpiredFilter(newValue);
    props.setFilterCriteria({...filterCriteria, expiredFilter: newValue});
  };

  // This effect re-filters the result set based on updated
  // filter values.
  //
  function filterTextField(filterValue, fieldValue) {
    let keepEntry = true;
    if (filterValue) {
      if (fieldValue) {
        const ucFieldValue  = fieldValue.toUpperCase();
        const ucFilterValue = filterValue.toUpperCase();
        keepEntry = ucFieldValue.startsWith(ucFilterValue);
      } else {
        keepEntry = false;
      }
    }
    return keepEntry
  }

  function filterIntField(filterValue, fieldValue) {
    let keepEntry = true;
    if (filterValue) {
      keepEntry = parseInt(filterValue) === parseInt(fieldValue);
    }
    return keepEntry;
  }

  function filterExpired(includeExpired, includeNotExpired, fieldValue) {
    let expired = false;
    const expDate = DateTime.fromISO(fieldValue);
    if (expDate.isValid) {
      const today = DateTime.now();
      expired = expDate < today;
    }
    const keepEntry = (expired && includeExpired)
                   || (!expired && includeNotExpired);
    return keepEntry;
  }

  useEffect(() => {
    const selectedDepartments = props.courtdept.filter(dept =>
         filterTextField(deptFilter,        dept.department)
      && filterTextField(judDistrictFilter, dept.judicial_district)
      && filterTextField(courtTypeFilter,   dept.court_type)
      && filterTextField(descFilter,        dept.description)
      && filterTextField(addressFilter,     dept.address)
      && filterTextField(cityFilter,        dept.city)
      && filterExpired(expiredFilter, notExpFilter, dept.exp_date)
    ).sort((a,b) => {
      let sort_result = 0;
      if (a.judicial_district === b.judicial_district) {
        sort_result = (a.department < b.department) ? -1 : 0;
      } else {
        sort_result = (a.judicial_district < b.judicial_district) ? -1 : 0;
      }
      return sort_result;
    });
    props.setFilteredItems(selectedDepartments);
  }, [ 
    deptFilter,
    judDistrictFilter,
    courtTypeFilter,
    descFilter,
    addressFilter,
    cityFilter,
    notExpFilter,
    expiredFilter,
    props.courtdept.length
  ]);
  
  return (
    <fieldset style={{width: 'min-content'}}>
      <legend>Court Department Filters</legend>
      <FilterSection>
        <div>
          <SmallField>
            <label>Department</label>
            <input type="text"
                   tabIndex="1"
                   id="department"
                   value={deptFilter}
                   size="3"
                   onChange={e => deptFilterChanged(e.target.value)} />
          </SmallField>
          <SmallField>
            <label>District</label>
            <input type="text"
                   tabIndex="2"
                   id="judicial_district"
                   size="3"
                   value={judDistrictFilter}
                   onChange={e => judDistrictFilterChanged(e.target.value)} />
          </SmallField>
        </div>
        <div>
          <MediumField>
            <label>Court Type</label>
            <select type="select"
                    tabIndex="3"
                    id="court_type"
                    value={courtTypeFilter}
                    onChange={e => courtTypeFilterChanged(e.target.value)} >
              <option value="">Any</option>
              <option value="M">M – Municipal</option>
              <option value="S">S – Superior</option>
              <option value="X">X – Juvenile</option>
              <option value="J">J – Justice</option>
              <option value="P">P – Parole</option>
            </select>
          </MediumField>
          <MediumField>
            <label>Description</label>
            <input type="text"
                   tabIndex="4"
                   id="description"
                   size="12"
                   value={descFilter}
                   onChange={e => descFilterChanged(e.target.value)} />
          </MediumField>
        </div>
        <div>
          <Checkbox>
            <input type="checkbox"
                   tabIndex="5"
                   id="not_expired"
                   checked={notExpFilter}
                   onChange={e => notExpFilterChanged(e.target.checked)} />
            <label htmlFor="not_expired">Not Expired</label>
          </Checkbox>
          <Checkbox>
            <input type="checkbox"
                   tabIndex="6"
                   id="expired"
                   value={expiredFilter}
                   onChange={e => expiredFilterChanged(e.target.checked)} />
            <label>Expired</label>
          </Checkbox>
          <MediumField>
            <label htmlFor="city">City</label>
            <input type="text"
                   tabIndex="7"
                   id="city"
                   size="12"
                   value={cityFilter}
                   onChange={e => cityFilterChanged(e.target.value)} />
          </MediumField>
        </div>
        <div>
          <FilterStats>
            <table>
              <tbody>
                <tr>
                  <td className="propName">Records loaded:</td>
                  <td className="propValue">{commafy(props.courtdept.length)}</td>
                </tr>
                <tr>
                  <td className="propName">Records displayed:</td>
                  <td className="propValue">{commafy(props.filteredItems.length)}</td>
                </tr>
              </tbody>
            </table>
          </FilterStats>
          <MediumField>
            <label htmlFor="address">Address</label>
            <input type="text"
                   tabIndex="8"
                   id="address"
                   size="20"
                   value={addressFilter}
                   onChange={e => addressFilterChanged(e.target.value)} />
          </MediumField>
        </div>
      </FilterSection>
    </fieldset>
  )
}

const FilterSection = styled.section`
  display: flex;
  gap: 20px;

  label {
    font-family: georgia;
    font-size: 10pt;
    color: #00aad6;
  }

  input {
    font-family: consolas;
    font-size:10pt;
    background: #e8e8e8;
  }

  select {
    font-family: helvetica;
    background: #e8e8e8;
  }
`;
FilterSection.displayName = 'FilterSection';

const SmallField = styled.div`
  display: grid;
  grid-template-rows: 1em 1.5em;
  width: 80px;
  margin-bottom: 5px;
`;
SmallField.displayName = 'SmallField';

const MediumField = styled.div`
  display: grid;
  grid-template-rows: 1em 1.5em;
  width: 120px;
  margin-bottom: 5px;
`;
MediumField.displayName = 'MediumField';

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  & input {
    margin-right: 5px;
  }
`;

const FilterStats = styled.div`
  width: 14em;
  .propName {
    font-family: georgia;
    text-align: right;
  }
  .propValue {
    font-family: consolas;
    text-align: right;
  }
`;
FilterStats.displayName = 'FilterStats';

const mapStateToProps = state => ({
  courtdept:      state.courtdept.items,
  filteredItems:  state.courtdept.filteredItems,
  filterCriteria: state.courtdept.filterCriteria
});

const mapDispatchToProps = { setFilterCriteria, setFilteredItems };

export default connect(mapStateToProps, mapDispatchToProps)(courtDeptFilter);