import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChargeCodeForm from './ChargeCodeForm';
import { clearFailed, startAddChargeCode } from './ChargeCodeActions';

const addChargeCode = props => {

  const navigate = useNavigate();

  function onSubmit(chargeWithoutId) {
    // Convert to integer where necessary.
    //
    const template_id = chargeWithoutId.template_id;
    if (typeof template_id !== 'undefined') {
      chargeWithoutId.template_id = parseInt(template_id);
    }
    if (typeof chargeWithoutId.bcs_hierarchy !== 'undefined') {
      chargeWithoutId.bcs_hierarchy = parseInt(chargeWithoutId.bcs_hierarchy);
    }
    props.startAddChargeCode(chargeWithoutId, props.token, props.apihost, props.apikey);
  }

  function onCancel(evt) {
    evt.preventDefault();
    props.clearFailed();
    navigate('/ChargeCode/dashboard');
  }

  return <div>
    <div className="page-header">
      <h1>Add Charge Code</h1>
      <div className="warning">
        <p>{props.token ? 'Authenticated' : 'Warning: Not Authenticated'}</p>
      </div>
    </div>
    <div>
      <ChargeCodeForm onSubmit={onSubmit} 
                      onCancel={onCancel}
                      clearFailed={props.clearFailed}
                      errMsg={props.errorMessage}
                      successMsg={props.successMessage}
                      unauthorizedFields={props.unauthorizedFields}
                      composite_keys={props.composite_keys}
      />
    </div>
  </div>
}

const mapStateToProps = (state) => ({ 
  errorMessage: state.charges.errorMessage,
  successMessage: state.charges.successMessage,
  unauthorizedFields: state.charges.unauthorizedFields,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey,
  composite_keys: new Set(state.charges.items.map(c => c.composite_key))
});

const mapDispatchToProps = {
  startAddChargeCode,
  clearFailed
};

export default connect(mapStateToProps, mapDispatchToProps)(addChargeCode);