const initialChargeCodeDisplayFilter = {
  code: 'PC',
  statute: '',
  expired: true,
  unexpired: true,
  felony: true,
  misdemeanor: true,
  infraction: false,
  otherNone: false,
  fullDesc: '',
  template_id: '',
}
const defaultInitialState = {
  isFetching: false,
  isUpdating: false,
  errorMessage: '',
  successMesssge: '',
  items: [],
  filterCriteria: initialChargeCodeDisplayFilter,
  filteredItems: [],
  unauthorizedFields: []
};

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE': 
      if (action.charges) {
        console.log(`Initialize called with action.charges.items.length = ${action.charges.items.length}`);
        state = action.charges;  // Does work; shallow comparison fails, forces re-render.
      //  state.items = action.charges.items;  // Does NOT work; shallow comparison bypasses rerender.
      //  state = {...state, ...action.charges };   // works, clone causes new object id, shallow compare fails.
      } else {
        console.log('Initialized called with no action.charges.');
      }
      console.log(`Initialize returning with ${state.items.length} items.`);
      return state;
    
    case 'POPULATE_FETCH_RESULTS':
      return { ...state, ...action.charges };
    
    // Notify React components that we are fetching new state.
    //
    case 'START_FETCHING':
      return { ...state,
               isFetching: true,
               items: state.items };
    
    case 'FINISH_FETCHING':
      return { ...state,
               isFetching: false,
               items: state.items };
    
    // Update a single charge with inbound state.
    // The update attribute of the action will contain the
    // subset of the attributes to update (not necessarily
    // the entire charge state).
    //
    case 'UPDATE_CHARGE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: state.items.map(chg => chg.id === action.id
                                    ? { ...chg, ...action.update }
                                    : chg)
             };
            
    // Replace the state of a single charge.
    //
    case 'FETCH_ONE':
      return { ...state,
               isFetching: false,
               items: state.items.map(chg => chg.id === action.id
                                    ? action.charge
                                    : chg)
             };
          
    case 'START_ADDING':
      return { ...state,
               isUpdating: true,
               errorMessage: '',
               successMessage: '' 
             };

    // Add a charge without checking for duplicate id.
    //
    case 'ADD_CHARGE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: [...state.items, action.charge]
             };

    case 'ADD_FAILED':
      return { ...state,
               isUpdating: false,
               errorMessage: action.message,
               successMessage: '' 
             }
    
    case 'DELETE_CHARGE':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               errorMessage: '',
               successMessage: action.message,
               items: state.items.filter(c => c.id !== action.id)
             };
    
    case 'DELETE_CHARGE_FAILED':
      return { ...state,
               isFetching: false,
               isUpdating: false,
               errorMessage: action.message,
               successMessage: ''
             }

    case 'CLEAR_FAILED':
      return { ...state,
               errorMessage: '',
               successMessage: '',
               unauthorizedFields: [],
             }

    case 'UNAUTHORIZED_FIELDS':
      return { ...state,
               unauthorizedFields: action.unauthorizedFields 
             };
    
    case 'SET_CHARGECODE_DISPLAY_FILTER_CRITERIA':
      return { ...state,
               filterCriteria: action.criteria };
    
    case 'SET_FILTERED_CHARGECODES':
      return { ...state,
               filteredItems: action.filteredItems };

    // Just return the state.
    default:
      console.log(`ChargeCode default reducer just ran with action.type = ${action.type}!`)
      return state;
  }
};