const defaultState = {
  token: '',
  claims: {},
  error: '',
  success: ''
};

export default (state = defaultState, action) => {
  switch(action.type) {

    case 'SET_TOKEN':
      return ({ 
        ...state,
        token:  action.token,
        claims: action.claims,
        error:  action.error
      });

    case 'CLEAR_TOKEN':
      return ({
        token: '',
        claims: {},
        error: '',
        success: ''
      });

    case 'SET_ERROR_CREDENTIALS':
      return ({ ...state, error: action.error, success: '' });
    
    case 'SET_SUCCESS_CREDENTIALS':
      return ({ ...state, error: '', success: action.success });
    
    default:
      return state;
  }
};