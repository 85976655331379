import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import chargeCodeReducer     from '../ChargeCode/ChargeCodeReducer';
import chargeConversionReducer from '../ChargeConversion/ChargeConversionReducer';
import credentialReducer     from '../Login/CredentialReducer';
import hostSelectorReducer   from '../HostSelector/HostSelectorReducer';
import profileReducer        from '../UserAdmin/ProfileReducer';
import leaReducer            from '../LEA/LeaReducer';
import courtDeptReducer      from '../CourtDepartment/CourtDeptReducer';
import courtDistReducer      from '../CourtDistrict/CourtDistReducer';
import versionReducer        from '../Version/VersionReducer';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// createStore takes two arguments:
//   1. a reducer or combination of reducers
//   2. (optional) middleware to be executed between the dispatching of
//      the action and the actual change of state.
//
export default () => {
  const store = createStore(
    combineReducers({
      apiserver:   hostSelectorReducer('API_'),
      authserver:  hostSelectorReducer('AUTH_'),
      charges:     chargeCodeReducer,
      conversions: chargeConversionReducer,
      credentials: credentialReducer,
      lea:         leaReducer,
      courtdept:   courtDeptReducer,
      courtdist:   courtDistReducer,
      profiles:    profileReducer,
      versions:    versionReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};