import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledRow = styled.tr`
  &:nth-child(odd) {
    background: #d5e7ff;
  }
  &:nth-child(even) {
    background: #a8ceff;
  }

  td {
    padding: 0 10px;
  }
`;
StyledRow.displayName = 'StyledRow';

const MonoData = styled.td`
  font-family: consolas, "Courier New", mono;
`;
MonoData.displayName = 'MonoData';

const VarData = styled.td`
  font-family: georgia, "Times New Roman", serif;
`;
VarData.displayName = 'VarData';

const ProfileRow = ({
  enumber,
  email,
  scope,
  description,
  enabled
}) => (
  <StyledRow>
    <td><Link to={`/Profiles/detail/${enumber}`}>{enumber}</Link></td>
    <MonoData>{email}</MonoData>
    <MonoData>{scope.length > 0 ? scope.join() : ''}</MonoData>
    <VarData>{description}</VarData>
    <VarData>{enabled ? 'Enabled' : 'Disabled'}</VarData>
  </StyledRow>
);

export default ProfileRow;