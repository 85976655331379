import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setPasswordAdmin, setError, setSuccess } from './CredentialActions';

const SetPasswordAdminDialog = styled.fieldset`
  display: ${props => props.token ? 'flex' : 'none'};
  flex-direction: column;
  row-gap: 5px;
`;
SetPasswordAdminDialog.displayName = 'SetPasswordAdminDialog';

const setPasswordAdminDialog = (props) => {

  const [newPw,   setNewPw]   = useState('');
  const [repPw,   setRepPw]   = useState('');
  const [errMsg,  setErrMsg]  = useState('');

  useEffect(() => {
    setErrMsg('');
    props.setError('');
    props.setSuccess('');
  }, [newPw, repPw]);

  useEffect(() => {
    setErrMsg(props.error);
  }, [props.error]);

  function submit() {
    console.log('enumber = ', props.enumber, 'newPw =', newPw, ', repPw = ', repPw);
    if (newPw == repPw) {
      console.log('Repeat password verified');
      setNewPw('');
      setRepPw('');
      setErrMsg('');
      props.setPasswordAdmin(props.enumber, newPw, props.host, props.token, props.apikey);
    } else {
      setErrMsg('New and repeated password do not match!');
    }
  }

  return (
    <SetPasswordAdminDialog token={props.token}>
      <legend>Update Password for {props.enumber}</legend>
      <input type="password"
             placeholder="Enter new password"
             autoFocus
             value={newPw}
             onChange={e => setNewPw(e.target.value)}
             />
      <input type="password"
             placeholder="Verify new password"
             value={repPw}
             onChange={e => setRepPw(e.target.value)}
             />
      <button type="button" onClick={submit}>Submit</button>
      <p>
        <span className="error">{errMsg}</span>
        <span className="success">{props.success}</span>
      </p>
    </SetPasswordAdminDialog>
  )
}

const mapStateToProps = (state) => ({
  success: state.credentials.success,
  error:   state.credentials.error,
  token:   state.credentials.token,
  host:    state.authserver.urlbase,
  apikey:  state.authserver.apikey,
})

const mapDispatchToProps = { setPasswordAdmin, setError, setSuccess };

export default connect(mapStateToProps, mapDispatchToProps)(setPasswordAdminDialog);