import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setFilteredItems, setFilterCriteria } from './ChargeCodeActions';

const ChargeCodeFilterSet = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: 250px 130px 140px 270px 240px;
  margin-bottom: 10px;

  & p {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
`;

const CodeStatuteEntry = styled.div`
  display: grid;
  grid-template-columns: 3em 11em;
  gap: 10px;

  label {
    text-align: right;
    height: 1.5rem;
  }
`;
CodeStatuteEntry.displayName = 'CodeStatuteEntry';

const CheckboxSet = styled.fieldset`
  & div {
    display: grid;
    grid-template-columns: 1fr 5fr;
    column-gap: 5px;
  }
`;
CheckboxSet.displayName = 'CheckboxSet';

const OtherFilters = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  column-gap: 10px;
  row-gap: 20px;

  label {
    text-align: right;
    height: 1.5rem;
  }
`;

const MaxDisplayInput = styled.input`
  height: 1.8em;
  width: 4em;
  float: right;
  text-align: right;
  background: #a7cfdc;
`;

const filterStatute = (item, filterStatute) => {
  const prop   = item.statute  ? item.statute.toLowerCase()  : '';
  const filter = filterStatute ? filterStatute.toLowerCase() : '';
  return prop.startsWith(filter);
}

const filterExpired = (item, includeExpired, includeUnexpired) => {
  let includeCharge = true;
  if (!includeExpired || !includeUnexpired) {
    if (item.exp_date) {
      const expirationDate = DateTime.fromISO(item.exp_date);
      if (expirationDate.isValid) {
        const chargeExpired = expirationDate.diffNow('days').days < 0;
        includeCharge = (includeExpired && chargeExpired) || (includeUnexpired && !chargeExpired);
      } else { // If exp_date is invalid, do NOT filter.
        console.log('Invalid exp_date', item.exp_date, 'for item', item.code, item.statute);
      }
    } // If exp_date is empty, do NOT filter.
  } // If both expired and unexpired are allowed, bypass date computation.
  return includeCharge;
}

const filterLevel = (item, includeFelony, includeMisdemeanor, includeInfraction, includeOtherNone) => {
  let includeCharge = true;
  const choices = {
    F: includeFelony,
    M: includeMisdemeanor,
    I: includeInfraction,
    O: includeOtherNone
  }
  if (item.offense_level in choices) {
    includeCharge = choices[item.offense_level];
  } else {
    includeCharge = includeOtherNone;
  }
  return includeCharge;
}

const filterDescription = (item, filterString) => {
  let includeCharge = true;
  const full_description_lc = item?.full_description?.toLowerCase();
  if (full_description_lc && filterString) {
    includeCharge = full_description_lc.includes(filterString.toLowerCase());
  }
  return includeCharge;
}

const filterTemplateId = (item, filterString) => {
  let includeCharge = true;
  const templateId = item?.template_id?.toString();
  if (templateId && filterString) {
    includeCharge = templateId.startsWith(filterString);
  }
  return includeCharge;
}

const chargeCodeFilter = props => {
  const [code,        setCode     ]   = useState(props.code);
  const [statute,     setStatute  ]   = useState(props.statute);
  const [expired,     setExpired  ]   = useState(props.expired);
  const [unexpired,   setUnexpired]   = useState(props.unexpired);
  const [felony,      setFelony]      = useState(props.felony);
  const [misdemeanor, setMisdemeanor] = useState(props.misdemeanor);
  const [infraction,  setInfraction]  = useState(props.infraction);
  const [otherNone,   setOtherNone]   = useState(props.otherNone);
  const [fullDesc,    setFullDesc]    = useState(props.fullDesc);
  const [template_id, setTemplate_id] = useState(props.template_id);
  const [maxRows,     setMaxRows]     = useState(100);
  const [selectedChargesNum, setSelectedChargesNum] = useState(0);

  useEffect(() => {
    const selectedCharges = props.charges.filter((charge) => 
         (code === 'ANY' || charge.code === code)
      && filterStatute(charge, statute)
      && filterExpired(charge, expired, unexpired)
      && filterLevel(charge, felony, misdemeanor, infraction, otherNone)
      && filterDescription(charge, fullDesc)
      && filterTemplateId(charge, template_id)
    ).sort( (a, b) =>  a.statute.toLowerCase() < b.statute.toLowerCase() ? -1 : 0);
    setSelectedChargesNum(selectedCharges.length);
    const displayedCharges = selectedCharges.slice(0, maxRows);
    props.setFilteredItems(displayedCharges);
    props.setFilterCriteria({ code, statute, expired, unexpired, felony, misdemeanor, infraction, otherNone, fullDesc, template_id});
  }, [code,
      statute,
      expired,
      unexpired,
      felony,
      misdemeanor,
      infraction,
      otherNone,
      fullDesc,
      template_id,
      maxRows,
      props.charges.length]);

  const loadedChargesNum = props.charges ? props.charges.length : 0;
  const displayedChargesNum = props.filteredItems ? props.filteredItems.length : 0;

  return <ChargeCodeFilterSet>
    <fieldset>
      <legend>Charge Code</legend>
      <CodeStatuteEntry>
        <label htmlFor="chargeCode">Code</label>
        <select type="select"
                id="chargeCode"
                value={code}
                onChange={e => setCode(e.target.value)} >
          <option value="ANY">Any Code</option>
          <option value="BP">BP (Business and Professions)</option>
          <option value="CC">CC (Corporations Code)</option>
          <option value="EC">EC (Education Code)</option>
          <option value="FA">FA (Food and Agriculture)</option>
          <option value="FC">FC (Financial Code)</option>
          <option value="FG">FG (Fish and Game)</option>
          <option value="GC">GC (Government Code)</option>
          <option value="HN">HN (Harbors and Navigation)</option>
          <option value="HS">HS (Health and Safety)</option>
          <option value="IC">IC (Insurance Code)</option>
          <option value="LC">LC (Labor Code)</option>
          <option value="PC">PC (Penal Code)</option>
          <option value="PR">PR (Public Resources)</option>
          <option value="PU">PU (Public Utilities)</option>
          <option value="RT">RT (Revenue and Tax)</option>
          <option value="UI">UI (Unemployment Insurance)</option>
          <option value="VC">VC (Vehicle Code)</option>
          <option value="WI">WI (Welfare and Institutions)</option>
        </select>
        <label htmlFor="statute">Statute</label>
        <input type="text"
               id="statute"
               value={statute}
               onChange={e => setStatute(e.target.value)} />
      </CodeStatuteEntry>
    </fieldset>

    <CheckboxSet>
      <legend>Expiration</legend>
      <div>
        <input type="checkbox"
                id="unexpired"
                checked={unexpired}
                onChange={e => { setUnexpired(e.target.checked); console.log('unexpired =', e.target.checked)}} />
        <label htmlFor="unexpired">Unexpired</label>
      </div>
      <div>
        <input type="checkbox"
                id="expired"
                checked={expired}
                onChange={e => setExpired(e.target.checked)} />
        <label htmlFor="expired">Expired</label>
      </div>
    </CheckboxSet>

    <CheckboxSet>
      <legend>Level</legend>
      <div className="checkboxGroup">
        <input type="checkbox"
              id="felony"
              checked={felony}
              onChange={e => setFelony(e.target.checked)} />
        <label>felony</label>
      </div>
      <div className="checkboxGroup">
        <input type="checkbox"
                id="misdemeanor"
                checked={misdemeanor}
                onChange={e => setMisdemeanor(e.target.checked)} />
        <label>misdemeanor</label>
      </div>
      <div className="checkboxGroup">
        <input type="checkbox"
                id="infraction"
                checked={infraction}
                onChange={e => setInfraction(e.target.checked)} />
        <label>infraction</label>
      </div>
      <div>
        <input type="checkbox"
                id="otherNone"
                checked={otherNone}
                onChange={e => setOtherNone(e.target.checked)} />
        <label>other/none</label>
      </div>
    </CheckboxSet>

    <fieldset>
      <legend>Other</legend>
      <OtherFilters>
        <label htmlFor="fullDesc">Full Description</label>
        <input type="text"
                id="fullDesc"
                value={fullDesc}
                onChange={e => setFullDesc(e.target.value)}/>
        <label htmlFor="template_id">Template ID</label>
        <input type="text"
                id="template_id"
                value={template_id}
                onChange={e => setTemplate_id(e.target.value)} />
      </OtherFilters>
    </fieldset>

    <fieldset>
      <legend>Dataset Info</legend>
      <div>
        <p>Charges Loaded:     <span style={{ float: 'right'}}>{numeral(loadedChargesNum).format('0,0')} </span></p>
        <p>Charges Selected:   <span style={{ float: 'right' }}> {numeral(selectedChargesNum).format('0,0')} </span></p>
        <p>Charges Displayed:  <span style={{ float: 'right' }}>{numeral(displayedChargesNum).format('0,0')} </span></p>
        <p><span>Max Rows Displayed:</span> 
          <MaxDisplayInput type="number" id="maxToDisplay" min="10" step="10" value={maxRows} onChange={e => setMaxRows(e.target.value)} />
        </p>
      </div>
    </fieldset>
  </ChargeCodeFilterSet>
}

const mapStateToProps = (state) => ({
  charges: state.charges.items,
  filteredItems: state.charges.filteredItems,
  ...state.charges.filterCriteria,
});

const mapDispatchToProps = { setFilteredItems, setFilterCriteria };

export default connect(mapStateToProps, mapDispatchToProps)(chargeCodeFilter);