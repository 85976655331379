import fetch from 'cross-fetch';

const apiVersionUri = '/api/build';
const authVersionUri = '/idp/build';

export const initialize = () => ({
  type: 'INITIALIZE'
});

const startFetching = () => ({ type: 'START_FETCHING' });
const finishFetching = () => ({ type: 'FINISH_FETCHING' });

const setApiVersion = (version) => ({
  type: 'SET_API_VERSION',
  version
});
const setAuthVersion = (version) => ({
  type: 'SET_AUTH_VERSION',
  version
});
const setError = (errorMessage) => ({
  type: 'SET_ERROR_MESSAGE',
  errorMessage
});

export const setWebVersion = (version) => ({
  type: 'SET_WEB_VERSION',
  version
});

export const startFetchApiVersion = (apihost, apikey) =>
      startFetchingVersionInfo(apiVersionUri, setApiVersion, apihost, apikey);

export const startFetchAuthVersion = (authhost, authkey) =>
      startFetchingVersionInfo(authVersionUri, setAuthVersion, authhost, authkey);

function startFetchingVersionInfo(uri, setAction, apihost, apikey) {
  const fetchUrl = apihost + uri;
  const fetchOptions = { method: 'GET' };
  fetchOptions['headers'] = {
    'Accept': 'application/json',
  }
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }
  return async (dispatch) => {
    dispatch(startFetching());
    try {
      console.time(`fetchVersion ${uri}`);
      const response = await fetch(fetchUrl, fetchOptions);
      console.timeEnd(`fetchVersion ${uri}`);
      console.log(`Version ${uri} GET status code = ${response.status}`);
      if (response.ok) {
        const results = await response.json();
        dispatch(finishFetching());
        dispatch(setAction(results));
      } else {
        dispatch(finishFetching());
        let errorMessage;
        switch (response.status) {
          case 401:
            errorMessage = "API key missing";
            break;
          case 404:
            errorMessage = `Invalid version URI: ${uri}`;
            break;
          default:
            errorMessage = "Version request returned HTTP status ${response.status}";
        }
        console.error(errorMessage);
        dispatch(setError(errorMessage));
      }
    } catch (err) {
      console.timeEnd(`fetchVersion ${uri}`);
      console.error('Fetch connection failure', err);
      dispatch(finishFetching());
      dispatch(setError(err.message));
    }
  };
}