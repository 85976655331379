import * as envOptions from './RemoteProps.json';

const typePrefix = 'API_'

// The default local host is used when running dev-server
// on a developer workstation.  In this case, only the
// JavaScript is running, not the full Node.js document root.
// So the AJIS call to download the API endpoint list will
// fail.
const defaultLocalEndpoint = {
  urlbase: "http://localhost:6010"
}

export const setCurrentHost = (apiHost) => ({
  type: typePrefix + 'SET_CURRENT_HOST',
  apiHost
})

export const setApiKey = (apiKey) => ({
  type: typePrefix + 'SET_API_KEY',
  apiKey
});

export const populateApiEndpoints = () => {
  const urlbaseProp = 'TABLES_URLBASE';
  const apikeyProp  = 'APIKEY';
  return (dispatch) => {
    let apiHost = {};
    if (urlbaseProp in envOptions) {
      const urlbase = envOptions[urlbaseProp];
      apiHost = { urlbase };
      console.log(`API base URL set to ${urlbase}`);
    } else {
      console.error(`Failed to determine API base URL from ${urlbaseProp}; defaulting to local`);
      apiHost = defaultLocalEndpoint;
    }
    if ('APIKEY' in envOptions) {
      const apikey = envOptions[apikeyProp];
      apiHost = { ...apiHost, apikey };
      console.log(`API key set`);
    } else {
      console.error(`Failed to determine API key from ${apikeyProp}`);
    }
    console.log(`Dispatching API endpoints ${JSON.stringify(apiHost)}`)
    dispatch(setCurrentHost(apiHost));
  };
}