import React from 'react';
import styled from 'styled-components';

  // width: ${props => Math.ceil(Math.max(0.6 * props.maxLength, 0.4 * props.label.length + 1))}em;
const Field = styled.div`
  && {
    margin-right: 5px;
  }`;
const Label = styled.label`
  && {
    display: block;
  }`;
const Input = styled.input`
  background: lightgrey;
  &:invalid {
    background: #f42300;
  }
  `;

// props:
//   - id of the input field
//   - label
//   - value
//   - onChange
export const TextField = props => {
//  const size = Math.ceil(Math.max(props.maxLength, 0.4 * props.label.length + 1));
  const maxLength = props.maxLength ? props.maxLength : -1;
  const size = maxLength + 1;
  return (
    <Field {...props}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
            type="text"
            maxLength={maxLength}
            size={size}
            required={props.required ? true : false}
            value={props.value}
            onChange={props.onChange}/>
    </Field>
  );
}

export const LongNameField = props => {
  const maxLength = props.maxLength ? props.maxLength : -1;
  return (
    <Field {...props}>
      <label htmlFor={props.id} style={{marginRight: '10px'}}>{props.label}</label>
      <Input id={props.id}
            type="text"
            maxLength={maxLength}
            required={props.required ? true : false}
            value={props.value}
            onChange={props.onChange}/>
    </Field>
  )
}

export const FlagField = props => {
  return (
    <Field {...props}>
      <Input id={props.id}
             type="text"
             maxLength="1"
             size="2"
             required={props.required ? true : false}
             value={props.value}
             onChange={props.onChange} />
      <label htmlFor={props.id} style={{marginLeft: '10px'}}>{props.label}</label>
    </Field>
  )
}

export const BcsField = props => {
  return (
    <Field {...props}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
            type="text"
            pattern="^\d\d\d"
            title="3 digits, lead with 0 if needed"
            maxLength="3"
            size="4"
            required={props.required ? true : false}
            value={props.value}
            onChange={props.onChange}/>
    </Field>
  )
}

export const ReadonlyField = props => {
  const maxLength = props.maxLength ? props.maxLength : -1;
  const size = maxLength + 1;
  return (
    <Field {...props}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
             type="text"
             maxLength={maxLength}
             size={size}
             value={props.value}
             readOnly />
    </Field>
  );
};

export const DateField = props => {
  return (
    <Field {...props} className={props.className}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input id={props.id}
            type="text"
            pattern="^\d\d\d\d-\d\d-\d\d$"
            required={props.required ? true : false}
            title="Date format yyyy-mm-dd"
            size="10"
            value={props.value}
            onChange={props.onChange}/>
    </Field>
  );
}

export const IntField = props => {
  return <Field {...props} className={props.className}>
    <Label htmlFor={props.id}>{props.label}</Label>
    <Input id={props.id}
           type="text"
           pattern="^\d{0,10}$"
           required={props.required ? true : false}
           maxLength={props.maxLength ? props.maxLength : -1}
           size={props.maxLength ? props.maxLength : -1}
           title="Numeric"
           value={props.value}
           onChange={props.onChange} />
  </Field>
}