import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setPasswordSelf, setError, setSuccess } from './CredentialActions';

const SetPasswordSelfDialog = styled.fieldset`
  display: ${props => props.token ? 'block' : 'none'};
`;
SetPasswordSelfDialog.displayName = "SetPasswordSelfDialog";

const setPasswordSelfDialog = (props) => {

  const [opw, setOpw] = useState('');
  const [npw, setNpw] = useState('');
  const [rpw, setRpw] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    setErrMsg('');
    props.setError('');
    props.setSuccess('');
  }, [opw, npw, rpw]);

  useEffect(() => {
    console.log("Running SetPasswordSelf error effect");
    if (props.error) {
      setErrMsg(props.error);
      return function cleanup() {
        setOpw('');
        setNpw('');
        setRpw('');
        setErrMsg('');
      }
    } 
  }, [props.error]);

  function submit() {
    console.log('opw = ', opw, 'npw = ', npw, 'rpw = ', rpw);
    if (rpw === npw) {
      console.log('New password verified');
      setOpw('');
      setNpw('');
      setRpw('');
      setErrMsg('');
      props.setPasswordSelf(props.claims.sub, opw, npw, props.host, props.token, props.apikey);
    } else {
      setErrMsg('New and repeated password do not match!');
    }
  }

  return (
    <SetPasswordSelfDialog token={props.token}>
      <legend>Change Password for {props.claims ? props.claims.sub : 'unknown'}</legend>
      <input type="password"
             placeholder="old password"
             autoFocus
             value={opw}
             onChange={e => setOpw(e.target.value)}
             />
      <input type="password"
             placeholder="enter new password"
             value={npw}
             onChange={e => setNpw(e.target.value)}
             />
      <input type="password"
             placeholder="verify new password"
             value={rpw}
             onChange={e => setRpw(e.target.value)}
             />
      <button type="button" onClick={submit}>Submit</button>
      <p>
        <span className="error">{errMsg}</span>
        <span className="success">{props.success}</span>
      </p>
    </SetPasswordSelfDialog>
  )
}

const mapStateToProps = (state) => ({
  error:   state.credentials.error,
  success: state.credentials.success,
  token:   state.credentials.token,
  claims:  state.credentials.claims,
  host:    state.authserver.urlbase,
  apikey:  state.authserver.apikey,
});

const mapDispatchToProps = { setPasswordSelf, setError, setSuccess };

export default connect(mapStateToProps, mapDispatchToProps)(setPasswordSelfDialog);