import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChargeCodeDashboard from './ChargeCodeDashboard';
import AddChargeCode       from './AddChargeCode';
import UpdateChargeCode    from './UpdateChargeCode';

const ChargeCodeRouter = () => (
    <div>
      <Routes>
        <Route path={'/dashboard'}  element={<ChargeCodeDashboard />} />
        <Route path={'/add'}        element={<AddChargeCode />} />
        <Route path={'/update/:id'} element={<UpdateChargeCode />} />
      </Routes>
    </div>
);

export default ChargeCodeRouter;