import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { json2csv } from 'json-2-csv';
import ChargeCodeFilter from './ChargeCodeFilter';
import ChargeList from './ChargeList';
import { startFetchingChargeCodes } from './ChargeCodeActions';
import MessageDisplay from '../ErrorHandling/MessageDisplay';
import { LoadingMessage, DownloadButton } from '../components/Loading';

export const ChargeCodeDashboard = props => {
  const navigate = useNavigate();
  const runEffectOnlyOnce = true;

  const [msTimeout, setMsTimeout] = useState(20000);

  // The runEffectOnlyOnce restricts the effect to running only
  // once per page load.  But even this is too much if the LEA
  // items have already been loaded from a previous page load
  // and there have been no changes.  Therefore, inside the
  // useEffect function, we verify that the number of items is
  // zero before issuing a request for a new set.
  //
  useEffect(() => {
    console.log('In ChargeCodeDashboard useEffect');
    if (props.charges.isFetching) {
      console.log("We're already fetching charge codes ...");
    } else {
      if (props.charges.items.length === 0) {
        console.log(`Fetching charge codes from ${props.apihost}.`)
        props.startFetchingChargeCodes(props.apihost, props.apikey, msTimeout);
      } else {
        console.log(`Skipping ChargeCodeDashboard fetch; already have ${props.charges.items.length} items.`);
      }
    }
  }, [runEffectOnlyOnce]);

  useEffect(() => {
    console.log('In ChargeCodeDashboard Fetching effect');
    const dlLink = document.querySelector('#downloadAllCSV');
    if (dlLink) {
      if (props.charges.isFetching) {
        dlLink.disabled = true;
        dlLink.hidden = true;
      } else {
        json2csv(props.charges.items, (err, csvText) => {
          if (err) {
            console.error(`CSV Conversion Error: ${err.message}`);
            dlLink.disabled = true;
            dlLink.hidden = true;
          } else {
            const csvDataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvText)}`;
            dlLink.disabled = false;
            dlLink.hidden = false;
            dlLink.href= csvDataUrl;
          }
        });
      }
    } else {
      console.error('ChargeCodeDashboard: CSV Download link not available in DOM.');
    }

  }, [props.charges.isFetching]);

  function reloadCharges() {
    console.log("Refetching charge codes.");
    let newTimeout = msTimeout;
    if (props.charges.items.length === 0) {
      console.log(`Increasing ChargeCode fetch timeout from ${newTimeout}`);
      newTimeout += 2000;
      setMsTimeout(newTimeout);
    }
    console.log(`Fetching charge codes with timeout ${newTimeout} ms`);
    props.startFetchingChargeCodes(props.apihost, props.apikey, newTimeout);
  }

  return (
    <div style={{marginTop: '20px'}}>
      <button onClick={() => navigate('/ChargeCode/add')} style={{margin: '0 5px', background: 'orange'}}>Add Charge</button>
      <button onClick={reloadCharges} style={{margin: "0 10px", background: 'lightgreen'}}>Refresh Charges from API</button>
      <DownloadButton id="downloadAllCSV" download="CJISChargeCode.csv">
        Download CSV
      </DownloadButton>
      <span style={{ display: 'inline-block', paddingLeft: '10px' }} >
        <MessageDisplay error={props.charges.errorMessage} success={props.charges.successMessage} />
      </span>
      <ChargeCodeFilter />
      {props.charges.isFetching ? <div>
                                    <LoadingMessage message={`Fetching charge codes with ${msTimeout/1000} second timeout.`}/>
                                  </div>
                                : <div>
                                    <ChargeList />
                                  </div>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  charges: state.charges,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey
});

const mapDispatchToProps = {
  startFetchingChargeCodes
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargeCodeDashboard);
