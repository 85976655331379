import styled from 'styled-components';

export const Name = styled.span`
  color: #563600;
  padding: 1px;
  margin-right: 5px;
  line-height: 1.5;
  font-family: arial-sans-serif;
  text-align: right;
`;
export const VarValue = styled.span`
  font-family: serif;
  letter-spacing: 1px;
  padding: 1px;
  line-height: 1.5;
`;
export const MonoValue = styled.pre`
  display: inline-block;
  font-family: consolas;
  margin: 0 0;
  padding: 1px;
  line-height: 1.5;
`;