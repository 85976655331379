import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { startSetToken, clearToken } from './CredentialActions';
import styles from 'styled-components';

const UserLogin = styles.div`
  & form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & input {
    width: 20em;
    height: 2em;
  }
`;
UserLogin.displayName = 'UserLogin';

const LoginButtons = styles.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
LoginButtons.displayName = "LoginButtons";

const loginForm = props => {

  const [id,    setId]    = useState('');
  const [pw,    setPw]    = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setError(props.error);
  })

  function login() {
    props.startSetToken(id, pw, props.host, props.apikey);
    console.log(`Login with id ${id}/${pw}.`);
    setPw('');
  }

  function logout() {
    props.clearToken();
    setId('');
    setPw('');
    setError('');
  }

  return (
    <UserLogin>
      <form>
        <input type="input"
                placeholder="user id"
                autoFocus
                value={id}
                onChange={e => setId(e.target.value)}
                />
        <input type="password"
                placeholder="password"
                value={pw}
                onChange={e => setPw(e.target.value)}
                />
        <LoginButtons>
          <button type="button" onClick={login}>Login</button>
          <button type="button" onClick={logout}>Logout</button>
        </LoginButtons>
      </form>
      <p>{error}</p>
    </UserLogin>
  )
}

const mapDispatchToProps = (dispatch) => ({
  startSetToken: (id, pw, host, apikey) => dispatch(startSetToken(id, pw, host, apikey)),
  clearToken: () => dispatch(clearToken())
});

const mapStateToProps = (state) => ({
  error: state.credentials.error,
  host: state.authserver.urlbase,
  apikey: state.authserver.apikey
});

export default connect(mapStateToProps, mapDispatchToProps)(loginForm);