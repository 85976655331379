import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { setCurrentRow } from './LeaActions';
import CellHover from '../components/CellHover';
import LeaDetailHover from './LeaDetailHover';

const LeaRow = styled.tr`
  &:nth-child(odd) {
    background: #ffeed5;
  }
  &:nth-child(even) {
    background: #ffdda9;
  }
  &:hover {
    background: #ddd;
  }
`;

const MonoField = styled.td`
  font-family: consolas, courier, mono;
  padding: 0 10px 0 5px;
`;
MonoField.displayName = 'MonoField';

const VarField = styled.td`
  font-family: garamond,"Times New Roman", serif;
  padding: 0 5px;
`;
VarField.displayName = 'VarField';

export const leaListRow = props => {

  let navigate = useNavigate();

  function handleSingleClick() {
    props.handleRowSelect(props.arrest_agency_code);
  }

  function handleDoubleClick() {
    navigate(`/lea/detail/${props.arrest_agency_code}`);
  }

  function datetimeToDate(isoDT) {
    const dt = DateTime.fromISO(isoDT);
    return dt.isValid ? dt.toISODate() : '';
  }

  return (
    <LeaRow onClick={handleSingleClick} onDoubleClick={handleDoubleClick}>
      <MonoField>{props.arrest_agency_code}</MonoField>
      <MonoField>{props.ncic_identification}</MonoField>
      <MonoField>{props.primary_lea}</MonoField>
      <VarField>{props.subpoena_service_type}</VarField>
      <CellHover style={{fontFamily: 'garamond,serif', padding: '0 5px'}}>
        {props.agency_name}
        <LeaDetailHover {...props} />
      </CellHover>
      <VarField>{props.city}</VarField>
      <VarField>{props.expired_flag}</VarField>
      <MonoField>{datetimeToDate(props.expiration_date)}</MonoField>
      <MonoField>{datetimeToDate(props.update_date)}</MonoField>
    </LeaRow>
  )
}

const mapDispatchToProps = dispatch => ({
  handleRowSelect: (id) => dispatch(setCurrentRow(id))
})

export default connect(null, mapDispatchToProps)(leaListRow);