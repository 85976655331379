import fetch from 'cross-fetch';
import jwt_decode from 'jwt-decode';

export const clearToken = () => ({ type: 'CLEAR_TOKEN' });

export const setToken = (token = '', error = '') => {
  const action = {
    type: 'SET_TOKEN',
    token,
    claims: {},
    error
  }
  if (token) {
    const credentials = jwt_decode(token);
    if (credentials) {
      action.claims = credentials;
    } else {
      action.error = 'Failed to decode token';
    }
  }
  if (!error && !token) {
    action.error = "ID/PW do not match";
  }
  return action;
}

export const setError = (error = '')     => ({ type: 'SET_ERROR_CREDENTIALS',   error });
export const setSuccess = (success = '') => ({ type: 'SET_SUCCESS_CREDENTIALS', success });

export const startSetToken = (id, pw, host, apikey) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id, pw }),
    mode: 'cors',
    timeout: 10000
  }
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }

  const loginUri = '/idp/login';
  const loginUrl = host + loginUri;
  console.log(`Login URL = ${loginUrl}.`);

  return (dispatch) => {
    return fetch(loginUrl, fetchOptions).then(response => {
      console.log(`Login HTTP status: ${response.status}`);
      if (response.ok) {
        return response.json();
      } else {
        return { error: response.status }
      }
    }).then((result) => {
      console.log(`Login response: ${JSON.stringify(result)}.`);
      dispatch(setToken(result.token));
    }).catch( (error) => {
      console.log(`Login error: ${error}.`);
      dispatch(setToken('', error.message || error.toString()));
    })
  }
}

const createSetPasswordDispatcher = (pwUrl, fetchOptions) => {
  return (dispatch) => {
    return fetch(pwUrl, fetchOptions).then(response => {
      console.log('SetPassword HTTP status:', response.status);
      const reqId = response.headers.get('x-cjisauth-requestid');
      console.log(`SetPassword PUT status code: ${response.status}; IDP-Request ID: ${reqId}`);
      if (response.ok) {
        return { error: ''};
      } else {
        switch (response.status) {
          case 400: return response.json();
          case 401: return { error: 'Failed to authenticate password change request.'};
          case 403: return { error: 'Accounts lacks permission to change password.'};
          default:  return { error: `API received status code ${response.status}` };
        }
      }
    }).then((result) => {
      if (!result.error) {
        console.log('Password change successful.');
        dispatch(setSuccess('Password change successful'));
      } else {
        console.log('Error returned', JSON.stringify(result));
        dispatch(setError(result.error));
      }
    }).catch((error) => {
      console.error('SetPassword error', )
      dispatch(setError(error.message));
    })
  }
}

export const setPasswordSelf = (enumber, opw, npw, host, token, apikey) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({enumber, opw, npw}),
    mode: 'cors',
    timeout: 3000
  };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }

  const pwUri = '/idp/profile/pw/self';
  const pwUrl = host + pwUri;
  console.log('SetPassword URL =', pwUrl);

  return createSetPasswordDispatcher(pwUrl, fetchOptions);
};

export const setPasswordAdmin = (enumber, pw, host, token, apikey) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({enumber, pw}),
    mode: 'cors',
    timeout: 2000,
  };
  if (apikey) {
    fetchOptions.headers['x-api-key'] = apikey;
  }

  const pwUri = '/idp/profile/pw/admin';
  const pwUrl = host + pwUri;
  console.log('SetPassword URL = ', pwUrl);

  return createSetPasswordDispatcher(pwUrl, fetchOptions);
}