const defaultInitialState = {
  isFetching: false,
  errorMessage: '',
  webVersion: null,
  apiVersion: null,
  authVersion: null,
};

export default (state = defaultInitialState, action) => {
  switch (action.type) {
    case 'INITIALIZE': 
      console.log('Initializing version information to defaultInitialState.');
      return defaultInitialState;

    case 'START_FETCHING':
      return { ...state, isFetching: true };
    
    case 'FINISH_FETCHING':
      return { ...state, isFetching: false };
    
    case 'SET_WEB_VERSION':
      if (!action.version) {
        console.warn('Attempting to set Web Version info to ', action.version);
      }
      return { ...state, webVersion: action.version };
    
    case 'SET_API_VERSION':
      if (!action.version) {
        console.warn('Attempting to set API Version info to ', action.version);
      }
      return { ...state, apiVersion: action.version };
    
    case 'SET_AUTH_VERSION':
      if (!action.version) {
        console.warn('Attempting to set Auth Version info to ', action.version);
      }
      return { ...state, authVersion: action.version }; 

    case 'SET_ERROR_MESSAGE':
      return {...state, errorMessage: (action.message ? action.message : '')};
    
    default:
      console.log('Version default reducer ran with action.type=', action.type);
      return state;
  }
}