const initialDisplayFilter = {
  deptFilter: '',
  judDistrictFilter: '',
  courtTypeFilter: '',
  descFilter: '',
  addressFilter: '',
  cityFilter: '',
  notExpFilter: true,
  expiredFilter: false,
};

const defaultInitialState = {
  isFetching: false,
  errorMessage: '',
  successMessage: '',
  items: [],
  filterCriteria: initialDisplayFilter,
  filteredItems: [],
  unauthorizedFields: [],
}

export default (state = defaultInitialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      if (action.dept) {
        console.log('Court Dept INITIALIZE called with action.dept.items.length = ', action.dept.items.length);
        state = action.dept;
      } else {
        console.log('Court Dept INITIALIZE called with no action.dept');
      }
      console.log(`Court Dept returning with ${state.items.length} items`);
      return state;

    case 'START_FETCHING':
      return { ...state, isFetching: true };
    
    case 'FINISH_FETCHING':
      return { ...state, isFetching: false };
    
    case 'UPDATE_COURT_DEPT_FETCH_RESULTS':
      return { ...state,
               errorMessage: action.errorMessage,
               successMessage: action.successMessage,
               items: action.items,
             };
    
    case 'UPDATE_COURT_DEPT':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: state.items.map(dept => ( dept.court_type === action.update.court_type &&
                                                dept.judicial_district === action.update.judicial_district &&
                                                dept.department === action.update.department )
                                            ? { ...dept, ...action.update }
                                            : dept)
             };
    case 'ADD_COURT_DEPT':
      return { ...state,
               isFetching: false,
               errorMessage: '',
               successMessage: action.successMessage,
               items: [...state.items, action.newDept]
             };
    
    case 'SET_COURT_DEPT_DISPLAY_FILTER_CRITERIA':
      return { ...state,
               filterCriteria: action.filterCriteria };

    case 'SET_FILTERED_COURT_DEPT':
      return { ...state,
               filteredItems: action.filteredItems }
    
    case 'SET_ERROR_MESSAGE':
      return { ...state,
               errorMessage: action.errorMessage };

    case 'CLEAR_MESSAGES':
      return { ...state,
               errorMessage: '',
               successMessage: '',
               unauthorizedFields: []
             }

    case 'UNAUTHORIZED_FIELDS':
      return { ...state,
               unauthorizedFields: action.unauthorizedFields };

    default:
      console.log('Court Dept default reducer just ran with action.type = ', action.type);
      return state;
  }
}