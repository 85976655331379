import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import CredentialList from './CredentialList';
import SetPassword from './SetPasswordSelf';
import { populateApiEndpoints,
         setCurrentHost,
         setApiKey } from '../HostSelector/AuthServerActions';
import styles from 'styled-components';

const CredentialDashboard = styles.div`
  margin-left: 10px;
  display: grid;
  grid-template-columns: 16em 25em;
  grid-column-gap: 30px;

  & h2 {
    margin-top: 0;
  }
`;
CredentialDashboard.displayName = "CredentialDashboard";

const LeftPanel = styles.div`
  display: grid;
  grid-template-rows: 120px auto auto;
  grid-row-gap: 20px;
  margin-top: 10px;
`
LeftPanel.displayName = 'CredentialsLeftPanel';

const CredentialButtons = styles.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
`;
CredentialButtons.displayName = 'CredentialButtons';


const credentialDashboard = (props) => {

  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const executeThisOnce = true;
  useEffect(() => {
    console.log(`Credential dashboard fetching Auth API endpoints. Current host = ${window.location.hostname}`);
    props.populateApiEndpoints();
  }, [executeThisOnce]);

  useEffect(() => {
    if (props.token) {
      setShowPasswordButton(true);
    } else {
      setShowPasswordButton(false);
    }
  }, [props.token]);

  return <CredentialDashboard>
    <LeftPanel>
      <LoginForm/>
    </LeftPanel>
    <div>
      <CredentialList />
      {showPasswordForm && <SetPassword/>}
      {!showPasswordForm && showPasswordButton &&
        <CredentialButtons>
          <button type="button" onClick={() => setShowPasswordForm(true)}>
            Change Password
          </button>
        </CredentialButtons>
      }
    </div>
  </CredentialDashboard>
};

const mapStateToProps = (state) => ({
  host: state.authserver,
  title: 'Authorization',
  token: state.credentials.token,
});

const mapDispatchToProps = { populateApiEndpoints,
                             setCurrentHost,
                             setApiKey
                           };

export default connect(mapStateToProps, mapDispatchToProps)(credentialDashboard);