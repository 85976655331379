import React, { useState, useEffect } from 'react';
import { sprintf } from 'sprintf-js';
import { TextField, DateField, IntField,
         FlagField, BcsField } from './ChargeCodeField';
import styled from 'styled-components';

const ChargeCodePage = styled.div`
  margin-left: 10px;
`;
ChargeCodePage.displayName = 'ChargeCodePage';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
  }
  
  & > p {
    display: inline;
  }
`;
ButtonRow.displayName = 'ButtonRow';

const FormLayout = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;
FormLayout.displayName = 'FormatLayout';

const FieldRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0px;
`
FieldRow.displayName = 'FieldRow';

const FlagSet = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 15px;
`
FlagSet.displayName = 'FlagSet';

const LongNameSet = styled.div`
  display: grid;
  grid-template-columns: 9em 6em;
  row-gap: 5px;

  label {
    text-align: right;
    margin-right: 5px;
  }

  input {
    background: #e8e8e8;
  }
`;
LongNameSet.displayName = 'LongNameSet';

const LongValueSet = styled.div`
  display: grid;
  grid-template-columns: 6em 16em;
  row-gap: 10px;
  align-items: center;

  label {
    text-align: right;
    margin-right: 10px;
  }

  input {
    background: #e8e8e8;
  }

  p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    background: #e8e8e8;
    font-family: consolas, mono;
    padding: 6px 2px 4px;
  }
`;
LongValueSet.displayName = 'LongValueSet';

export default function ChargeCodeForm(props) {

  let charge = props.charge || {};
  const [id,             setId]             = useState(charge.id         || 0);
  const [code,           setCode]           = useState(charge.code       || '');
  const [statute,        setStatute]        = useState(charge.statute    || '');
  const [literal_id,     setLiteral_id]     = useState(charge.literal_id || '');
  const [degree,         setDegree]         = useState(charge.degree     || '');
  const [offense_level,  setOffense_level]  = useState(charge.offense_level || '');
  const [full_description, setFull_description] = useState(charge.full_description || '');
  const [short_description, setShort_description] = useState(charge.short_description || '');
  const [classification_code, setClassification_code] = useState(charge.classification_code || '');
  const [bcs_class_code, setBcs_class_code] = useState(charge.bcs_class_code || '');
  const [reduce_flag,    setReduce_flag]    = useState(charge.reduce_flag    || '');
  const [violent_flag,   setViolent_flag]   = useState(charge.violent_flag   || '');
  const [register_flag,  setRegister_flag]  = useState(charge.register_flag  || '');
  const [destruct_flag,  setDestruct_flag]  = useState(charge.destruct_flag  || '');
  const [reporting_district, setReporting_district] = useState(charge.reporting_district || '');
  const [bail_amount,    setBail_amount]    = useState(charge.bail_amount || '');
  const [bcs_hierarchy,  setBcs_hierarchy]  = useState(charge.bcs_hierarchy || '');
  const [eff_date,       setEff_date]       = useState(charge.eff_date    || '');
  const [exp_date,       setExp_date]       = useState(charge.exp_date    || '');
  const [reason,         setReason]         = useState(charge.reason      || '');
  const [composite_key,  setComposite_key]  = useState(charge.composite_key || '');
  const [alt_composite_key, setAlt_composite_key] = useState(charge.alt_composite_key || '');
  const [state_cjis_code, setState_cjis_code] = useState(charge.state_cjis_code || '');
  const [custody_code,   setCustody_code]   = useState(charge.custody_code || '');
  const [sentence_range, setSentence_range] = useState(charge.sentence_range || '');
  const [template_id,    setTemplate_id]    = useState(charge.template_id || '');

  const [canceldone,   setCanceldone]   = useState('Back');
  const [origCharge,   setOrigCharge]   = useState(new Map());
  const [diffCharge,   setDiffCharge]   = useState(new Map());
  const [fieldsValid,  setFieldsValid]  = useState(true);
  const [dupComposite, setDupComposite] = useState(false);

  // Help user avoid submitting a duplicate composite_key.
  // Existing Set added to props.composite_keys.
  //
  function checkDupComposite() {
    const newComposite = sprintf('%2.2s%-18.18s%-3.3s%1.1s%1.1s%-8.8s',
                                 code, statute, literal_id, degree, offense_level, exp_date.replace(/-/g, ''));
    const isDup = props.composite_keys.has(newComposite);
    setDupComposite(isDup);
  }

  useEffect(() => {
    if (origCharge.has('id')) {
      const changes = new Map();
      if (origCharge.get('id')           !== id)           changes.set('id', id);
      if (origCharge.get('code')         !== code)         changes.set('code', code);
      if (origCharge.get('statute')      !== statute)      changes.set('statute', statute);
      if (origCharge.get('literal_id')   !== literal_id)   changes.set('literal_id', literal_id);
      if (origCharge.get('degree')       !== degree)       changes.set('degree', degree);
      if (origCharge.get('offense_level') !== offense_level) changes.set('offense_level', offense_level);
      if (origCharge.get('full_description') !== full_description) changes.set('full_description', full_description);
      if (origCharge.get('short_description') !== short_description) changes.set('short_description', short_description);
      if (origCharge.get('classification_code') !== classification_code) changes.set('classification_code', classification_code);
      if (origCharge.get('bcs_class_code') !== bcs_class_code) changes.set('bcs_class_code', bcs_class_code);
      if (origCharge.get('reduce_flag')   !== reduce_flag)   changes.set('reduce_flag', reduce_flag);
      if (origCharge.get('violent_flag')  !== violent_flag)  changes.set('violent_flag', violent_flag);
      if (origCharge.get('register_flag') !== register_flag) changes.set('register_flag', register_flag);
      if (origCharge.get('destruct_flag') !== destruct_flag) changes.set('destruct_flag', destruct_flag);
      if (origCharge.get('reporting_district') !== reporting_district) changes.set('reporting_district', reporting_district);
      if (origCharge.get('bail_amount')   !== bail_amount)   changes.set('bail_amount', bail_amount);
      if (origCharge.get('bcs_hierarchy') !== bcs_hierarchy) changes.set('bcs_hierarchy', bcs_hierarchy);
      if (origCharge.get('eff_date')      !== eff_date)      changes.set('eff_date', eff_date);
      if (origCharge.get('exp_date')      !== exp_date)      changes.set('exp_date', exp_date);
      if (origCharge.get('reason')        !== reason)        changes.set('reason', reason);
      if (origCharge.get('state_cjis_code') !== state_cjis_code) changes.set('state_cjis_code', state_cjis_code);
      if (origCharge.get('custody_code')  !== custody_code)  changes.set('custody_code', custody_code);
      if (origCharge.get('sentence_range') !== sentence_range) changes.set('sentence_range', sentence_range);
      if (origCharge.get('template_id')   !== template_id)   changes.set('template_id', template_id);
      setDiffCharge(changes);
    } else {
      const original = new Map();
      original.set('id',                charge.id             || '');
      original.set('code',              charge.code           || '');
      original.set('statute',           charge.statute        || '');
      original.set('literal_id',        charge.literal_id     || '');
      original.set('degree',            charge.degree         || '');
      original.set('offense_level',     charge.offense_level  || '');
      original.set('full_description',  charge.full_description || '');
      original.set('short_description', charge.short_description || '');
      original.set('classification_code', charge.classification_code || '');
      original.set('bcs_class_code',    charge.bcs_class_code || '');
      original.set('reduce_flag',       charge.reduce_flag    || '');
      original.set('violent_flag',      charge.violent_flag   || '');
      original.set('register_flag',     charge.register_flag  || '');
      original.set('destruct_flag',     charge.destruct_flag  || '');
      original.set('reporting_district', charge.reporting_district || '');
      original.set('bail_amount',       charge.bail_amount    || '');
      original.set('bcs_hierarchy',     charge.bcs_hierarchy  || '');
      original.set('eff_date',          charge.eff_date       || '');
      original.set('exp_date',          charge.exp_date       || '');
      original.set('reason',            charge.reason         || '');
      original.set('state_cjis_code',   charge.state_cjis_code || '');
      original.set('custody_code',      charge.custody_code   || '');
      original.set('sentence_range',    charge.sentence_range || '');
      original.set('template_id',       charge.template_id    || '');
      setOrigCharge(original);
    }
    props.composite_keys && checkDupComposite();
    const invalidElements = document.querySelectorAll('form :invalid');
    setFieldsValid(invalidElements.length === 0);
  }, [id, code, statute, literal_id, degree, offense_level, full_description, short_description,
      classification_code, bcs_class_code, reduce_flag, violent_flag, register_flag, destruct_flag,
      reporting_district, bail_amount, bcs_hierarchy, eff_date, exp_date, reason, composite_key,
      alt_composite_key, state_cjis_code, custody_code, sentence_range, template_id]);

  // Disable submit buttons if success has occcured.
  // Clear error/success messages on render clean-up.
  //
  useEffect(() => {
    const submitButtons = document.getElementsByClassName('submit');
    if (submitButtons.length) {
      for (const button of submitButtons) {
        button.disabled = !!props.successMsg || !fieldsValid || dupComposite;
      }
    }
    return () => {
      if (props.errMsg || props.successMsg) {
        props.clearFailed();
      }
    }
  }, [props.errMsg, props.successMsg, fieldsValid, dupComposite]);
  
  function onClear(evt) { 
    evt.preventDefault();
    setId(0);
    setCode('');
    setStatute('');
    setLiteral_id('');
    setDegree('');
    setOffense_level('');
    setFull_description('');
    setShort_description('');
    setClassification_code('');
    setBcs_class_code('');
    setReduce_flag('');
    setViolent_flag('');
    setRegister_flag('');
    setDestruct_flag('');
    setReporting_district('');
    setBail_amount('');
    setBcs_hierarchy('');
    setEff_date('');
    setExp_date('');
    setReason('');
    setComposite_key('');
    setAlt_composite_key('');
    setState_cjis_code('');
    setCustody_code('');
    setSentence_range('');
    setTemplate_id('');
    props.clearFailed(); // Clear any fail messages.
  }

  function onSubmit(evt) {
    evt.preventDefault();
    const modifiedChargeValues = getChangedSubset();
    setCanceldone('Done');
    props.onSubmit(modifiedChargeValues);
  }

  // Return subset of state that has changed since the last
  // snapshot.  Include ID when non-zero for identity purposes.
  //
  function getChangedSubset() {
    const result = {};
    diffCharge.forEach((value, field) => result[field] = value);
    if ( id !== 0) {
      result.id = id;
    }
    console.log(`Changed state: ${JSON.stringify(result)}.`);
    return result;
  }

  return <ChargeCodePage>
    <p className="error">{props.errMsg}</p>
    <p className="success">{props.successMsg}</p>
    <div className="row">
      <FormLayout>
        <ButtonRow>
          <button className="btn submit" onClick={onSubmit}>Submit</button>
          <button className="btn orange" onClick={props.onCancel}>{canceldone}</button>
          <button className="btn red" onClick={onClear}>Clear</button>
          <span style={{'fontStyle': 'italic'}}>ID:</span>
          <span style={{'fontWeight': 'bold'}}>{id},</span>&nbsp;
          <span style={{'fontStyle': 'italic'}}>Last Updated:</span>
          <span>{charge.last_updated || 'New'}</span>
          <p className="error">{fieldsValid ? '' : ' – Red fields require valid input'}</p>
          <p className="error">{dupComposite ? ' – Entry conflicts with existing composite_key' : ''}</p>
        </ButtonRow>
        <FieldRow>
          <TextField id="code"          label="Code"          required maxLength={2}  value={code}    onChange={e => setCode(e.target.value)} />
          <TextField id="statute"       label="Statute"       required maxLength={18} value={statute} onChange={e => setStatute(e.target.value)} />
          <TextField id="offense_level" label="Offense Level" required maxLength={1}  value={offense_level} onChange={e => setOffense_level(e.target.value)} />
          <TextField id="literal_id"    label="Literal ID"    maxLength={3} value={literal_id} onChange={e => setLiteral_id(e.target.value)} />
          <TextField id="degree"        label="Degree"        maxLength={1} value={degree}     onChange={e => setDegree(e.target.value)} />
          <IntField  id="template_id"   label="Template ID"   required maxLength={10} value={template_id} onChange={e => setTemplate_id(e.target.value)} />
          <DateField id="eff_date"      label="Effective Date"  required value={eff_date}   onChange={e => setEff_date(e.target.value)} />
          <DateField id="exp_date"      label="Expiration Date" required value={exp_date}   onChange={e => setExp_date(e.target.value)} />
        </FieldRow>
        <FieldRow>
          <TextField id="full_description" label="Full Description" required maxLength={60} value={full_description} onChange={e => setFull_description(e.target.value)} />
          <TextField id="short_description" label="Short Description" maxLength={25} value={short_description} onChange={e => setShort_description(e.target.value)} />
          <BcsField  id="bcs_class_code"    label="BCS Code"  maxLength={3}  value={bcs_class_code}  onChange={e => setBcs_class_code(e.target.value)} />
          <IntField  id="bcs_hierarchy"     label="Hierarchy"   maxLength={8} value={bcs_hierarchy}   onChange={e => setBcs_hierarchy(e.target.value)} />
        </FieldRow>
        <FieldRow>
          <fieldset>
            <legend>Flags</legend>
            <FlagSet>
              <FlagField id="reduce_flag"   label="Reduce"   maxLength={1} value={reduce_flag}   onChange={e => setReduce_flag(e.target.value)} />
              <FlagField id="violent_flag"  label="Violent"  maxLength={1} value={violent_flag}  onChange={e => setViolent_flag(e.target.value)} />
              <FlagField id="register_flag" label="Register" maxLength={1} value={register_flag} onChange={e => setRegister_flag(e.target.value)} />
              <FlagField id="destruct_flag" label="Destruct" maxLength={1} value={destruct_flag} onChange={e => setDestruct_flag(e.target.value)} />
            </FlagSet>
          </fieldset>
          <fieldset>
            <legend>Miscellaneous</legend>
            <LongNameSet>
              <label htmlFor="classification_code">Classification Code</label>
              <input id="classification_code" type="text" maxLength={3} value={classification_code} onChange={e => setClassification_code(e.target.value)} />
              <label htmlFor="custody_code">Custody Code</label>
              <input id="custody_code" type="text" maxLength={1} value={custody_code} onChange={e => setCustody_code(e.target.value)} />
              <label htmlFor="state_cjis_code">State CJIS Code</label>
              <input id="state_cjis_code" type="text" maxLength={8} value={state_cjis_code} onChange={e => setState_cjis_code(e.target.value)} />
              <label htmlFor="sentence_range">Sentencing Code</label>
              <input id="sentence_range" type="text" maxLength={12} value={sentence_range} onChange={e => setSentence_range(e.target.value)} />
              <label htmlFor="bail_amount">Bail Amount</label>
              <input id="bail_amount" type="text" maxLength={9} value={bail_amount} onChange={e => setBail_amount(e.target.value)} />
              <label htmlFor="reporting_district">Reporting District</label>
              <input id="reporting_district" type="text" maxLength={2} value={reporting_district} onChange={e => setReporting_district(e.target.value)} />
            </LongNameSet>
          </fieldset>
          <fieldset className="longvalues">
            <legend>Long Values</legend>
            <LongValueSet>
              <label htmlFor="reason">Reason</label>
              <input id="reason" type="text" maxLength={40} value={reason} onChange={e => setReason(e.target.value)} />
              <label htmlFor="composite_key">Composite Key</label>
              <p id="composite_key">{composite_key}</p>
              <label htmlFor="alt_composite_key">Alt Composite Key</label>
              <p id="alt_composite_key">{alt_composite_key}</p>
            </LongValueSet>
          </fieldset>
        </FieldRow>
        <ButtonRow>
          <button className="btn submit" onClick={onSubmit}>Submit</button>
          <button className="btn orange" onClick={props.onCancel}>{canceldone}</button>
          <button className="btn red" onClick={onClear}>Clear</button>
        </ButtonRow>
      </FormLayout>
    </div>
  </ChargeCodePage>
}