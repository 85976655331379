import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ListFilter from './CourtDeptFilter';
import CourtDeptList from './CourtDeptList';
import { startFetchingCourtDepts, clearMessages } from './CourtDeptAction';
import MessageDisplay from '../ErrorHandling/MessageDisplay';
import { LoadingMessage, DownloadButton } from '../components/Loading';
import { json2csv } from 'json-2-csv';

const ButtonRow = styled.div`
  & > * {
    margin-right: 5px;
  }
`;
ButtonRow.displayName = 'ButtonRow';

const Dashboard = styled.div`
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
Dashboard.display = 'Dashboard';

const CourtDeptDashboard = props => {
  const navigate = useNavigate();
  const runEffectOnlyOnce = true;

  const [msTimeout, setMsTimeout] = useState(16000);

  // The runEffectOnlyOnce restricts the effect to running only
  // per page load.  But even this is too much of the once Court
  // Department items have already been loaded from a previous
  // page load and there are no changes, because a page load
  // happens every time the tab is selected.  Therefore, inside
  // the useEffect function, we verify that the number of items
  // is zero before issuing a request for a new set.
  //
  useEffect(() => {
    console.log('In Court Department useEffect');
    if (props.courtdept.items.length === 0) {
      console.log('No court departments found on page load; start API fetch');
      props.startFetchingCourtDepts(props.apihost, props.apikey);
    } else {
      console.log(`Skipping Court Department dashboard fetch; already have ${props.courtdept.items.length} items`);
    }
    return () => {
      console.log('Running Court Department useEffect cleanup');
      props.clearMessages();
    }
  }, [runEffectOnlyOnce]);

  useEffect(() => {
    console.log('In Court Department dashboard fetching effect');
    const dlLink = document.querySelector('#downloadAllCSV');
    if (dlLink) {
      if (props.courtdept.isFetching) {
        dlLink.disabled = true;
        dlLink.hidden = true;
      } else {
        json2csv(props.courtdept.items, (err, csvText) => {
          if (err) {
            console.error(`CSV conversion error: ${err.message}`);
            dlLink.disable = true;
            dlLink.hidden = true;
          } else {
            const csvDataUrl = `data:text/csv;charset=utf-8,${encodeURIComponent(csvText)}`
            dlLink.disabled = false;
            dlLink.hidden = false;
            dlLink.href = csvDataUrl;
          }
        });
      }
    } else {
      console.error('Court Department Dashboard: CSV download link not available in DOM');
    }
  }, [props.courtdept.isFetching]);

  function reloadCourtDept() {
    let newTimeout = msTimeout + 2000;
    console.log(`Refetching Court Departments with timeout ${newTimeout} ms`);
    setMsTimeout(newTimeout);
    props.startFetchingCourtDepts(props.apihost, props.apikey, newTimeout);
  }

  return <Dashboard>
    <ButtonRow>
      <button style={{background: 'darkseagreen'}} onClick={reloadCourtDept}>Refresh from API</button>
      <button style={{background: 'sandybrown'}} onClick={() => navigate('/CourtDepartment/add')}>Add Court Department</button>
      <DownloadButton id="downloadAllCSV" download="CourtDept.csv">Download CSV</DownloadButton>
      <span className={'message-box'}>
        <MessageDisplay error={props.courtdept.errorMessage} success={props.courtdept.successMessage} />
      </span>
    </ButtonRow>
    <ListFilter />
    {props.courtdept.isFetching ? <LoadingMessage message={`Fetching Court Departments with ${msTimeout/1000} second timeout`} />
                                : <CourtDeptList />
    }
  </Dashboard>
}

const mapStateToProps = state => ({
  courtdept: state.courtdept,
  apihost: state.apiserver.urlbase,
  apikey: state.apiserver.apikey,
});

const mapDispatchToProps = {
  startFetchingCourtDepts,
  clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourtDeptDashboard);