import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`
export const LoadingSpinner = styled.div`
  display: inline-block;
  width: 120px;
  height: 120px;

  &::after {
    content: " ";
    display: block;
    width:  100px;
    height: 100px;
    margin: 10px;
    border-radius: 50%;
    border: 12px solid #f00;
    border-color: #0f0 transparent #00f transparent;
    animation: ${rotate} 2s linear infinite;
  }
`

const Message = styled.span`
  position: absolute;
  top: 40px;
  display: inline-block;
  margin: auto 30px;
`

const SpinnerBox = styled.div`
  position: relative;
`;

export const LoadingMessage = props => <SpinnerBox>
  <LoadingSpinner /> <Message>{props.message}</Message>
</SpinnerBox>

export const DownloadButton = styled.a`
  border: solid 2px black;
  border-radius: 2px;
  text-decoration: none;
  color: black;
  background: skyblue;
  margin-right: 5px;
  padding: 1px;

  &:hover {
    background: #ffdda9;
  }

  &:active {
    background: #f3a000;
  }
`;