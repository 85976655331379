import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { startFetchingChargeCodes } from '../ChargeCode/ChargeCodeActions';
import { setCurrentRow, 
         startFetchingConversions,
         startAddConversion,
         startDeleteConversion,
         clearFailed } from './ChargeConversionActions';


const chargeConversionForm = props => {

  const [source, setSource]         = useState("JAI");
  const [cjis, setCjis]             = useState("");
  const [external, setExternal]     = useState("");

  const [addDisabled,    setAddDisabled]    = useState(true);
  const [deleteDisabled, setDeleteDisabled] = useState(true);


  useEffect(() => {
    setDeleteDisabled(!props.currentRow);
    setAddDisabled(!!props.currentRow);
    return () => {
      if (props.errorMsg || props.successMsg) {
        props.clearFailed();
        console.log('Clear charge conversion messages');
      } else {
        console.log('No charge conversion messages to clear');
      }
    }
  })

  function handleCjisSelect(e) {
    console.log(`Selected CJIS Code: ${e.target.innerHTML}`);
    setCjis(e.target.innerHTML);
    setDeleteDisabled(true);
    props.unsetCurrentRow();
  }

  function handleExternalSelect(e) {
    console.log(`Selected External Code: ${e.target.innerHTML}`);
    setExternal(e.target.innerHTML);
    setDeleteDisabled(true);
    setAddDisabled(true);
    props.unsetCurrentRow();
  }

  function refreshChargeCodes() {
    props.startFetchingChargeCodes(props.apihost, props.apikey);
  }

  function refreshChargeConversions() {
    props.startFetchingChargeConversions(props.apihost, props.apikey);
  }

  function onAddClick() {
    console.log('Add button was clicked.');
    props.startAddConversion(props.apihost, props.apikey, props.token, source, cjis, external);
    setAddDisabled(true);
  }

  function onDeleteClick() {
    console.log('Delete button was clicked');
    props.startDeleteConversion(props.apihost, props.apikey, props.token, props.currentRow);
    setDeleteDisabled(true);
  }

  return (
    <div>
      <div id="messages">
        <p className="error">{props.errorMsg}</p>
        <p className="success">{props.successMsg}</p>
      </div>
      <ChangeButtons>
        <button id="addbutton"     onClick={onAddClick}    disabled={addDisabled}>Add</button>
        <button id="deletebutton"  onClick={onDeleteClick} disabled={deleteDisabled}>Delete</button>
        <button id="reloadcharges" onClick={refreshChargeCodes}>Reload Charge Codes</button>
        <button id="reloadconversions" onClick={refreshChargeConversions}>Reload Charge Conversions</button>
      </ChangeButtons>
      <ConversionForm>
        <fieldset id="choosesource">
          <legend>Source System</legend>
          <div>
            <SourceSelect id="source" size="5"
                    value={props.currentRow ? props.currentRow.source : source}
                    onChange={e => setSource(e.target.value)}>
              <option value="JAI">JAI</option>
              <option value="AJIS">AJIS</option>
              <option value="TCIS">TCIS</option>
              <option value="DA">DA</option>
              <option value="PIMS">PIMS</option>
            </SourceSelect>
          </div>
        </fieldset>
        <fieldset id="choosecjis">
          <legend>CJIS Code</legend>
          <CodeLabel htmlFor="cjis">Code</CodeLabel>
          <input type="text" id="cjis" 
                             value={props.currentRow ? props.currentRow.cjis : cjis}
                             onChange={e => setCjis(e.target.value)}
                             onClick={e => { setCjis(e.target.value); props.unsetCurrentRow()}} />
          <p>Existing CJIS Charges</p>
          <ChargeList id="cjiscodes">
            {props.charges.filter(chg => chg.toLowerCase().startsWith(cjis.toLowerCase()))
                  .map((chg, index) => ( <li key={chg + index} onClick={handleCjisSelect}>{chg}</li>))}
          </ChargeList>
        </fieldset>
        <fieldset id="chooseexternal">
          <legend>External Code</legend>
          <CodeLabel htmlFor="external">Code</CodeLabel>
          <input type="text" id="external"
                             value={props.currentRow ? props.currentRow.external : external}
                             onChange={e => { setExternal(e.target.value); setAddDisabled(true)} }
                             onClick={e => {  setExternal(e.target.value); props.unsetCurrentRow()}} />
          <p>Existing External Charges</p>
          <ChargeList id="externalcodes">
            {props.conversions.filter(conv => conv.source === source && conv.cjis.startsWith(cjis))
                  .map((conv, index) => ( <li key={conv.external + index} onClick={handleExternalSelect}>{conv.external}</li>))}
          </ChargeList>
        </fieldset>
      </ConversionForm>
    </div>
  );
}

const ConversionForm = styled.form`
  display: flex;
  justify-content: space-between;
`;

const ChangeButtons = styled.div`
  display: flex;
  justify-content: space-between;

  & button {
    margin: 10px 0px;
  }
`;

const SourceSelect = styled.select`
  width: 100%;
`;

const CodeLabel = styled.label`
  margin-right: 5px;
`;

const ChargeList = styled.ul`
  list-style-type: none;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0px;
  border: 1px solid #ddd;

  & li {
    padding: 4px;
    border: 1px solid #ddd;

    &:hover {
      background-color: #ddd;
    }
  }
`;


const mapStateToProps = (state) => ({
  charges: state.charges.items.map(chg => `${chg.code}${chg.statute}`).sort(),
  currentRow:  state.conversions.currentRow,
  conversions: state.conversions.items,
  errorMsg:    state.conversions.errorMessage,
  successMsg:  state.conversions.successMessage,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey,
  token:   state.credentials.token
})

const mapDispatchToProps = (dispatch) => ({
  clearFailed: () => dispatch(clearFailed()),
  unsetCurrentRow:                       () => dispatch(setCurrentRow(null)),
  startFetchingChargeCodes:       (apihost, apikey) => dispatch(startFetchingChargeCodes(apihost, apikey)),
  startFetchingChargeConversions: (apihost, apikey) => dispatch(startFetchingConversions(apihost, apikey)),
  startAddConversion: (apihost, apikey, token, source, cjis, external) => dispatch(startAddConversion(apihost, apikey, token, source, cjis, external)),
  startDeleteConversion: (apihost, apikey, token, conversion) => dispatch(startDeleteConversion(apihost, apikey, token, conversion))
})

export default connect(mapStateToProps, mapDispatchToProps)(chargeConversionForm);