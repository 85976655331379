import React from 'react';
import styled from 'styled-components';
import { Name, VarValue, MonoValue } from '../components/DisplayFields';

const LeaDetailHover = styled.div`
  grid-template-columns: 10em 20em;
`;

const Var = styled(VarValue)`
  color: blue;
`;
const Mono = styled(MonoValue)`
  color: blue;
`;

export default (props) => {
  return <LeaDetailHover>

    <Name>Address</Name>
    <Var>{props.address}</Var>

    <Name>Telephone</Name>
    <Var>{props.telephone}</Var>

    <Name>Fax</Name>
    <Var>{props.fax_number}</Var>

    <Name>Zip Code</Name>
    <Var>{props.zipcode}</Var>

    <Name>Agency Type</Name>
    <Var>{props.agency_type}</Var>

    <Name>ORI Type</Name>
    <Var>{props.ori_type}</Var>

    <Name>Cal DOJ ORI Onfile</Name>
    <Var>{props.caldoj_ori_onfile}</Var>

    <Name>Cal DOJ Agency Name</Name>
    <Var>{props.caldoj_agency_name}</Var>

    <Name>Cal DOJ Compare Date</Name>
    <Mono>{props.caldoj_compare_date}</Mono>

    <Name>Create Date</Name>
    <Mono>{props.create_date}</Mono>

    <Name>Effective Start Date</Name>
    <Mono>{props.effective_start_date}</Mono>

    <Name>AJIS ORI Subfield</Name>
    <Var>{props.ajis_ori_sub_field}</Var>
  
    <Name>Printer ID</Name>
    <Mono>{props.printer_id}</Mono>

    <Name>Jail Facility</Name>
    <Var>{props.jail_facility}</Var>

    <Name>PTS Service Branch</Name>
    <Var>{props.pts_service_branch}</Var>

    <Name>Last changed by</Name>
    <Var>{props.user_id}</Var>

    <Name>Source System</Name>
    <Var>{props.source_system}</Var>
  </LeaDetailHover>
}