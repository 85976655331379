import React from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ChargeCodeForm from './ChargeCodeForm';
import { clearFailed,
         startUpdateChargeCode,
         startDeleteChargeCode } from './ChargeCodeActions';

const updateChargeCode = props => {

  const navigate = useNavigate();
  const { id } = useParams();
  console.log(`updateChargeCode path param id = ${id}`);
  const charge = props.charges.find(chg => chg.id === parseInt(id));
  if (charge) {
    console.log(`updateChargeCode with charge = ${charge.id}`);
  } else {
    console.log(`updateChargeCode: failed to find charge with id ${id}`);
  }
  const composite_keys = new Set(props.charges.map(chg => chg.composite_key));

  function onSubmit(modifiedChargeValues) {
    props.startUpdateChargeCode(modifiedChargeValues,
                                props.token,
                                props.apihost,
                                props.apikey);
  }

  function onCancel(evt) {
    evt.preventDefault();
    props.clearFailed();
    navigate('/ChargeCode/dashboard');
  }

  // To help prevent user from causing duplicate composite_key
  // entries, submit a Set of current composite_keys.  But
  // remove this one from the set so allow it to be added back.
  //
  let allCompositesExceptThisOne = new Set(composite_keys);
  const removeSucceeded = allCompositesExceptThisOne.delete(charge.composite_key);
  if (!removeSucceeded) {
    console.log(`Not able to remove ${charge.composite_key} from composite list`);
  }

  return <div>
    <div className="page-header">
      <h1>Update Charge Code</h1>
      <div className="warning">
        <p>{props.token ? 'Authenticated' : 'Warning: Not Authenticated'}</p>
      </div>
    </div>
    <div>
      <ChargeCodeForm onSubmit={onSubmit}
                      onCancel={onCancel}
                      onDelete={props.startDeleteChargeCode}
                      clearFailed={props.clearFailed}
                      errMsg={props.errorMessage}
                      successMsg={props.successMessage}
                      unauthorizedFields={[]}
                      charge={charge}
                      composite_keys={allCompositesExceptThisOne}
      />
    </div>
  </div>
}

const mapStateToProps = (state) => ({
  charges: state.charges.items,
  errorMessage: state.charges.errorMessage,
  successMessage: state.charges.successMessage,
  token: state.credentials.token,
  apihost: state.apiserver.urlbase,
  apikey:  state.apiserver.apikey,
});

const mapDispatchToProps = {
  startUpdateChargeCode,
  startDeleteChargeCode,
  clearFailed
};

export default connect(mapStateToProps, mapDispatchToProps)(updateChargeCode);