import React from 'react';
import { connect } from 'react-redux';
import CourtDeptListRow from './CourtDeptListRow';
import styled from 'styled-components';

const CourtDeptTable = styled.table`
  border-collapse: collapse;
  thead {
    background: #563600;
    color: #ffeed5;
  }
  th {
    padding: 0 3px;
  }
  th:nth-child(1) {
    width: 4em;
  }
  th:nth-child(2) {
    width: 4em;
  }
  th:nth-child(3) {
    width: 4em;
  }
  th:nth-child(4) {
    width: 20em;
  }
  th:nth-child(5) {
    width: 18em;
  }
  th:nth-child(6) {
    width: 8em;
  }
`;
CourtDeptTable.displayName = 'CourtDeptTable';

const CourtDeptList = props => (
  <div>
    {
      props.courtdept.items.length === 0 ? (
        <p>Empty Court Department list</p>
      ) : (
        <CourtDeptTable>
          <thead>
            <tr>
              <th>Court Type</th>
              <th>Judicial District</th>
              <th>Dept/ Div</th>
              <th>Description</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Expiration</th>
            </tr>
          </thead>
          <tbody>
            {
              props.courtdept.filteredItems.map(dept => (
                <CourtDeptListRow {...dept} key={`${dept.court_type}${dept.judicial_district}${dept.department}`}/>
              ))
            }
          </tbody>
        </CourtDeptTable>
      )
    }
  </div>
)

const mapStateToProps = state => ({
  courtdept: state.courtdept
});

export default connect(mapStateToProps)(CourtDeptList);