import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import styled      from 'styled-components';

const isActive = ({isActive}) => isActive ? "is-active" : "not-active";

const CjisNavBar = styled.nav`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  gap: 10px;

  a {
    background: #c986ad;
    padding: 4px 10px;
    color: black;
    text-decoration: none;
    border-radius: 10px;
    margin: auto 2px;
    font-family: arial sans-serif;
  }

  a:hover {
    background: lightgreen;
  }

  .active {
    background: #419cf7;
    padding: 8px 20px;
  }
`;
CjisNavBar.displayName = 'CjisNavBar';

const Header = (props) => <header>
  <CjisNavBar>
    <NavLink to="/" end>Home</NavLink>
    <NavLink to="/Credentials" end>Login Credentials</NavLink>
    <NavLink to="/ChargeCode/dashboard" end>Charge Codes</NavLink>
    <NavLink to="/CourtDistrict/dashboard" end>Court District</NavLink>
    { ( props.token && props.admin &&
          <NavLink to="/Profiles/dashboard" end>Profiles</NavLink>)
        || <div></div>
    }
    <NavLink to="/lea/dashboard" end>LEA</NavLink>
    <NavLink to="/version" end>Version Info</NavLink> 
    <NavLink to="/ChargeConversion/dashboard" end>Charge Conversion</NavLink>
    <NavLink to="/CourtDepartment/dashboard" end>Court Department</NavLink>
  </CjisNavBar>
</header>


const mapStateToProps = (state) => ({
    token: state.credentials.token,
    admin: state.credentials.claims && 
           state.credentials.claims.scope &&
           state.credentials.claims.scope.includes('admin')
})

export default connect(mapStateToProps)(Header);
