import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProfileDashboard from './ProfileDashboard';
import ProfileDetail from './ProfileDetail';
import CreateProfile from './CreateProfile';

const ProfileRouter = () => (
  <div>
    <Routes>
      <Route path={'/dashboard'}       element={<ProfileDashboard />} />
      <Route path={'/detail/:enumber'} element={<ProfileDetail />} />
      <Route path="/create"            element={< CreateProfile />} />
    </Routes>
  </div>
)

export default ProfileRouter;